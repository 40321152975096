.c-series-block {
	padding:var(--space) 0;
	background:#f7f7f7;
}
.c-series-block .c-breadcrumbs {
	margin-top:0;
}
.c-series-block .c-breadcrumbs,
.c-series-block .c-buffer-box__container {
	text-align:center;
}
.c-series-block .c-buffer-box__container p {
	width:100%;
	margin:auto;
}

/* customer-links inside series-block */
.c-series-block .c-customer-links {
	margin-bottom:-var(--space);
}

@media screen and (min-width:768px) {
	.c-series-block .c-buffer-box__container p {
		width:70%;
	}
}