/* =============================================================================
 * Product grid
============================================================================= */
.c-product-grid {
	display:flex;
	flex-wrap:wrap;
	align-items:flex-start;
}
.c-product-grid--collapseable {
	display:none;
}
.c-product-grid--collapseable .c-get-more {
	display:none;
}
.c-product-grid--align-normal {
	align-items:normal;
}
.c-product-grid--border-top {
	padding-top:20px !important;
	border-top:3px solid #e6e6e6;
}
.c-product-grid__item {
	width:50%;
}
.c-product-grid__title {
	padding-bottom:calc(var(--space) * 2);
}
.c-product-grid__toggler {
	position:relative;
	margin-bottom:0;
}
.c-product-grid__toggler {
	position:relative;
	margin-bottom:0;
}
.c-product-grid__button {
	position:absolute;
	top:50%;
	left:50%;
	border-radius:50%;
	background:var(--color__base-black);
	color:var(--color__base-white);
	transform:translate(-50%, -50%);
}
.c-product-grid__button:hover {
	color:var(--color__base-white);
	text-decoration:none;
}
.c-product-grid__button svg {
	border-radius:50%;
	background:var(--color__primary-dark);
}

@media screen and (min-width:768px), print {
	.c-product-grid__item {
		width:33.3333%;
	}
	.c-product-grid--collapseable {
		display:block;
	}
	.c-product-grid--collapseable .c-get-more {
		display:block;
	}
	.c-product-grid__toggler {
		margin-bottom:var(--space);
		border-top:3px solid #e6e6e6;
	}
}

@media screen and (min-width:1024px) {
	.c-product-grid__item {
		width:20%;
	}
}

@media screen and (min-width:1160px) {
	.c-product-grid--compact .c-product-grid__item {
		width:16.666%;
	}
}

/* Make quick view take up the full width of the page using negative margins */
@media screen and (max-width:767px) {
	.c-product-grid__item--quick-view-container .c-quick-view {
		width:200%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(2n+2) .c-quick-view {
		margin-left:-100%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(2n+3) {
		clear:left;
	}
	.c-product-grid__title {
		margin:0 -20px;
		padding:calc(var(--space) * 2) 0;
		background:#f7f7f7;
	}
	.c-product-grid__title-text {
		line-height:1.3;
		text-align:center;
	}
	.c-product-grid__heading {
		margin:0 -20px;
		padding-top:40px;
		border-top:1px solid #e6e6e6;
	}
}

@media screen and (min-width:768px) and (max-width:1023px), print {
	.c-product-grid__item--quick-view-container .c-quick-view {
		width:300%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(3n+2) .c-quick-view {
		margin-left:-100%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(3n+3) .c-quick-view {
		margin-left:-200%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(3n+4) {
		clear:left;
	}
}

@media screen and (min-width:1024px) and (max-width:1159px) {
	.c-product-grid__item--quick-view-container .c-quick-view {
		width:400%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(4n+2) .c-quick-view {
		margin-left:-100%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(4n+3) .c-quick-view {
		margin-left:-200%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(4n+4) .c-quick-view {
		margin-left:-300%;
	}
	.c-product-grid__item--quick-view-container:nth-of-type(4n+5) {
		clear:left;
	}
}

@media screen and (min-width:1160px) {
	.c-product-grid:not(.c-product-grid--compact) .c-product-grid__item--quick-view-container .c-quick-view {
		width:400%;
	}
	.c-product-grid:not(.c-product-grid--compact) .c-product-grid__item--quick-view-container:nth-of-type(4n+2) .c-quick-view {
		margin-left:-100%;
	}
	.c-product-grid:not(.c-product-grid--compact) .c-product-grid__item--quick-view-container:nth-of-type(4n+3) .c-quick-view {
		margin-left:-200%;
	}
	.c-product-grid:not(.c-product-grid--compact) .c-product-grid__item--quick-view-container:nth-of-type(4n+4) .c-quick-view {
		margin-left:-300%;
	}
	.c-product-grid:not(.c-product-grid--compact) .c-product-grid__item--quick-view-container:nth-of-type(4n+5) {
		clear:left;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container .c-quick-view {
		width:600%;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container:nth-of-type(6n+2) .c-quick-view {
		margin-left:-100%;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container:nth-of-type(6n+3) .c-quick-view {
		margin-left:-200%;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container:nth-of-type(6n+4) .c-quick-view {
		margin-left:-300%;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container:nth-of-type(6n+5) .c-quick-view {
		margin-left:-400%;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container:nth-of-type(6n+6) .c-quick-view {
		margin-left:-500%;
	}
	.c-product-grid--compact .c-product-grid__item--quick-view-container:nth-of-type(6n+7) {
		clear:left;
	}
}