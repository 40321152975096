.dashboard .glide,
.dashboard .glide__track,
.dashboard .glide__slides {
	height:100%;
}
.glide {
	position:relative;
	box-sizing:border-box;
	width:100%;
}
.glide * {
	box-sizing:inherit;
}
.glide__track {
	overflow:hidden;
}
.glide__slides {
	display:flex;
	position:relative;
	flex-wrap:nowrap;
	width:100%;
	overflow:hidden;
	padding:0;
	list-style:none;
	white-space:nowrap;
	backface-visibility:hidden;
	transform-style:preserve-3d;
	touch-action:pan-Y;
	will-change:transform;
}
.glide__slides--dragging {
	user-select:none;
}
.glide__slide {
	position:relative;
	flex-shrink:0;
	width:100%;
	height:100%;
	white-space:normal;
	user-select:none;
	-webkit-touch-callout:none;
	-webkit-tap-highlight-color:transparent;
}
.glide__slide a {
	user-select:none;
	-webkit-user-drag:none;
	-moz-user-select:none;
	-ms-user-select:none;
}
.glide__arrows {
	-webkit-touch-callout:none;
	user-select:none;
}
.glide__bullets {
	-webkit-touch-callout:none;
	user-select:none;
}
.glide--rtl {
	direction:rtl;
}