/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */

/* =======================================================================================================================
 * RESETS
 * ==================================================================================================================== */
.flex-container a:hover,
.flex-slider a:hover {
	outline:none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
	margin:0;
	padding:0;
	list-style:none;
}
.flex-pauseplay span {
	text-transform:capitalize;
}

/* =======================================================================================================================
 * BASE STYLES
 * ==================================================================================================================== */
.flexslider {
	margin:0;
	padding:0;
}
.flexslider .slides > li {
	display:none;
	-webkit-backface-visibility:hidden;
}
.flexslider .slides img {
	display:block;
	width:100%;
}
.flexslider .slides::after {
	display:block;
	visibility:hidden;
	clear:both;
	height:0;
	line-height:0;
	content:"\0020";
}
html[xmlns] .flexslider .slides {
	display:block;
}
* html .flexslider .slides {
	height:1%;
}
.no-js .flexslider .slides > li:first-child {
	display:block;
}

/* =======================================================================================================================
 * DEFAULT THEME
 * ==================================================================================================================== */
.flexslider {
	position:relative;
	margin:0 0 60px;
	border:4px solid #fff;
	-moz-border-radius:4px;
	-webkit-border-radius:4px;
	border-radius:4px;
	-moz-box-shadow:0 0 1px 4px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow:0 0 1px 4px rgba(0, 0, 0, 0.2);
	box-shadow:0 0 1px 4px rgba(0, 0, 0, 0.2);
	background:#fff;
	zoom:1;
	-o-box-shadow:0 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
	zoom:1;
}
.flexslider .slides img {
	height:auto;
	-moz-user-select:none;
}
.flex-viewport {
	max-height:2000px;
	-moz-transition:all 1s ease;
	-ms-transition:all 1s ease;
	-o-transition:all 1s ease;
	-webkit-transition:all 1s ease;
	transition:all 1s ease;
}
.loading .flex-viewport {
	max-height:300px;
}
.carousel li {
	margin-right:5px;
}
.flex-direction-nav a {
	display:block;
	position:absolute;
	top:50%;
	z-index:10;
	width:40px;
	height:40px;
	overflow:hidden;
	margin:-20px 0 0;
	color:rgba(0, 0, 0, 0.8);
	text-decoration:none;
	text-shadow:1px 1px 0 rgba(255, 255, 255, 0.3);
	opacity:0;
	cursor:pointer;
	-moz-transition:all 0.3s ease-in-out;
	-ms-transition:all 0.3s ease-in-out;
	-o-transition:all 0.3s ease-in-out;
	-webkit-transition:all 0.3s ease-in-out;
	transition:all 0.3s ease-in-out;
}
.flex-direction-nav a::before {
	display:inline-block;
	color:rgba(0, 0, 0, 0.8);
	font-size:40px;
	font-family:"flexslider-icon";
	content:"\f001";
	text-shadow:1px 1px 0 rgba(255, 255, 255, 0.3);
}
.flex-direction-nav a.flex-next::before {
	content:"\f002";
}
.flex-direction-nav .flex-prev {
	left:-50px;
}
.flex-direction-nav .flex-next {
	right:-50px;
	text-align:right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
	left:10px;
	opacity:0.7;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
	opacity:1;
}
.flexslider:hover .flex-direction-nav .flex-next {
	right:10px;
	opacity:0.7;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
	opacity:1;
}
.flex-direction-nav .flex-disabled {
	z-index:-1;
	opacity:0 !important;
	cursor:default;
}
.flex-pauseplay a {
	display:block;
	position:absolute;
	bottom:5px;
	left:10px;
	z-index:10;
	width:20px;
	height:20px;
	overflow:hidden;
	color:#000;
	opacity:0.8;
	cursor:pointer;
}
.flex-pauseplay a::before {
	display:inline-block;
	font-size:20px;
	font-family:"flexslider-icon";
	content:"\f004";
}
.flex-pauseplay a:hover {
	opacity:1;
}
.flex-pauseplay a.flex-play::before {
	content:"\f003";
}
.flex-control-nav {
	position:absolute;
	bottom:-40px;
	width:100%;
	text-align:center;
}
.flex-control-nav li {
	display:inline-block;
	margin:0 6px;
	zoom:1;
}
.flex-control-paging li a {
	display:block;
	width:11px;
	height:11px;
	-moz-border-radius:20px;
	-webkit-border-radius:20px;
	border-radius:20px;
	-moz-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.3);
	box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.3);
	background:#666;
	background:rgba(0, 0, 0, 0.5);
	text-indent:-9999px;
	cursor:pointer;
	-o-box-shadow:inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.flex-control-paging li a:hover {
	background:#333;
	background:rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
	background:#000;
	background:rgba(0, 0, 0, 0.9);
	cursor:default;
}
.flex-control-thumbs {
	position:static;
	overflow:hidden;
	margin:5px 0 0;
}
.flex-control-thumbs li {
	float:left;
	width:25%;
	margin:0;
}
.flex-control-thumbs img {
	display:block;
	width:100%;
	height:auto;
	opacity:0.7;
	cursor:pointer;
	-moz-transition:all 1s ease;
	-ms-transition:all 1s ease;
	-o-transition:all 1s ease;
	-webkit-transition:all 1s ease;
	transition:all 1s ease;
	-moz-user-select:none;
}
.flex-control-thumbs img:hover {
	opacity:1;
}
.flex-control-thumbs .flex-active {
	opacity:1;
	cursor:default;
}

/* =======================================================================================================================
 * RESPONSIVE
 * ==================================================================================================================== */
@media screen and (max-width:860px) {
	.flex-direction-nav .flex-prev {
		left:10px;
		opacity:1;
	}
	.flex-direction-nav .flex-next {
		right:10px;
		opacity:1;
	}
}