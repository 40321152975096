/* =============================================================================
 * Price
============================================================================= */
.c-price__label {
	display:block;
}
.c-price__price {
	font-weight:700;
	font-size:var(--font-size-default);
	line-height:1;
}
.c-price__vat-info {
	display:block;
	margin-top:4px;
	color:#767676;
	font-weight:400;
	font-size:12px;
	line-height:1;
}

@media (min-width:992px) {
	.c-price__price {
		font-size:var(--font-size-large);
	}
}

/* Plain version
 * Text inherits styling
----------------------------------------------------------------------------- */
.c-price--plain .c-price__price {
	color:inherit;
	font:inherit;
}


/* Larger
----------------------------------------------------------------------------- */
.c-price--large .c-price__price {
	font-size:20px;
}
.c-price--xl .c-price__price {
	font-size:24px;
}

@media screen and (min-width:768px) {
	.c-price--xl .c-price__price {
		font-size:32px;
	}
}