/* =============================================================================
 * Space between
============================================================================= */
.o-space-between {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
}
.o-space-between__left {
	margin-right:15px;
}

/* @custom-media md */
@media (min-width:600px) {
	.o-space-between__left {
		margin-right:30px;
	}
}

/* Shrinkable
----------------------------------------------------------------------------- */
.o-space-between--shrinkable {
	flex-wrap:nowrap;
	align-items:stretch;
}
.o-space-between--shrinkable .o-space-between__left {
	flex-shrink:1;
}
.o-space-between--shrinkable .o-space-between__right {
	flex-shrink:0;
}

/*
 * @md
 *
 * @custom-media md */
@media (min-width:600px) {
	.o-space-between--shrinkable\@md {
		flex-wrap:nowrap;
		align-items:stretch;
	}
	.o-space-between--shrinkable\@md .o-space-between__left {
		flex-shrink:1;
	}
	.o-space-between--shrinkable\@md .o-space-between__right {
		flex-shrink:0;
	}
}

/*
 * @lg
 */

/* @custom-media lg */

@media (min-width:1000px) {
	.o-space-between--shrinkable\@lg {
		flex-wrap:nowrap;
		align-items:stretch;
	}
	.o-space-between--shrinkable\@lg .o-space-between__left {
		flex-shrink:1;
	}
	.o-space-between--shrinkable\@lg .o-space-between__right {
		flex-shrink:0;
	}
}

/*
 * @xl
 */

/* @custom-media xl */

@media (min-width:1500px) {
	.o-space-between--shrinkable\@xl {
		flex-wrap:nowrap;
		align-items:stretch;
	}
	.o-space-between--shrinkable\@xl .o-space-between__left {
		flex-shrink:1;
	}
	.o-space-between--shrinkable\@xl .o-space-between__right {
		flex-shrink:0;
	}
}