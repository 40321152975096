.img--left {
	float:left;
	margin:0 calc(var(--space) * 1.5) calc(var(--space) * 1.5) 0;
}
.img--right {
	float:right;
	margin:0 0 calc(var(--space) * 1.5) calc(var(--space) * 1.5);
}
.img--fullwidth {
	margin:0 0 calc(var(--space) * 1.5);
}

@media screen and (max-width:767px) {
	.img--left {
		margin:0 0 calc(var(--space));
	}
	.img--right {
		margin:0 0 calc(var(--space));
	}
}