/* =============================================================================
 * Text styles
============================================================================= */
.t-text-style-h1 {
	color:var(--color__primary-dark);
	font-weight:700;
	font-size:var(--font-size-heading);
	line-height:var(--font-line-height-heading);
	text-wrap:balance;
}
.t-text-style-h2 {
	font-weight:600;
	font-size:var(--font-size-heading2);
	line-height:var(--font-line-height-heading2);
	text-wrap:balance;
}
.t-text-style-h2-light {
	font-size:31px;
	line-height:1.25;
}
.t-text-style-h3 {
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.t-text-style-h4 {
	font-weight:400;
	font-size:var(--font-size-heading4);
	line-height:var(--font-line-height-heading4);
}
.t-text-style-intro {
	font-size:var(--font-size-intro);
	line-height:var(--font-line-height-intro);
}
.t-text-style-large {
	font-size:var(--font-size-large);
}
.t-text-style-default {
	font-size:var(--font-size-default);
}
.t-text-style-default-medium {
	font-weight:600;
	font-size:var(--font-size-default);
}
.t-text-style-small,
.t-text-style-small-bold {
	font-size:var(--font-size-small);
	line-height:21px;
}
.t-text-style-bold,
.t-text-style-small-bold {
	font-weight:700;
}
.t-text-style-500 {
	font-weight:600;
}
.t-text-decoration-underline {
	text-decoration:underline;
}
.t-text-whitespace-nowrap {
	white-space:nowrap;
}

@media (min-width:992px) {
	.t-text-style-default,
	.t-text-style-default-medium {
		font-size:var(--font-size-large);
	}
}

@media screen and (max-width:767px) {
	.t-text-style-h1 {
		font-size:var(--font-size-heading-mobile);
		line-height:var(--font-line-height-heading-mobile);
	}
	.t-text-style-h2,
	.t-text-style-h2-light {
		font-size:var(--font-size-heading2-mobile);
		line-height:var(--font-line-height-heading2-mobile);
	}
}