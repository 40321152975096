/* =============================================================================
 * Address
============================================================================= */
.c-address__heading {
	margin-bottom:8px;
}
.c-address__content {
	margin-bottom:var(--space);
	padding:var(--space);
	border:1px solid var(--color__grey-2);
}
.c-address__content p {
	margin:0 !important;
}