.c-instant-search-results {
	position:relative;
}
.c-instant-search-results__container {
	position:absolute;
	z-index:25;
	max-height:595px;
	width:100%;
	overflow-y:auto;
	padding-top:2rem;
	box-shadow:0 5px 5px 1px rgba(0, 0, 0, 0.1);
	background-color:#fff;
}
.c-instant-search-results__inner {
	max-width:1400px;
	overflow-y:auto;
}
.c-instant-search-results .c-search-category {
	margin-bottom:30px;
	text-align:left;
}
.c-instant-search-results .c-form-item {
	display:none;
}
.c-instant-search-results .c-page-list__item-heading {
	font-weight:400;
}
.c-instant-search-results .c-search-category__item {
	margin-bottom:0;
}
.c-instant-search-results .c-search-category__heading {
	min-height:unset;
	margin-bottom:16px;
}
.c-instant-search-results .c-search-category__heading h2 {
	color:var(--color__ut_darkgreen);
	font-weight:600;
	font-size:18px;
}
.c-instant-search-results .c-search-category__list {
	justify-content:space-between;
	margin-bottom:20px;
	grid-template-columns:repeat(3, 1fr);
}
.c-instant-search-results--related li {
	margin-bottom:20px;
	cursor:pointer;
}
.c-instant-search-results--related li a {
	color:#000;
	font-weight:700;
	font-size:16px;
	text-decoration:none;
}
.c-instant-search-results--related li a:hover {
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-instant-search-results--related li[data-bestbet-label]:not([data-bestbet-label=""])::before {
	display:inline-block;
	min-width:61px;
	height:26px;
	margin-right:8px;
	padding-left:1px;
	border-radius:13px;
	background-color:var(--color__ut_darkgreen);
	color:#fff;
	font-size:15px;
	font-family:Verveine, sans-serif;
	line-height:1.8;
	content:attr(data-bestbet-label);
	vertical-align:bottom;
	text-align:center;
	letter-spacing:1px;
}
.c-instant-search-results .c-search-category__item--product .c-page-list__item-meta {
	display:block;
}
.c-instant-search-results .c-search-category__item--product > div:first-child > div:first-child {
	flex-direction:row;
	gap:20px;
}
.c-instant-search-results .c-search-category__item--product {
	max-width:unset;
	width:unset;
}
.c-instant-search-results .c-search-category__item--product .c-search-category__item-image {
	max-width:62px;
}
.c-instant-search-results .c-search-category__item--product .c-page-list__item-content {
	position:static;
	min-height:unset;
	width:auto;
	padding:0;
	box-shadow:none;
	background:transparent;
	opacity:1;
}
.c-instant-search-results .c-search-category__item--product .c-page-list__item-meta > *:not(.c-page-list__item-author),
.c-instant-search-results .c-search-category__item--product .c-page-list__item-content ul {
	display:none;
	margin-bottom:0;
}
.c-instant-search-results .c-page-list__item-heading {
	margin-bottom:0;
}
.c-instant-search-results .c-search-category__item--product .c-search-category__item-image + .c-page-list__item-heading {
	display:none;
}
.c-instant-search-results .c-page-list__item-author {
	font-size:inherit;
}
.c-instant-search-results--products .c-search-category__list {
	grid-template-columns:repeat(2, 1fr);
	gap:20px;
}
.c-instant-search-results--products .c-page-list__item-author {
	display:block;
}
.c-instant-search-results .c-search-category__item--series .c-search-category__item-image {
	height:64px;
	background-color:#fff;
}
.c-instant-search-results .c-product__bubble--selected {
	display:none;
}
.c-instant-search-results .c-search-category__books-wrapper picture {
	box-shadow:2px 24px 50px 0 rgba(1, 1, 1, 0.35);
}
.c-instant-search-results .c-page-list__item-meta,
.c-instant-search-results .c-page-list__item-content p {
	display:none;
}
.c-instant-search-results .c-search-category__showmore {
	display:inline-block;
	margin:0;
	padding:0;
	background-color:transparent;
	color:var(--color__link-color);
	font-weight:400;
	text-align:left;
}

@media (min-width:1025px) {
	.c-instant-search-results__middle {
		display:grid;
		grid-template-columns:2fr 286px;
		gap:20px;
	}
	.c-instant-search-results .c-search-category__list:not(.c-instant-search-results--products .c-search-category__list) {
		grid-template-columns:repeat(3, 286px);
	}
}

@media (max-width:580px) {
	.c-instant-search-results .c-search-category__list:not(.c-instant-search-results--products .c-search-category__list) {
		margin-bottom:20px;
		grid-template-columns:repeat(1, 286px);
	}
}