/* =============================================================================
 * Vanilla breadcrumbs
============================================================================= */
.c-breadcrumbs {
	margin:var(--space) 0 calc(var(--space) * 2);
	font-size:var(--font-size-default);
}
.c-breadcrumbs__link {
	color:var(--color__link-color);
	font-weight:600;
	text-decoration:underline;
}
.c-breadcrumbs__current-page {
	color:var(--color__link-active);
	font-weight:600;
}
.c-breadcrumbs--xs-viewports {
	display:none;
}
.c-breadcrumbs--xs-viewports .c-breadcrumbs__link {
	display:flex;
	align-items:center;
}
.c-breadcrumbs--truncate {
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

@media screen and (max-width:450px) {
	.c-breadcrumbs {
		display:none;
	}
	.c-breadcrumbs--xs-viewports {
		display:block;
	}
}