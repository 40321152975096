/* =============================================================================
 * Add to cart
 *
 * 1. Align with the custom number input
============================================================================= */
.c-add-to-cart {
	margin:0 0 var(--space);
}
.c-add-to-cart--no-margin {
	margin:0;
}
.c-add-to-cart__price {
	display:block;
	color:var(--color__base-black);
	font-weight:700;
	font-size:20px;
	line-height:1;
}
.c-add-to-cart__not-available {
	display:flex;
	padding:calc(var(--space) - 4px);
	background:var(--color__primary-alt_light);
}
.c-add-to-cart__not-available .c-icon {
	margin-right:8px;
}
.c-add-to-cart__form {
	display:flex;
	flex-wrap:wrap;
	align-items:flex-end;
	margin:calc(var(--space) / 2) 0 0;
}
.c-add-to-cart__vat {
	display:block;
	margin-top:4px;
	color:#767676;
	font-weight:400;
	font-size:12px;
	line-height:1;
}
.c-add-to-cart__link {
	display:block;
	padding:calc(var(--space) - 5px) 0;
	font-weight:bold;
	font-size:calc(var(--space) - 4px);
}
.c-add-to-cart__duration,
.c-add-to-cart__quantity {
	margin:0 calc(var(--space) / 2) calc(var(--space) / 2) 0;
}
.c-add-to-cart__duration .c-form-item__label,
.c-add-to-cart__quantity .c-input-number__label {
	margin-bottom:0;
}
.c-add-to-cart__duration .c-custom-select {
	height:46px; /* 1 */
}
.c-add-to-cart__button {
	position:relative;
	overflow:hidden;
	margin:0 0 calc(var(--space) / 2);
}
button.c-add-to-cart__button[disabled] {
	opacity:1;
}
.c-add-to-cart__button:only-child {
	margin:0;
}
.c-add-to-cart__text,
.c-add-to-cart__added-text {
	display:block;
}
.c-add-to-cart__text {
	transition:transform 0.2s;
}
.c-add-to-cart__added-text {
	display:none;
	position:absolute;
	top:-100%;
	right:0;
	left:0;
	transition:top 0.2s;
	transform:translateY(-50%);
}
.c-add-to-cart__button--added .c-add-to-cart__text {
	transform:translateY(300%);
}
.c-add-to-cart__button--added .c-add-to-cart__added-text {
	top:50%;
}
.c-add-to-cart__account {
	font-size:16px;
}

@media screen and (max-width:767px) {
	.c-add-to-cart__price {
		margin-right:calc(var(--space) / 2);
	}
	.c-add-to-cart__link {
		padding:calc(var(--space) - 5px) 0;
	}
	.c-add-to-cart__form .c-add-to-cart__button {
		font-size:15px;
	}
}