/* =============================================================================
 * Timestamp
============================================================================= */
.c-timestamp {
	margin-bottom:calc(var(--space) / 2);
	font-size:var(--font-size-small);
	line-height:21px;
}
.c-timestamp--large {
	font-weight:600;
	font-size:var(--font-size-default);
}

@media (min-width:992px) {
	.c-timestamp--large {
		font-size:var(--font-size-large);
	}
}