/* =============================================================================
 * Quick view
============================================================================= */
.js-toggle-quick-view {
	outline:none;
}
.js-toggle-quick-view[type="button"] {
	-webkit-appearance:none;
}
.js-toggle-quick-view.is-expanded {
	background:var(--color__primary-alt_light);
}
.js-toggle-quick-view.is-expanded::after {
	position:absolute;
	bottom:-20px;
	left:50%;
	z-index:10;
	width:0;
	height:0;
	border-right:12px solid transparent;
	border-bottom:12px solid var(--color__primary-alt_light);
	border-left:12px solid transparent;
	content:"";
	transform:translateX(-50%);
}
.c-quick-view {
	position:relative;
	height:0;
	overflow:hidden;
	margin:var(--space) 0;
	outline:none;
}
.c-quick-view:focus {
	box-shadow:0 0 0 1px rgba(0, 0, 0, 0.1);
}
.c-quick-view__inner {
	padding:var(--space);
	background:var(--color__primary-alt_light);
}
.c-quick-view__close-button {
	position:absolute;
	top:var(--space);
	right:var(--space);
	z-index:10;
	color:var(--color__base-black);
	line-height:1;
}

@media screen and (min-width:768px) {
	.c-quick-view__inner {
		padding:calc(var(--space) * 2);
	}
}