/* =============================================================================
 * Custom select
 *
 * 1. Hide dropdown arrow in IE10+.
 * 2. Background styling is put on a separate element placed after the actual
 *    select in the DOM to be able to act on states of the select.
 * 3. Make sure overlay is above actual select.
 * 4. Make sure we only interact with the actual select.
============================================================================= */
.c-custom-select {
	display:flex;
	position:relative;
	min-height:40px;
	line-height:1;
	vertical-align:middle;
}
.c-custom-select__select {
	position:relative;
	z-index:2;
	width:100%;
	height:auto;
	padding:0 50px 0 10px;
	outline:none;
	border:none;
	background:transparent;
	line-height:2;
	-webkit-appearance:none;
	-moz-appearance:none;
}
.c-custom-select__select::-ms-expand {
	/* 1 */
	display:none;
}
.c-custom-select__select--min-width {
	min-width:250px;
}
.c-custom-select__overlay {
	/* 2 */
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1; /* 3 */
	overflow:hidden;
	border:1px solid var(--color__ut_darkgrey-95);
	background:#fff;
	pointer-events:none; /* 4 */
}
.c-custom-select__arrow {
	display:flex;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	justify-content:center;
	align-items:center;
	width:40px;
}

/* Hover and focus effect
----------------------------------------------------------------------------- */
.c-custom-select__select:hover + .c-custom-select__overlay,
.c-custom-select__select:hover + .c-custom-select__overlay .c-custom-select__arrow {
	border-color:var(--color__primary-alt);
}
.c-custom-select__select:focus + .c-custom-select__overlay {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
}

/* Disabled
----------------------------------------------------------------------------- */
.c-custom-select__select[disabled],
.c-custom-select__select[disabled] + .c-custom-select__overlay {
	opacity:0.6;
}
.c-custom-select__select[disabled]:hover + .c-custom-select__overlay,
.c-custom-select__select[disabled]:hover + .c-custom-select__overlay .c-custom-select__arrow,
.c-custom-select__select[disabled]:focus + .c-custom-select__overlay,
.c-custom-select__select[disabled]:focus + .c-custom-select__overlay .c-custom-select__arrow {
	border-color:var(--color__ut_darkgrey-95);
}

/* Invalid
----------------------------------------------------------------------------- */
.c-form-item--invalid .c-custom-select__select + .c-custom-select__overlay,
.c-form-item--invalid .c-custom-select__select:hover + .c-custom-select__overlay,
.c-form-item--invalid .c-custom-select__select:hover + .c-custom-select__overlay .c-custom-select__arrow,
.c-form-item--invalid .c-custom-select__select:focus + .c-custom-select__overlay,
.c-form-item--invalid .c-custom-select__select:focus + .c-custom-select__overlay .c-custom-select__arrow {
	border-width:2px;
	border-color:var(--color__error);
	background-color:var(--color__error-light);
}

/* Js max select */
.js-max-select {
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-khtml-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}