/* =============================================================================
 * Iframe popup
============================================================================= */
.c-iframe-popup {
	display:none;
	position:fixed;
	top:0;
	left:0;
	z-index:10000;
	justify-content:center;
	align-items:center;
	width:100%;
	height:100vh;
	overflow:auto;
	background:rgba(22, 22, 22, 0.8);
}
.c-iframe-popup--show {
	display:block;
}
.c-iframe-popup__button {
	color:var(--color__link-active);
	font-weight:600;
	text-decoration:underline;
	cursor:pointer;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-iframe-popup__button:hover {
	color:var(--color__link-hover);
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-iframe-popup__button:focus {
	color:var(--color__link-active);
	text-decoration-thickness:0.5px;
}
.c-iframe-popup__close {
	position:absolute;
	top:8px;
	right:8px;
	z-index:1;
	padding:5px;
	border:1px solid transparent;
	border-radius:2px;
	background-color:var(--color__primary);
	cursor:pointer;
}
.c-iframe-popup__wrapper {
	visibility:hidden;
	position:absolute;
	top:0;
	bottom:0;
	left:50%;
	max-width:1600px;
	min-height:800px;
	width:90%;
	height:100%;
	opacity:0;
	transition:opacity 0.2s ease, transform 0.2s ease-out;
	transform:translate(-50%, 0%) scale(0);
}
.c-iframe-popup__wrapper--show {
	visibility:visible;
	opacity:1;
	transform:translate(-50%, 0%) scale(1);
}
.c-iframe-popup__icon {
	color:var(--color__base-white);
}
.c-iframe-popup--loading::before {
	position:absolute;
	top:50%;
	left:50%;
	width:40px;
	height:40px;
	background:url(../../images/loading.svg);
	background-size:100%;
	content:"";
	transform:translate(-50%, -50%);
}