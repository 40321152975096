/* =============================================================================
 * Clearfix
 *
 * Clears floats
 * See http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
============================================================================= */
.t-clearfix::after {
	display:block;
	clear:both;
	content:"";
}
.t-clear {
	clear:both;
}