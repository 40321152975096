/* =============================================================================
 * Tooltip
 *
 * A keyboard accessible, ARIA-enabled tooltip
============================================================================= */
.c-tooltip {
	display:inline-block;
	position:relative;
	text-align:left;
}
.c-tooltip__content {
	display:block;
	position:relative;
	margin-top:10px;
	padding:15px;
	border:1px solid rgb(0, 0, 0);
	box-shadow:0 0 4px rgba(0, 0, 0, 0.2);
	background:rgb(255, 255, 255);
	color:rgb(0, 0, 0);
	font-size:14px;
	line-height:1.4;
	opacity:1;
	-webkit-transition:all 0.16s ease;
	pointer-events:auto;
}
.c-tooltip__content.js {
	position:absolute;
	right:0;
	z-index:10;
}
.c-tooltip__content::before,
.c-tooltip__content::after {
	position:absolute;
	top:-7px;
	right:3px;
	margin-left:-4px;
	border:5px solid transparent;
	border-width:0 7px 7px 7px;
	border-bottom-color:rgb(0, 0, 0);
	content:"";
}
.c-tooltip__content::after {
	top:-6px;
	right:4px;
	border-width:0 6px 6px 6px;
	border-bottom-color:rgb(255, 255, 255);
}
.c-tooltip__content[aria-hidden="true"] {
	opacity:0;
	pointer-events:none;
}

@media only screen and (min-width:1000px) {
	.c-tooltip__content {
		width:250px;
	}
}


/* The element that triggers the tooltip
----------------------------------------------------------------------------- */
.c-tooltip__trigger {
	padding:4px;
	cursor:pointer;
}
.c-tooltip__trigger--active {
	background:#000;
	color:#fff;
}