/* =============================================================================
 * Grid
 *
 * Grid systems with named breakpoints:
 * - sm
 * - md (min-width:768px)
 * - lg (min-width:1024px)
 * - xl (min-width:1400px)
 *
 * Use .o-grid--inline-block if you don't want a grid based on display:flex
 * [1] min-height forces IE11 to collapse the height of .o-grid > [data-size] > * even if it
 *     contains an img. See https://github.com/philipwalton/flexbugs/issues/75.
============================================================================= */

/*
 * The default, flex-based grid
 */
.o-grid {
	display:-webkit-flex;
	display:flex;
	-ms-flex-wrap:wrap;
	-webkit-flex-wrap:wrap;
	flex-wrap:wrap;
}
.o-grid {
	gap:var(--space);
}
.o-grid--vertical {
	-ms-flex-direction:column;
	-webkit-flex-direction:column;
	flex-direction:column;
}
.o-grid > .o-grid__column {
	display:-webkit-flex;
	display:flex;
	-ms-flex-direction:column;
	-webkit-flex-direction:column;
	flex-direction:column;
	width:100%;
}

/* .o-grid > .o-grid__column:not(.c-section .o-grid__column) {
	margin-right:-var(--space);
	margin-bottom:-var(--space);
} */
.o-grid > .o-grid__column > * {
	-ms-flex:0 1 auto;
	-webkit-flex:0 1 auto;
	flex:0 1 auto;
	min-height:1px; /* [1] */
}
.o-grid--stretched > .o-grid__column > * {
	-ms-flex:1 1 auto;
	-webkit-flex:1 1 auto;
	flex:1 1 auto;
}

/*
 * Alignment
 */
.o-grid--align-top {
	-ms-flex-align:start;
	-webkit-align-items:flex-start;
	align-items:flex-start;
}
.o-grid--align-center {
	justify-content:center;
}
.o-grid--align-middle {
	-ms-flex-align:center;
	-webkit-align-items:center;
	align-items:center;
}
.o-grid--align-bottom {
	-ms-flex-align:end;
	-webkit-align-items:flex-end;
	align-items:flex-end;
}
.o-grid--align-right {
	-ms-flex-pack:end;
	-webkit-justify-content:flex-end;
	justify-content:flex-end;
}
.o-grid--align-left {
	justify-content:flex-start;
}
.o-grid__column--align-left[class] {
	margin-right:auto;
}
.o-grid__column--align-middle {
	align-items:center;
}
.o-grid__column--align-right[class] {
	margin-left:auto;
}

/*
 * The inline-block grid
 */
.o-grid--inline-block {
	display:block;
	font-size:0;
}
.o-grid--inline-block > .o-grid__column {
	display:inline-block;
	font-size:var(--font-size-default);
	vertical-align:top;
}


/*
 * Column widths
 *
 * Small (s)
----------------------------------------------------------------------------- */
.o-grid > [data-size="1"],
.o-grid > [data-size~="sm:1"] {
	width:calc(8.33333% - 18.33333px);
}
.o-grid > [data-size="2"],
.o-grid > [data-size~="sm:2"] {
	width:calc(16.66667% - 16.66667px);
}
.o-grid > [data-size="3"],
.o-grid > [data-size~="sm:3"] {
	width:calc(25% - 15px);
}
.o-grid > [data-size="4"],
.o-grid > [data-size~="sm:4"] {
	width:calc(33.33333% - 13.33333px);
}
.o-grid > [data-size="5"],
.o-grid > [data-size~="sm:5"] {
	width:calc(41.66667% - 11.66667px);
}
.o-grid > [data-size="6"],
.o-grid > [data-size~="sm:6"] {
	width:calc(50% - 10px);
}
.o-grid > [data-size="7"],
.o-grid > [data-size~="sm:7"] {
	width:calc(58.33333% - 8.33333px);
}
.o-grid > [data-size="8"],
.o-grid > [data-size~="sm:8"] {
	width:calc(66.66667% - 6.66667px);
}
.o-grid > [data-size="9"],
.o-grid > [data-size~="sm:9"] {
	width:calc(75% - 5px);
}
.o-grid > [data-size="10"],
.o-grid > [data-size~="sm:10"] {
	width:calc(83.33333% - 3.33333px);
}
.o-grid > [data-size="11"],
.o-grid > [data-size~="sm:11"] {
	width:calc(91.66667% - 1.66667px);
}
.o-grid > [data-size="12"],
.o-grid > [data-size~="sm:12"] {
	width:100%;
}

@media only screen and (max-width:767px) {
	.o-grid > [data-size~="sm:0"] {
		display:none;
	}
}


/* Medium (and print)
----------------------------------------------------------------------------- */
@media only screen and (min-width:768px), print {
	.o-grid > [data-size~="md:1"] {
		width:calc(8.33333% - 18.33333px);
	}
	.o-grid > [data-size~="md:2"] {
		width:calc(16.66667% - 16.66667px);
	}
	.o-grid > [data-size~="md:3"] {
		width:calc(25% - 15px);
	}
	.o-grid > [data-size~="md:4"] {
		width:calc(33.33333% - 13.33333px);
	}
	.o-grid > [data-size~="md:5"] {
		width:calc(41.66667% - 11.66667px);
	}
	.o-grid > [data-size~="md:6"] {
		width:calc(50% - 10px);
	}
	.o-grid > [data-size~="md:7"] {
		width:calc(58.33333% - 8.33333px);
	}
	.o-grid > [data-size~="md:8"] {
		width:calc(66.66667% - 6.66667px);
	}
	.o-grid > [data-size~="md:9"] {
		width:calc(75% - 5px);
	}
	.o-grid > [data-size~="md:10"] {
		width:calc(83.33333% - 3.33333px);
	}
	.o-grid > [data-size~="md:11"] {
		width:calc(91.66667% - 1.66667px);
	}
	.o-grid > [data-size~="md:12"] {
		width:100%;
	}
}

@media only screen and (min-width:768px) and (max-width:1023px) {
	.o-grid > [data-size~="md:0"] {
		display:none;
	}
}

/* Large
----------------------------------------------------------------------------- */
@media only screen and (min-width:1024px) {
	.o-grid > [data-size~="lg:1"] {
		width:calc(8.33333% - 18.33333px);
	}
	.o-grid > [data-size~="lg:2"] {
		width:calc(16.66667% - 16.66667px);
	}
	.o-grid > [data-size~="lg:3"] {
		width:calc(25% - 15px);
	}
	.o-grid > [data-size~="lg:4"] {
		width:calc(33.33333% - 13.33333px);
	}
	.o-grid > [data-size~="lg:5"] {
		width:calc(41.66667% - 11.66667px);
	}
	.o-grid > [data-size~="lg:6"] {
		width:calc(50% - 10px);
	}
	.o-grid > [data-size~="lg:7"] {
		width:calc(58.33333% - 8.33333px);
	}
	.o-grid > [data-size~="lg:8"] {
		width:calc(66.66667% - 6.66667px);
	}
	.o-grid > [data-size~="lg:9"] {
		width:calc(75% - 5px);
	}
	.o-grid > [data-size~="lg:10"] {
		width:calc(83.33333% - 3.33333px);
	}
	.o-grid > [data-size~="lg:11"] {
		width:calc(91.66667% - 1.66667px);
	}
	.o-grid > [data-size~="lg:12"] {
		width:100%;
	}
}

@media only screen and (min-width:1024px) and (max-width:1399px) {
	.o-grid > [data-size~="lg:0"] {
		display:none;
	}
}

/* Extra large
----------------------------------------------------------------------------- */
@media only screen and (min-width:1400px) {
	.o-grid > [data-size~="xl:0"] {
		display:none;
	}
	.o-grid > [data-size~="lg:1"] {
		width:calc(8.33333% - 18.33333px);
	}
	.o-grid > [data-size~="lg:2"] {
		width:calc(16.66667% - 16.66667px);
	}
	.o-grid > [data-size~="lg:3"] {
		width:calc(25% - 15px);
	}
	.o-grid > [data-size~="xl:4"] {
		width:calc(33.33333% - 13.33333px);
	}
	.o-grid > [data-size~="xl:5"] {
		width:calc(41.66667% - 11.66667px);
	}
	.o-grid > [data-size~="xl:6"] {
		width:calc(50% - 10px);
	}
	.o-grid > [data-size~="xl:7"] {
		width:calc(58.33333% - 8.33333px);
	}
	.o-grid > [data-size~="xl:8"] {
		width:calc(66.66667% - 6.66667px);
	}
	.o-grid > [data-size~="xl:9"] {
		width:calc(75% - 5px);
	}
	.o-grid > [data-size~="xl:10"] {
		width:calc(83.33333% - 3.33333px);
	}
	.o-grid > [data-size~="xl:11"] {
		width:calc(91.66667% - 1.66667px);
	}
	.o-grid > [data-size~="xl:12"] {
		width:100%;
	}
}


/* Print
----------------------------------------------------------------------------- */
@media print {
	.o-grid > [data-size~="p:0"] {
		display:none;
	}
	.o-grid > [data-size~="p:12"] {
		width:100%;
	}
	.o-grid > .o-grid__column {
		page-break-inside:avoid;
	}
	.o-grid--align-center > .o-grid__column:only-child {
		width:100%;
	}
}