/* =============================================================================
 * Floats
============================================================================= */
.t-float-left {
	float:left;
	margin:0 10px 10px 0;
}
.t-float-right {
	float:right;
	margin:0 0 10px 10px;
}