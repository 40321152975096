/* =============================================================================
 * Header search
 * [1] Edge fix
 * [2] Overriding inline CSS on mobile layout
============================================================================= */
.c-header-search {
	display:flex;
	display:-webkit-flex;
	position:relative;
	z-index:1;
}
.c-header-search__inner {
	overflow:hidden;
}
.c-header-search__toggle {
	display:none;
	position:absolute;
	top:50%;
	right:100%;
	width:0;
	transition:width 0.4s ease;
	transform:translateY(-50%);
}
.c-header-search__toggle--expand {
	width:350px;
}
.c-header-search__button {
	overflow:hidden;
	font-size:var(--font-size-action-menu);
	line-height:1.5;
}
.c-header-search__icons {
	display:inline-block;
	width:24px;
	transition:transform 0.4s ease;
}
.c-header-search__icons svg {
	pointer-events:none; /* [1] */
}
.c-header-search__text {
	display:inline-block;
	padding-left:7px;
	vertical-align:middle;
	white-space:nowrap;
	transition:transform 0.4s ease;
}
.c-header-search__button--open .c-header-search__icons,
.c-header-search__button--open .c-header-search__text {
	transform:translateX(100%);
}
.c-header-search--mobile {
	padding:0 var(--space);
}
.c-header-search--mobile .c-header-search__toggle {
	position:relative;
	top:auto;
	right:auto;
	width:100%;
	transform:none;
}
.c-header-search--mobile .c-header-search__button {
	display:none;
}
.c-header-search--mobile .c-header-search__toggle {
	display:block !important; /* [2] */
}

@media screen and (min-width:768px) and (max-width:1023px) {
	.c-header-search__text {
		padding-left:2px;
		font-size:12px;
	}
}