body {
	width:100%;
	height:100%;
	background:#fff;
	color:#000;
	font:400 var(--font-size-default)/var(--font-line-height-default) var(--font-family-default);
	-webkit-font-smoothing:antialiased;
}

@media (min-width:992px) {
	body {
		font:400 var(--font-size-large)/var(--font-line-height-default) var(--font-family-default);
	}
}

@media print {
	body {
		font-size:10pt;
		line-height:1.4;
	}
}

/* Prevent body from scrolling when mobile navigation is displayed */
@media screen and (max-width:1024px) {
	.js-body-locked {
		position:fixed;
		top:0;
		right:0;
		bottom:0;
		left:0;
		overflow:hidden;
	}
}
div.dibs-checkout-wrapper iframe,
div.nets-checkout-wrapper iframe {
	min-height:500px;
	width:100%;
	border:0;
	transition:height 0.2s;
}