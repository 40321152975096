.c-contact-block {
	display:block;
	padding-bottom:calc(var(--space) - 4px);
}
.c-contact-block img {
	display:flex;
	height:180px;
	margin:0 0 var(--space);
	border-radius:10%;
}
.c-contact-block__heading {
	margin-bottom:calc(var(--space) - 4px);
}
.c-contact-block__heading h3 {
	font-weight:700;
	font-size:24px;
	line-height:32px;
}
.c-contact-block__heading span {
	color:var(--color__grey-8);
}
.c-contact-block__info {
	display:grid;
}
.c-contact-block__info a {
	margin:0 !important;
	justify-self:flex-start;
}

@media screen and (min-width:600px) {
	.c-contact-block {
		display:flex;
		padding:calc(var(--space) - 4px) 0;
	}
	.c-contact-block img {
		display:inline;
		height:160px;
		margin:0 calc(var(--space) / 2) 0 0;
		border-radius:10%;
	}
	.c-contact-block__heading {
		text-align:left;
	}
	.c-contact-block__info {
		display:grid;
		justify-content:flex-start;
	}
}