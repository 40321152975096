/* =============================================================================
 * Slideshow
 *
 * Based on Flexslider
 *
 * [1] Enable absolute positioning of the controls
 * [2] Transition for IE since Flexslider only adds webkit transitions
 * [3] Hide slideshow before init to prevent image flickering
============================================================================= */
@import "flexslider.css";
.c-slideshow {
	position:relative; /* [1] */
	margin-bottom:calc(var(--space) * 3);
}
.js .c-slideshow {
	visibility:hidden; /* [3] */
}
.js .c-slideshow--show {
	visibility:visible;
}
.c-slideshow__slides {
	padding:0;
	list-style:none;
}
.c-slideshow__slide {
	display:block;
	position:relative;
	width:100%;
	text-align:center;
	-ms-transition:opacity 0.6s ease; /* [2] */
	-webkit-backface-visibility:hidden;
}

/* Dashboard slides */
.c-slideshow--dashboard {
	margin-bottom:0;
}
.c-slideshow__slide--dashboard {
	text-align:left;
}

@media print {
	.c-slideshow {
		background:transparent;
	}
	.c-slideshow__slides {
		width:auto !important;
		transform:none !important;
	}
	.c-slideshow__slide {
		float:none !important;
		width:auto !important;
		margin-right:0 !important;
		opacity:1 !important;
	}
	.c-slideshow__slide + .c-slideshow__slide {
		margin-top:1em;
	}
	.c-slideshow__slide.clone {
		display:none !important;
	}
	.c-slideshow__slide[class] img {
		display:block;
		position:static;
		height:auto;
		page-break-inside:avoid;
	}
	.flex-viewport {
		max-height:none;
		height:auto !important;
	}
}


/* Slideshow controls container
----------------------------------------------------------------------------- */
.c-slideshow__controls {
	position:absolute;
	right:0;
	bottom:calc((var(--space) * 2) * -1);
	left:0;
	text-align:center;
}

@media print {
	.c-slideshow__controls {
		display:none;
	}
}


/* Direction navigation
 * Previous/next arrows
----------------------------------------------------------------------------- */
.c-slideshow__direction-nav {
	display:flex;
	position:absolute;
	top:50%;
	right:0;
	left:0;
	z-index:10;
	justify-content:space-between;
	-webkit-transform:translateY(-50%);
	transform:translateY(-50%);
}
.c-slideshow__direction-nav .c-button {
	width:50px;
	height:50px;
	outline:none;
	background:linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.c-slideshow__direction-nav .c-icon {
	transition:transform 0.2s;
	fill:var(--color__primary);
}
.c-slideshow__direction-nav .c-button:hover {
	background-image:linear-gradient(to top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.c-slideshow__direction-nav .c-button:focus {
	box-shadow:0 0 0 1px rgba(255, 255, 255, 0.8) inset, 0 0 0 1px rgba(0, 0, 0, 0.8) inset;
}
.c-slideshow__direction-nav .c-button:hover .c-icon {
	fill:#fff;
}

@media print {
	.c-slideshow__direction-nav {
		display:none;
	}
}


/* Pause/play
 * Button that stops/resumes slide rotation
 *
 * [1] Hide the button that does not apply
----------------------------------------------------------------------------- */
.c-button.c-slideshow__pauseplay {
	display:block;
	padding:15px;
	color:inherit;
}
.c-slideshow__pauseplay .c-icon {
	display:block;
}
.c-slideshow__pauseplay--playing .c-pause-play__play,
.c-slideshow__pauseplay--paused .c-pause-play__pause {
	display:none; /* [1] */
}


/* Control navigation
 * Buttons that go to a specific slide
----------------------------------------------------------------------------- */
.c-slideshow__dot-nav .c-button {
	box-sizing:content-box;
	width:12px;
	height:12px;
	margin:0 4px;
	outline:none;
	border:2px solid #fff;
	border-radius:50%;
	box-shadow:0 1px 2px 1px rgba(0, 0, 0, 0.2);
	vertical-align:middle;
	transition:border-width 0.4s;
}
.c-slideshow__dot-nav .c-button:hover,
.c-slideshow__dot-nav .c-button:focus {
	box-shadow:0 0 0 1px #000, 0 0 0 3px #fff;
	background:rgba(255, 255, 255, 0.4);
}
.c-slideshow__dot-nav .c-button:first-child {
	margin-left:0;
}
.c-slideshow__dot-nav .c-button:last-child {
	margin-right:0;
}
.c-slideshow__dot-nav .c-button.c-dot-nav--current {
	background:var(--color__primary);
}