/* =============================================================================
 * Aspect ratio
============================================================================= */
.o-aspect-ratio {
	position:relative;
}
.o-aspect-ratio::before {
	display:block;
	width:100%;
	content:"";
}
.o-aspect-ratio__content {
	display:flex;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	justify-content:center;
	align-items:center;
}


/* 4:3
 ---------------------------------------------------------------------------- */
.o-aspect-ratio[data-ratio="4:3"]::before {
	padding-top:calc(3 / 4 * 100%);
}


/* 16:9
 ---------------------------------------------------------------------------- */
.o-aspect-ratio[data-ratio="16:9"]::before {
	padding-top:calc(9 / 16 * 100%);
}


/* A4
 ---------------------------------------------------------------------------- */
.o-aspect-ratio[data-ratio="A4"]::before {
	padding-top:calc(297 / 210 * 100%);
}


/* Golden ratio
---------------------------------------------------------------------------- */
.o-aspect-ratio[data-ratio="golden"]::before {
	padding-top:calc(1 / 1.618 * 100%);
}