.c-product-info {
	display:flex;
	position:relative;
}
.c-product-info__image-container {
	text-align:center;
}
.c-product-info__content {
	max-width:580px;
	overflow:hidden;
}
.c-product-info__heading {
	font-weight:600;
	font-size:var(--font-size-heading2);
}
.c-product-info__heading a {
	color:inherit;
}
.c-product-info__author {
	margin-bottom:calc(var(--space) - (var(--space) / 4));
	font-size:14px;
	line-height:20px;
}

@media screen and (min-width:768px) {
	.c-product-info__image-container {
		margin:0 var(--space) 0 0;
	}
	.c-product-info__content {
		margin-right:calc(240px - var(--space));
	}
	.c-product-info__links {
		position:absolute;
		top:calc(var(--space) / 2);
		right:var(--space);
		max-width:240px;
	}
}

@media screen and (max-width:767px) {
	.c-product-info {
		flex-direction:column;
	}
	.c-product-info__image-container {
		margin:0 0 var(--space) 0;
	}
	.c-product-info__image {
		min-width:165px;
		max-width:30%;
	}
	.c-product-info__heading {
		font-size:24px;
		line-height:32px;
	}
	.c-product-info__links {
		margin:calc(var(--space) / 2) 0 var(--space) 0;
	}
}

@media print {
	.c-product-info__content {
		margin-left:var(--space);
	}
}