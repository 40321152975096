/* Regular button
----------------------------------------------------------------------------- */
.c-button {
	display:inline-block;
	padding:9px 20px;
	border-radius:var(--border__radius);
	background:var(--color__primary-dark);
	color:var(--color__base-white);
	font-weight:700;
	font-size:16px;
	line-height:24px;
	text-align:center;
	text-decoration:none;
	cursor:pointer;
}
.c-button:hover {
	background:var(--color__hover-button);
	color:var(--color__base-white);
	text-decoration:underline;
}
.c-button:focus {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
	background:var(--color__primary-dark);
	color:var(--color__base-white);
}
.c-button:focus-visible {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
}

@media (min-width:992px) {
	.c-button {
		padding:12px 20px;
		font-size:var(--font-size-large);
	}
}


/* Block display
----------------------------------------------------------------------------- */
.c-button--block {
	display:block;
	width:100%;
}


/* Secondary colours
----------------------------------------------------------------------------- */
.c-button--secondary {
	border:2px solid var(--color__primary-dark);
	border-radius:2px;
	background-color:transparent;
	color:var(--color__primary-dark);
}
.c-button--secondary:hover {
	background-color:var(--color__ut_darkgreen-30);
	color:var(--color__primary-dark);
	text-decoration:underline;
}
.c-button--secondary:focus {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
	background-color:transparent;
	color:var(--color__primary-dark);
}


/* Inverted colours
----------------------------------------------------------------------------- */
.c-button--inverted {
	border-bottom-color:#cfcfcf;
	background-color:var(--color__base-white);
	color:var(--color__primary-dark);
}
.c-button--inverted:hover,
.c-button--inverted:focus {
	border-bottom-color:var(--color__base-white);
	background-color:var(--color__base-white);
	color:var(--color__primary-dark);
}


/* Blank
 * No nothing
----------------------------------------------------------------------------- */
.c-button--blank {
	padding:0;
	border:none;
	border-radius:0;
	background:transparent;
	color:inherit;
	font-weight:inherit;
}
.c-button--blank:hover,
.c-button--blank:focus {
	background:transparent;
	color:inherit;
}


/* Small
----------------------------------------------------------------------------- */
.c-button--small {
	padding:6px 10px 4px 10px;
	border-bottom-width:2px;
	font-weight:600;
	font-size:16px;
	line-height:20px;
}


/* External link
----------------------------------------------------------------------------- */
a.c-button[rel~="external"]::after {
	display:inline-block;
	width:14px;
	height:14px;
	margin-left:6px;
	background:url(../../components/icon/icons/external-link-white.svg?embed) no-repeat right center;
	background-size:contain;
	content:"";
	vertical-align:2px;
}


/* Link Button
----------------------------------------------------------------------------- */
.c-button--link {
	padding:0;
	border:0;
	background:none;
	color:var(--color__link-color);
	font-weight:600;
}
.c-button--link svg {
	margin-left:1rem;
}
.c-button--link:hover {
	border-bottom-color:unset;
	background-color:unset;
	color:unset;
}
.c-button--link:focus {
	background-color:unset;
	color:unset;
}

/* Disabled
----------------------------------------------------------------------------- */
.c-button[disabled] {
	border:none;
	background-color:var(--color__ut_darkgrey);
	color:var(--color__base-white);
	text-decoration:none;
}


/* Print
----------------------------------------------------------------------------- */
@media print {
	.c-button:not(.c-button--blank) {
		border:1px solid #000;
		background:#fff;
		color:#000;
	}
}