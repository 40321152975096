/* =============================================================================
 * Text scope
 *
 * Adds styling to elements when inside the scope .s-text, e.g. margin
 * or another font-weight than 'normal'.
============================================================================= */

/* Margins
 * Weird syntax for clarity's sake.
 ---------------------------------------------------------------------------- */
.s-text ul,
ul.s-text,
.s-text ol,
ol.s-text,
.s-text dl,
dl.s-text,
.s-text blockquote:not([class]),
blockquote[class="s-text"],
.s-text p,
p.s-text,
.s-text address,
address.s-text,
.s-text hr,
hr.s-text,
.s-text table,
table.s-text,
.s-text fieldset,
fieldset.s-text,
.s-text figure,
figure.s-text,
.s-text pre,
pre.s-text,
.s-text img,
img.s-text {
	margin-bottom:var(--space);
}
.s-text form,
form.s-text {
	margin-bottom:calc(var(--space) * 2);
}
.s-text h1,
h1.s-text,
.s-text h2,
h2.s-text,
.s-text h3,
h3.s-text,
.s-text h4 {
	margin-bottom:calc(var(--space) / 2);
}

/* Inline typography
 ---------------------------------------------------------------------------- */
.s-text i,
i.s-text,
.s-text em,
em.s-text {
	font-style:italic;
}
.s-text b,
b.s-text,
.s-text strong,
strong.s-text {
	font-weight:700;
}
.s-text h1,
h1.s-text {
	font-weight:700;
}
.s-text h2,
h2.s-text {
	font-weight:600;
}
.s-text h3,
h3.s-text
.s-text h4,
h4.s-text,
.s-text h5,
h5.s-text {
	font-weight:400;
}
.s-text h1,
h1.s-text {
	color:var(--color__primary-dark);
	font-size:var(--font-size-heading);
	line-height:var(--font-line-height-heading);
}
.s-text h2,
h2.s-text {
	font-size:var(--font-size-heading2);
	line-height:var(--font-line-height-heading2);
}
.s-text h3,
h3.s-text {
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.s-text h4,
h4.s-text {
	font-size:var(--font-size-heading4);
	line-height:var(--font-line-height-heading4);
}
.s-text h5,
h5.s-text {
	font-size:var(--font-size-default);
}
.s-text .intro {
	font-size:var(--font-size-intro);
	line-height:var(--font-line-height-intro);
}
.s-text .divider {
	display:block;
	clear:both;
	padding-top:var(--space);
	border-top:3px solid var(--color__primary);
}
.s-text .highlight {
	display:block;
	clear:both;
	padding:var(--space);
	border:2px solid var(--color__primary);
}

/* .s-text .s-class a {
	font-weight:600;
	text-decoration:underline;
} */

/* New scope with more space */
@media screen and (min-width:992px) {
	.s-text ul,
	.s-text ol,
	.s-text dl,
	.s-text blockquote:not([class]),
	.s-text p,
	.s-text address,
	.s-text hr,
	.s-text table,
	.s-text fieldset,
	.s-text figure,
	.s-text pre,
	.s-text a {
		font-size:var(--font-size-large);
		line-height:1.75;
	}
	.s-prose ul,
	.s-prose ol,
	.s-prose dl,
	.s-prose blockquote:not([class]),
	.s-prose p,
	.s-prose address,
	.s-prose hr,
	.s-prose table,
	.s-prose fieldset,
	.s-prose figure,
	.s-prose pre,
	.s-prose a {
		margin-bottom:calc(var(--space) * 2);
	}
	.s-prose--md ul,
	.s-prose--md ol,
	.s-prose--md dl,
	.s-prose--md blockquote:not([class]),
	.s-prose--md p,
	.s-prose--md address,
	.s-prose--md hr,
	.s-prose--md table,
	.s-prose--md fieldset,
	.s-prose--md figure,
	.s-prose--md pre {
		font-size:var(--font-size-large);
	}
	.s-prose h1,
	.s-prose h2,
	.s-prose h3,
	.s-prose h4 {
		margin-bottom:var(--space);
	}
	.s-prose .intro,
	.s-prose .intro p {
		font-size:calc(var(--font-size-intro) + 4px);
		line-height:calc(var(--font-line-height-intro) + 6px);
	}
	.s-prose img {
		margin-bottom:calc(var(--space) * 2);
	}
	.s-prose time {
		margin-bottom:var(--space);
		font-size:calc(var(--font-size-intro) + 4px);
		line-height:1.5;
	}
}

@media screen and (max-width:767px) {
	.s-text h1,
	h1.s-text {
		font-size:var(--font-size-heading-mobile);
		line-height:var(--font-line-height-heading-mobile);
	}
	.s-text h2,
	h2.s-text {
		font-size:var(--font-size-heading2-mobile);
		line-height:var(--font-line-height-heading2-mobile);
	}
	.s-prose--md ul,
	.s-prose--md ol,
	.s-prose--md dl,
	.s-prose--md blockquote:not([class]),
	.s-prose--md p,
	.s-prose--md address,
	.s-prose--md hr,
	.s-prose--md table,
	.s-prose--md fieldset,
	.s-prose--md figure,
	.s-prose--md pre {
		font-size:var(--font-size-large);
	}
}

/* Links
 ---------------------------------------------------------------------------- */
.s-text a:not(.c-button):not(.button-editor--secondary):not(.button-editor),
.s-prose a:not(.c-button):not(.button-editor--secondary):not(.button-editor) {
	font-weight:600;
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.s-text a:not(.c-button):not(.button-editor--secondary):not(.button-editor),
.s-prose a:not(.c-button):not(.button-editor--secondary):not(.button-editor) {
	color:var(--color__link-color);
}
.s-text a:not(.c-button):not(.button-editor--secondary):not(.button-editor):hover,
.s-prose a:not(.c-button):not(.button-editor--secondary):not(.button-editor):hover {
	color:var(--color__hover-button);
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.s-text a:not(.c-button):not(.button-editor--secondary):not(.button-editor):focus,
.s-prose a:not(.c-button):not(.button-editor--secondary):not(.button-editor):focus {
	color:var(--color__hover-button);
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.s-text a:not(.c-button):focus-visible,
.s-prose a:not(.c-button):focus-visible {
	outline:none;
}
.s-text a.t-border-none,
.s-text a.t-border-none:hover
.s-text a.t-border-none:focus {
	border:none;
}
div[class*="green"]:not(div[class*="-20"]) .s-text a:not(.c-button):not(.button-editor--secondary),
div[class*="green"]:not(div[class*="-20"]) .s-prose a,
div[class*="--bg-red"] .s-text a:not(.c-button):not(.button-editor--secondary),
div[class*="--bg-red"] .s-prose a,
div[class*="--bg-darkgrey"] .s-text a:not(.c-button):not(.button-editor--secondary),
div[class*="--bg-darkgrey"] .s-prose a,
div[class*="--bg-black"] .s-text a:not(.c-button):not(.button-editor--secondary),
div[class*="--bg-black"] .s-prose a {
	color:var(--color__base-white);
}
div[class*="green"]:not(div[class*="-20"]) .s-text a:not(.c-button):not(.button-editor--secondary):hover,
div[class*="green"]:not(div[class*="-20"]) .s-prose a:hover,
div[class*="--bg-red"] .s-text a:not(.c-button):not(.button-editor--secondary):hover,
div[class*="--bg-red"] .s-prose a:hover,
div[class*="--bg-darkgrey"] .s-text a:not(.c-button):not(.button-editor--secondary):hover,
div[class*="--bg-darkgrey"] .s-prose a:hover,
div[class*="--bg-black"] .s-text a:not(.c-button):not(.button-editor--secondary):hover,
div[class*="--bg-black"] .s-prose a:hover {
	color:var(--color__ut_red-30);
}
div[class*="green"]:not(div[class*="-20"]) .s-text a:not(.c-button):not(.button-editor--secondary):focus,
div[class*="green"]:not(div[class*="-20"]) .s-prose a:focus,
div[class*="--bg-red"] .s-text a:not(.c-button):not(.button-editor--secondary):focus,
div[class*="--bg-red"] .s-prose a:focus,
div[class*="--bg-darkgrey"] .s-text a:not(.c-button):not(.button-editor--secondary):focus,
div[class*="--bg-darkgrey"] .s-prose a:focus,
div[class*="--bg-black"] .s-text a:not(.c-button):not(.button-editor--secondary):focus,
div[class*="--bg-black"] .s-prose a:focus {
	color:var(--color__ut_yellow-50);
}

/* Quotes
 *
 * [1] Line up first letter in first row with the second row's left side.
 ---------------------------------------------------------------------------- */
.s-text blockquote:not([class]) p:first-of-type,
blockquote[class="s-text"] p:first-of-type {
	text-indent:-0.33em; /* [1] */
}
.s-text blockquote:not([class]) p:first-of-type::before,
blockquote[class="s-text"] p:first-of-type::before {
	content:"”";
}
.s-text blockquote:not([class]) p:last-of-type::after,
blockquote[class="s-text"] p:last-of-type::after {
	content:"”";
}


/* Lists
 *
 * 1. Remove trailing margins from nested lists.
 ---------------------------------------------------------------------------- */
.s-text ul,
ul.s-text {
	list-style:disc;
}
.s-text ol,
ol.s-text {
	list-style:decimal;
}
.s-text ul,
ul.s-text,
.s-text ol,
ol.s-text,
.s-text dd,
dd.s-text {
	margin-left:var(--space);
}
.s-text ul.c-page-list__item-tags {
	margin:0 0 10px;
}
.s-text li {
	margin-bottom:calc(var(--space) / 4);
}
.s-text li > ul,
.s-text li > ol {
	margin-top:5px;
	margin-bottom:0; /* [1] */
}
.s-text dt {
	font-weight:700;
}
.s-text dd {
	margin-bottom:calc(var(--space) / 2);
}
.s-text > ul {
	margin-top:var(--space);
}

/* Tables
 ---------------------------------------------------------------------------- */
.s-text table,
table.s-text {
	margin-bottom:calc(var(--space) * 2);
}
.s-text caption,
caption.s-text {
	padding-bottom:10px;
	border-bottom:1px solid #ccc;
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.s-text th,
th.s-text {
	padding:15px 20px 15px 0;
	border-bottom:1px solid #ccc;
	font-weight:bold;
}
.s-text td,
td.s-text,
.s-text tbody th,
tbody th.s-text {
	padding:15px 20px 15px 0;
}
.s-text tfoot,
tfoot.s-text {
	font-weight:bold;
}

/* Buttons
 ---------------------------------------------------------------------------- */
.s-text .button-editor {
	display:inline-block;
	margin:0;
	padding:9px 20px;
	border-radius:var(--border__radius);
	background:var(--color__primary-dark);
	color:var(--color__base-white);
	font-weight:700;
	font-size:16px;
	line-height:24px;
	text-align:center;
	text-decoration:none;
	cursor:pointer;
}
.s-text .button-editor[class]:hover {
	border:0;
	background:var(--color__hover-button);
	color:var(--color__base-white);
	text-decoration:underline;
}
.s-text .button-editor[class]:focus {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
	border:0;
	background:var(--color__primary-dark);
	color:var(--color__base-white);
}
.s-text .button-editor--secondary {
	display:inline-block;
	margin:0;
	padding:9px 20px;
	border:2px solid var(--color__primary-dark);
	border-radius:2px;
	background-color:transparent;
	color:var(--color__primary-dark);
	font-weight:700;
	font-size:16px;
	line-height:24px;
	text-align:center;
	text-decoration:none;
	cursor:pointer;
}
.s-text .button-editor--secondary[class]:hover {
	border:2px solid var(--color__primary-dark);
	background-color:var(--color__ut_darkgreen-30);
	color:var(--color__primary-dark);
	text-decoration:underline;
}
.s-text .button-editor--secondary[class]:focus {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
	border:2px solid var(--color__primary-dark);
	background-color:transparent;
	color:var(--color__primary-dark);
}

@media (min-width:992px) {
	.s-text .button-editor,
	.s-text .button-editor--secondary {
		padding:12px 20px;
		font-size:var(--font-size-large);
	}
}