.c-shortcuts-menu {
	display:flex;
	justify-content:flex-end;
	flex:1 1 auto;
}
.c-shortcuts-menu__list {
	display:flex;
	display:-webkit-flex;
	flex-direction:column;
	max-width:276px;
	width:100%;
}
.c-shortcuts-menu__item {
	width:auto;
	height:56px;
	margin:0 0 16px;
	border-right:1px var(--color__ut_darkgrey) solid;
	border-bottom:1px var(--color__ut_darkgrey) solid;
	border-left:4px var(--color__ut_green) solid;
	background-color:var(--color__base-white);
	transition-duration:0.1s;
}
.c-shortcuts-menu__item:last-of-type {
	margin-bottom:0;
}
.c-shortcuts-menu__list li a {
	display:flex;
	display:-webkit-flex;
	justify-content:space-between;
	-webkit-align-items:center;
	align-items:center;
	width:100%;
	height:100%;
	padding:10px;
	color:var(--color__base-black);
	font-size:var(--font-size-large);
	text-align:center;
	text-decoration:none !important;
}
.c-shortcuts-menu__list li a span span {
	margin-left:5px;
}
.c-shortcuts-menu__list li a span span:not(span.c-icon):not(span.c-icon + span) {
	margin-left:39px;
}
.c-shortcuts-menu__item:hover,
.c-shortcuts-menu__item:focus {
	background-color:var(--color__ut_darkgreen-20);
}
.c-shortcuts-menu__link a:hover {
	text-decoration:none;
}
.c-shortcuts-menu__icon-text {
	margin-left:5px;
	line-height:21px;
}
.c-shortcuts-menu__arrow {
	transition-duration:0.1s;
	transform:rotate(90deg);
}
.c-shortcuts-menu__item:hover .c-shortcuts-menu__arrow {
	transform:rotate(90deg) translateY(-6px);
}

@media screen and (max-width:1024px) {
	.c-shortcuts-menu__list {
		max-width:unset;
		padding:0;
	}
	.c-shortcuts-menu {
		background-color:var(--color__ut_grey-30);
	}
	.c-shortcuts-menu__item {
		margin:2px 0 0 20px;
		border-right:none;
		border-bottom:0;
		border-left:5px var(--color__ut_green) solid;
	}
	.c-shortcuts-menu__list li a {
		padding:10px 20px 10px 15px;
	}
}