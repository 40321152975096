/* =============================================================================
 * Social media
============================================================================= */
.c-social-media {
	display:flex;
	flex-wrap:wrap;
}
.c-social-media__item {
	padding-right:calc(var(--space) / 2);
}
.c-social-media__item:last-child {
	padding-right:0;
}
.c-social-media__link {
	display:block;
	margin:4px 0 6px;
}
.c-social-media__link[rel~="external"]::after {
	content:none;
}
.c-social-media__icon {
	display:block;
	width:32px;
	height:32px;
}