/* =============================================================================
 * Dialog
============================================================================= */
.c-dialog {
	display:block;
	position:fixed;
	top:50%;
	right:0;
	left:0;
	max-width:800px;
	min-height:100px;
	width:calc(100vw - 60px);
	margin-right:auto;
	margin-left:auto;
	padding:0;
	border:1px solid #000;
	box-shadow:0 0 20px rgba(0, 0, 0, 0.5);
	background:#fff;
	color:#000;
	transform:translate(0, -50%);
}
.c-dialog:not([open]) {
	display:none;
}
.c-dialog__document {
	position:relative;
}
.c-dialog__close {
	position:absolute;
	top:0;
	right:0;
	box-sizing:content-box;
	width:16px;
	height:16px;
	margin:0;
	padding:10px;
	border:none;
	background:transparent;
	cursor:pointer;
	fill:var(--color__primary);
}
.c-dialog__close svg {
	width:16px;
	height:16px;
}
.c-dialog__title {
	margin:0;
	padding:15px 30px 15px 15px;
	font:inherit;
	font-weight:700;
	font-size:20px;
	line-height:24px;
}
.c-dialog__content {
	padding:0 15px 15px;
}
.c-dialog__content > :last-child,
.c-dialog__content > :last-child :last-child {
	margin-bottom:0;
}

@media (min-width:768px) {
	.c-dialog__title {
		padding:40px 40px 20px;
	}
	.c-dialog__content {
		padding:0 40px 40px;
	}
	.c-dialog__close,
	.c-dialog__close svg {
		width:24px;
		height:24px;
	}
}

/* Backdrop
----------------------------------------------------------------------------- */

/*
 * Native dialog backdrop
 */
dialog::-webkit-backdrop {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:rgba(22, 22, 22, 0.8);
}
dialog::backdrop {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:rgba(22, 22, 22, 0.8);
}

/*
 * Polyfill backdrop
 */
.c-dialog ~ .backdrop {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:rgba(22, 22, 22, 0.8);
}
.c-dialog ~ ._dialog_overlay {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
}


/* Inline dialogs
 * Hide elements with content that will potentially be displayed in a dialog.
----------------------------------------------------------------------------- */
.c-dialog__inline-content {
	display:none;
}