.c-product--price-aligned {
	height:100%;
}
.c-product__inner {
	position:relative;
	max-width:182px;
	margin:0 auto var(--space) 0;
	text-align:center;
}
.c-product-redesign .c-product__inner {
	margin-bottom:0;
}
.c-product--price-aligned .c-product__inner {
	height:100%;
}
.c-product-grid__heading {
	display:block;
	padding:calc(var(--space) * 2) var(--space);
}
.c-product-grid__heading-text {
	margin:auto;
	font-weight:600;
	font-size:var(--font-size-heading2);
	text-align:center;
}
.c-product-grid__heading .c-form-item {
	display:block;
	margin-right:0;
}
.c-product__link {
	display:block;
	min-height:225px;
	width:100%;
	padding:15px;
	outline:none;
	text-decoration:none;
}
.c-product--price-aligned .c-product__link {
	display:flex;
	flex-direction:column;
	height:calc(100% - 20px);
}
.c-product__link:hover,
.c-product__link:focus {
	background:var(--color__primary-alt_light);
	text-decoration:none;
}
.c-product__link:hover .c-product__name,
.c-product__link:focus .c-product__name {
	text-decoration:underline;
}
.c-product__link:focus {
	outline:2px solid var(--color__primary-dark);
}
.c-product__bubble-wrapper {
	display:flex;
	position:absolute;
	top:5px;
	left:15px;
	z-index:5;
	align-items:flex-start;
}
.c-product__bubble {
	width:32px;
	height:32px;
	margin-right:5px;
	border-radius:50%;
	background-color:var(--color__ut_red);
}

/* .c-product__bubble:hover {
	background-color:var(--color__primary-dark);
} */
.c-product__bubble [class*="c-icon--"] {
	color:var(--color__base-white);
}
.c-product__bubble--new {
	position:absolute;
	top:5px;
	right:18px;
	width:32px;
	height:32px;
}
.c-product__bubble--selected {
	position:absolute;
	top:-8px;
	right:10px;
	z-index:10;
}
.c-product__bubble--selected > svg {
	width:106px;
	height:36px;
}
.c-product__bubble--bingel {
	top:20px;
	left:10px;
}
.c-product__bubble--bingel > .c-product__bubble {
	border-radius:0;
	background-color:transparent;
}
.c-product__bubble--bingel > .c-product__bubble:hover {
	background-color:transparent;
}
.c-product__bubble--bingel > .c-product__bubble use {
	stroke:#000;
	stroke-width:0.25;
	stroke-miterlimit:1;
}
.c-product__image-container {
	min-height:160px;
	width:100%;
	height:210px;
}
.c-product__image--landscape {
	height:100px;
}
.c-product__name,
.c-product__text {
	display:block;
	color:var(--color__base-black);
	font-weight:400;
	text-align:left;
}

/* autoprefixer: off */
.c-product__name {
	display:-webkit-box;
	overflow:hidden;
	padding-top:calc(var(--space) / 2);
	font-weight:700;
	font-size:16px;
	text-wrap:balance;
	text-overflow:ellipsis;
	-webkit-line-clamp:4;
	-webkit-box-orient:vertical;
}
.c-product--price-aligned .c-product__name,
.c-product--price-aligned .c-product__text {
	margin-bottom:4px;
}

/* autoprefixer: on */
.c-product__read-more {
	display:flex;
	position:relative;
	color:var(--color__link-color);
	font-weight:600;
	font-size:18px;
	text-decoration:underline;
	cursor:pointer;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-product__read-more:hover {
	color:var(--color__link-hover);
}
.c-product__read-more:focus {
	color:var(--color__link-active);
}
.c-product__arrow {
	margin:5px;
}

/* Product redesign */
.c-product-redesign .c-product__link {
	padding:0;
}
.c-product-redesign .c-product__image-container {
	height:230px;
}
.c-product-redesign .c-product__bubble-wrapper {
	top:unset;
	right:0;
	left:unset;
	flex-direction:column;
}
.c-product-redesign .c-product__bubble:first-child {
	margin-top:10px;
}
.c-product-redesign .c-product__bubble {
	margin-right:10px;
	margin-bottom:5px;
	background-color:#fff;
}
.c-product-redesign .c-product__bubble [class*="c-icon--"] {
	color:var(--color__base-black);
}
.c-product-redesign .c-product__bubble:hover {
	background-color:var(--color__primary-alt);
}
.c-product-redesign .c-product__bubble:hover > .c-icon {
	color:var(--color__base-white);
}
.c-product-redesign .c-product__name {
	padding-top:10px;
	font-weight:700;
	font-size:18px;
	text-align:left;;
}
.c-product__price {
	display:block;
	margin:5px 0 0;
	color:var(--color__ut_darkgreen);
	font-weight:600;
	text-align:left;
}
.c-product-redesign .c-product__price {
	margin-bottom:0;
}
.c-product--price-aligned .c-product__price {
	margin-top:auto;
}

@media screen and (min-width:992px) {
	.c-product__name {
		font-size:var(--font-size-large);
	}
}

@media screen and (min-width:769px) {
	.c-product-grid__heading .c-form-item {
		margin-right:calc(var(--space) - 5px);
	}
	.c-product__bubble--bingel {
		top:5px;
		left:15px;
	}
}

@media screen and (min-width:500px) {
	.c-product__link {
		min-height:285px;
	}
	.c-product__image-container {
		height:200px;
	}
	.c-product__bubble-wrapper {
		left:0;
	}
	.c-product-grid__heading {
		padding:0 0 calc(var(--space));
	}
	.c-product-grid__heading-text {
		flex:1;
		margin:0;
		text-align:left;
	}
	.c-product-grid__heading,
	.c-product-grid__heading .c-form-item {
		display:flex;
	}
}