.c-mobile-navigation {
	display:none;
	position:absolute;
	top:67px;
	right:0;
	bottom:0;
	left:0;
	z-index:1000;
	overflow-y:scroll;
	background-color:rgba(255, 255, 255, 0.98);
	-webkit-overflow-scrolling:touch;
}
.c-mobile-navigation__wrapper {
	outline:none;
}
.c-mobile-navigation__content {
	clear:both;
}

@media screen and (min-width:1025px) {
	.c-mobile-navigation {
		display:none;
	}
}

@media screen and (max-width:1024px) {
	.c-mobile-navigation--open {
		display:block;
	}
	.c-mobile-navigation--open:focus {
		outline:none;
	}
}