/* =============================================================================
 * Visually hidden
 *
 * Hidden visually, but screen readers will read it.
 * Remember, any change must be made in all variants of the trump.
============================================================================= */
.t-visually-hidden {
	position:absolute;
	width:1px;
	height:1px;
	overflow:hidden;
	clip:rect(1px, 1px, 1px, 1px);
	padding:0;
	border:0;
}
.t-visually-hidden--focusable:active,
.t-visually-hidden--focusable:focus {
	position:static;
	width:auto;
	height:auto;
	overflow:visible;
	clip:auto;
}

/*
 * @md
 */
@media screen and (min-width:768px) {
	.t-visually-hidden\@md {
		position:absolute;
		width:1px;
		height:1px;
		overflow:hidden;
		clip:rect(1px, 1px, 1px, 1px);
		padding:0;
		border:0;
	}
}

/*
 * @lg
 */
@media screen and (min-width:1024px) {
	.t-visually-hidden\@lg {
		position:absolute;
		width:1px;
		height:1px;
		overflow:hidden;
		clip:rect(1px, 1px, 1px, 1px);
		padding:0;
		border:0;
	}
}

/*
 * @xl
 */
@media screen and (min-width:1400px) {
	.t-visually-hidden\@xl {
		position:absolute;
		width:1px;
		height:1px;
		overflow:hidden;
		clip:rect(1px, 1px, 1px, 1px);
		padding:0;
		border:0;
	}
}