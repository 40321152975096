.c-carousel {
	width:100%;
	margin:0;
}
.c-carousel__heading {
	padding-left:var(--space);
	font-weight:600;
	font-size:var(--font-size-heading2);
}
.c-carousel__container {
	position:relative;
	overflow:hidden;
}
.c-carousel__list {
	display:flex;
	overflow-x:scroll;
	scroll-snap-type:x mandatory;
	-ms-overflow-style:none;
	scrollbar-width:none;
}
.c-carousel__list::-webkit-scrollbar {
	display:none;
}
.c-carousel__list > * {
	scroll-snap-align:center;
}
.c-carousel__list--disabled li {
	pointer-events:none;
}
.c-carousel__listitem {
	display:flex;
	justify-content:center;
	align-items:center;
	flex:0 0 auto;
	min-width:250px;
	width:calc(100% - var(--space));
	height:350px;
	margin:calc(var(--space) / 2);
	transition-duration:0.1s;
}
.c-carousel__listitem:first-of-type {
	margin-left:var(--space);
}
.c-carousel__listitem:nth-last-of-type(2) {
	margin-right:5.8px;
}
.c-carousel__listitem .c-teaser {
	width:100%;
	height:100%;
	margin:0;
	transform:translate(0);
}
.c-carousel__listitem .c-teaser__content {
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	min-height:112px;
	border-bottom:none;
	opacity:0.9;
}
.c-carousel__listitem .c-teaser__content {
	font-weight:400;
	text-decoration:none;
}
.c-carousel__listitem .c-teaser__content:focus {
	outline:none;
}
.c-carousel__listitem .c-teaser__overlay--bg-green-20 .c-teaser__heading,
.c-carousel__listitem .c-teaser__overlay--bg-darkgreen-20 .c-teaser__heading,
.c-carousel__listitem .c-teaser__overlay--bg-yellow-20 .c-teaser__heading,
.c-carousel__listitem .c-teaser__overlay--bg-white .c-teaser__heading {
	color:var(--color__ut_red);
}
.c-carousel__listitem .c-teaser__content:focus .c-teaser__heading,
.c-carousel__listitem .c-teaser__content:hover .c-teaser__heading {
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-carousel__listitem .c-teaser__overlay--bg-green-20 .c-teaser__heading,
.c-carousel__listitem .c-teaser__overlay--bg-darkgreen-20 .c-teaser__heading,
.c-carousel__listitem .c-teaser__overlay--bg-yellow-20 .c-teaser__heading,
.c-carousel__listitem .c-teaser__overlay--bg-white .c-teaser__heading {
	background-image:url(/ui-project/components/icon/icons/carousel-link-arrow.svg);
}
.c-carousel__listitem .c-teaser__heading {
	order:1;
	margin-top:7px;
	padding-left:25px;
	background-image:url(/ui-project/components/icon/icons/arrow-right-alt-white.svg);
	background-position-y:center;
	background-repeat:no-repeat;
	color:inherit;
	font-size:var(--font-size-heading4);
	line-height:var(--font-line-height-heading4);
}
.c-carousel__button {
	position:absolute;
	top:calc(50% - 33px);
	z-index:1;
	width:45px;
	height:65px;
	border:none;
	background:#fff;
	background-position:center;
	background-repeat:no-repeat;
	color:transparent;
	font-size:1px;
	opacity:0.9;
	cursor:pointer;
	transition-duration:0.3s;
}
.c-carousel__button--left {
	left:-50px;
	background-image:url(/ui-project/components/icon/icons/carousel-left-arrow.svg);
}
.c-carousel__button--right {
	right:-50px;
	background-image:url(/ui-project/components/icon/icons/carousel-right-arrow.svg);
}
.dots {
	margin-top:20px;
	text-align:center;
}
.dots span {
	display:inline-block;
	width:10px;
	height:10px;
	margin:0 10px;
	border-radius:50%;
	background:#fff;
}

@media only screen and (min-width:370px) {
	.c-carousel__listitem {
		width:350px;
	}
}

@media (min-width:768px) {
	.c-carousel__listitem .c-teaser__content::before {
		position:fixed; /* not absolute but fixed */
		top:0;
		right:0;
		bottom:0;
		left:0;
		content:"";
		transition-duration:0.1s;
		pointer-events:none;
	}
	.c-carousel__listitem .c-teaser__content:hover::before,
	.c-carousel__listitem:focus-within {
		outline:2px dashed var(--color__primary-dark);
		outline-offset:4px;
		border-bottom:none;
	}
	.c-carousel__listitem:nth-last-of-type(2) {
		margin-right:calc(var(--space) - 5px);
	}
	.c-carousel__button:hover {
		box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
		opacity:1;
		transform:scale(1.05);
	}
}

@media only screen and (min-width:1000px) {
	.c-carousel__listitem .c-teaser__content {
		min-height:118px;
	}
}