/* =============================================================================
 * Page intro
 *
 * [1] min-height forces IE11 to collapse the height of .o-grid > [data-size] > * even if it
 *     contains an img. See https://github.com/philipwalton/flexbugs/issues/75.
 * [2] fix IE 11 flex overflow bug
============================================================================= */
.c-page-intro__centered-content {
	margin-bottom:var(--space);
	text-align:center;
}
.c-page-intro__centered-content--no-margin,
.c-page-intro__centered-content--no-margin > *:last-child {
	margin-bottom:0;
}
.c-page-intro__content {
	display:flex;
	flex-direction:column;
	align-items:flex-start;
	margin-bottom:var(--space);
}
.c-page-intro__content > * {
	max-width:100%; /* [2] */
}
.c-page-intro__content > *:last-child {
	margin-bottom:0;
}
.c-page-intro__content-bottom {
	margin-top:auto;
	padding-top:var(--space);
}
.c-page-intro__media {
	display:flex;
	flex-direction:column;
	margin-bottom:var(--space);
}
.c-page-intro__image {
	flex:1 1 auto;
}

@media only screen and (min-width:768px) {
	.c-page-intro {
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;
	}
	.c-page-intro__centered-content {
		width:100%;
	}
	.c-page-intro__content,
	.c-page-intro__media {
		width:calc(50% - calc(var(--space) / 2));
	}
	.c-page-intro__media img {
		min-height:1px; /* [1] */
	}
}