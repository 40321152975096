/* =============================================================================
 * List inline
============================================================================= */

/*
 * Default
 */
.o-list-inline-pairs__title,
.o-list-inline-pairs__desc {
	margin-bottom:4px;
}
.o-list-inline-pairs__title:last-of-type,
.o-list-inline-pairs__desc:last-of-type {
	margin-bottom:0;
}
.o-list-inline-pairs .o-list-inline-pairs__title,
.o-list-inline-pairs .o-list-inline-pairs__desc {
	float:left;
	max-width:140px;
	width:calc(50% - (var(--space) / 2));
	word-wrap:break-word;
}
.o-list-inline-pairs .o-list-inline-pairs__title {
	clear:both;
	margin-right:var(--space);
	white-space:nowrap;
}

/*
 * Remove margin
 */
.o-list-inline-pairs--no-margin .o-list-inline-pairs__title,
.o-list-inline-pairs--no-margin .o-list-inline-pairs__desc {
	margin-bottom:0;
}

/*
 * Collapsed
 */
.o-list-inline-pairs--collapsed {
	display:block;
	width:100%;
}
.o-list-inline-pairs--collapsed .o-list-inline-pairs__title,
.o-list-inline-pairs--collapsed .o-list-inline-pairs__desc {
	display:block;
	float:none;
	max-width:none;
	width:auto;
}
.o-list-inline-pairs--collapsed .o-list-inline-pairs__title {
	float:left;
	margin-right:calc(var(--space) / 4);
}

/*
 * Align right
 */
.o-list-inline-pairs--align-right {
	display:inline-block;
	min-width:250px;
}
.o-list-inline-pairs--align-right .o-list-inline-pairs__title {
	max-width:unset;
	width:auto;
	margin-right:var(--space);
}
.o-list-inline-pairs--align-right .o-list-inline-pairs__desc {
	float:right;
	max-width:unset;
	width:auto;
}

/*
 * Alternative styling
 */
.o-list-inline-pairs--alt-styling {
	margin-bottom:40px;
}
.o-list-inline-pairs--alt-styling .o-list-inline-pairs__title,
.o-list-inline-pairs--alt-styling .o-list-inline-pairs__desc {
	float:none;
	font-size:var(--font-size-large);
}
.o-list-inline-pairs--alt-styling .o-list-inline-pairs__title {
	margin-bottom:8px;
}
.o-list-inline-pairs--alt-styling .o-list-inline-pairs__desc {
	max-width:none;
	width:auto;
	font-weight:700;
}
.o-list-inline-pairs--alt-styling .o-list-inline-pairs__items {
	display:flex;
	margin-right:-20px;
	margin-left:-20px;
	padding:16px 20px;
}
.o-list-inline-pairs--alt-styling .o-list-inline-pairs__items:nth-child(even) {
	background-color:var(--color__cue_light);
}

/*
 * @md
 */
@media screen and (min-width:992px) {
	.o-list-inline-pairs\@md .o-list-inline-pairs__title,
	.o-list-inline-pairs\@md .o-list-inline-pairs__desc {
		float:left;
		max-width:145px;
		width:calc(50% - (var(--space) / 2));
		word-wrap:break-word;
	}
	.o-list-inline-pairs\@md .o-list-inline-pairs__title {
		clear:both;
		margin-right:var(--space);
	}
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__desc {
		width:calc(100% - 210px);
	}
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__items {
		width:100%;
		margin:0;
		padding:16px 24px;
	}
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__title {
		max-width:50%;
		width:235px;
		margin-bottom:0;
	}
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__desc--link {
		font-weight:normal;
	}
}

/*
 * @sm-only
 */
@media screen and (max-width:991px) {
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__items {
		flex-wrap:wrap;
		padding:16px 24px 8px;
	}
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__title {
		flex:1 0 auto;
		max-width:unset;
		width:auto;
	}
	.o-list-inline-pairs--alt-styling .o-list-inline-pairs__desc {
		margin-bottom:8px;
		font-weight:700;
	}
}