/* =============================================================================
 * Margins
============================================================================= */
.t-margin-top {
	margin-top:var(--space) !important;
}
.t-margin-right {
	margin-right:var(--space) !important;
}
.t-margin-bottom {
	margin-bottom:var(--space) !important;
}
.t-margin-left {
	margin-left:var(--space) !important;
}

@media (min-width:768px) {
	.t-margin-top\@md {
		margin-top:var(--space) !important;
	}
	.t-margin-right\@md {
		margin-right:var(--space) !important;
	}
	.t-margin-bottom\@md {
		margin-bottom:var(--space) !important;
	}
	.t-margin-left\@md {
		margin-left:var(--space) !important;
	}
}

/* Set tighter margin
---------------------------------------------------------------------------- */
.t-tight-margin-top {
	margin-top:calc(var(--space) / 2) !important;
}
.t-tight-margin-right {
	margin-right:calc(var(--space) / 2) !important;
}
.t-tight-margin-bottom {
	margin-bottom:calc(var(--space) / 2) !important;
}
.t-tight-margin-left {
	margin-left:calc(var(--space) / 2) !important;
}

@media (min-width:768px) {
	.t-tight-margin-top\@md {
		margin-top:calc(var(--space) / 2) !important;
	}
	.t-tight-margin-right\@md {
		margin-right:calc(var(--space) / 2) !important;
	}
	.t-tight-margin-bottom\@md {
		margin-bottom:calc(var(--space) / 2) !important;
	}
	.t-tight-margin-left\@md {
		margin-left:calc(var(--space) / 2) !important;
	}
}

/* Set larger margin
---------------------------------------------------------------------------- */
.t-large-margin-top {
	margin-top:calc(var(--space) * 2) !important;
}
.t-large-margin-right {
	margin-right:calc(var(--space) * 2) !important;
}
.t-large-margin-bottom {
	margin-bottom:calc(var(--space) * 2) !important;
}
.t-large-margin-left {
	margin-left:calc(var(--space) * 2) !important;
}

@media (min-width:768px) {
	.t-large-margin-top\@md {
		margin-top:calc(var(--space) * 2) !important;
	}
	.t-large-margin-right\@md {
		margin-right:calc(var(--space) * 2) !important;
	}
	.t-large-margin-bottom\@md {
		margin-bottom:calc(var(--space) * 2) !important;
	}
	.t-large-margin-left\@md {
		margin-left:calc(var(--space) * 2) !important;
	}
}

/* No margin
---------------------------------------------------------------------------- */
.t-no-margin {
	margin:0 !important;
}
.t-no-margin-top {
	margin-top:0 !important;
}
.t-no-margin-right {
	margin-right:0 !important;
}
.t-no-margin-bottom {
	margin-bottom:0 !important;
}
.t-no-margin-left {
	margin-left:0 !important;
}

/* Set margins at @sm-only
---------------------------------------------------------------------------- */
@media screen and (max-width:767px) {
	.t-margin-top\@sm-only {
		margin-top:var(--space) !important;
	}
	.t-margin-right\@sm-only {
		margin-right:var(--space) !important;
	}
	.t-margin-bottom\@sm-only {
		margin-bottom:var(--space) !important;
	}
	.t-margin-left\@sm-only {
		margin-left:var(--space) !important;
	}
}