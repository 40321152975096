.c-checkout-receipt {
	width:100%;
	padding:calc(var(--space));
	background-color:var(--color__primary-alt_light);
}
.c-checkout-receipt_list {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	gap:32px;
}
.c-checkout-receipt_heading {
	margin-bottom:6px;
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}

/* checkout table */
.c-checkout-receipt_table {
	margin-top:calc(var(--space));
}
.c-checkout-receipt_table td:empty {
	display:none;
}
.c-checkout-receipt_table tr {
	border-bottom:1px solid #dbdbdb;
}
.c-checkout-receipt_table tbody tr:last-child,
.c-checkout-receipt_table tfoot tr {
	border-bottom:none;
}
.c-checkout-receipt_table th {
	padding:0 40px 16px;
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.c-checkout-receipt_table td {
	padding:16px 40px;
}
.c-checkout-receipt_table tfoot {
	background-color:#f0e9f2;
}
.c-checkout-receipt_table tfoot td {
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}

@media screen and (min-width:769px) {
	.c-checkout-receipt {
		padding:calc(var(--space) * 2);
	}
	.c-checkout-receipt_table {
		margin-top:calc(var(--space) * 2);
	}
	.c-checkout-receipt_table td:empty {
		display:table-cell;
	}
	.c-checkout-receipt_table tfoot td {
		padding:16px 40px;
	}
	.c-checkout-receipt_list {
		justify-content:flex-start;
		gap:48px;
	}
}