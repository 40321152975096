/* =============================================================================
 * Width limiter
 *
 * Limits width and centers container on page.
============================================================================= */
.o-width-limiter {
	max-width:1220px;
	width:100%;
	margin-right:auto;
	margin-left:auto;
	padding-right:var(--space);
	padding-left:var(--space);
}
.o-width-limiter--xs {
	max-width:920px;
	width:100%;
	margin-right:auto;
	margin-left:auto;
	padding-right:var(--space);
	padding-left:var(--space);
}