/* =============================================================================
 * Object fit
 *
 * Rules applied via JS in browsers that do not support object-fit.
============================================================================= */
.o-object-fit--fallback {
	background-position:center;
	background-repeat:no-repeat;
}
.o-object-fit--cover {
	background-size:cover;
}
.o-object-fit--contain {
	background-size:contain;
}
.o-object-fit--top {
	background-position:center top;
}
.o-object-fit--bottom {
	background-position:center bottom;
}
.o-object-fit__image[class] {
	width:100%;
	height:100%;
}
.o-object-fit__image--cover {
	object-fit:cover;
}
.o-object-fit__image--contain {
	object-fit:contain;
}
.o-object-fit__image--top {
	object-position:top;
}
.o-object-fit__image--bottom {
	object-position:bottom;
}
.o-object-fit--fallback .o-object-fit__image {
	display:none;
}