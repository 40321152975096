/* =============================================================================
 * Skip link
============================================================================= */
.c-skip {
	position:absolute;
	top:0;
	left:-9999px;
	z-index:10000;
	width:100%;
	margin:0;
}
.c-skip__link:focus,
.c-skip__link:active {
	display:block;
	position:absolute;
	top:0;
	left:9999px;
	width:100%;
	padding:var(--space) 0;
	outline:none;
	background:var(--color__base-black);
	color:#fff;
	text-align:center;
}