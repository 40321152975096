.c-blob {
	display:flex;
	position:absolute;
	justify-content:center;
	color:#333;
}
.c-blob--top {
	top:20px;
}
.c-blob--bottom {
	bottom:20px;
}
.c-blob--left {
	left:20px;
}
.c-blob--right {
	right:20px;
}
.c-blob[style*="top"] {
	transform:translateY(-50%);
}
.c-blob[style*="left"] {
	transform:translateX(-50%);
}
.c-blob[style*="left"][style*="top"] {
	transform:translate(-50%, -50%);
}
.c-teaser__content + .c-blob--bottom {
	position:static;
	order:-1;
	margin:0 20px 20px;
}
.c-teaser__content + .c-blob--right {
	align-self:flex-end;
}
.c-blob__inner {
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	width:100%;
	text-align:center;
	text-wrap:balance;
	gap:5px;
}
.c-blob__inner p {
	font-size:14px;
	line-height:16px;
}
.c-blob__heading {
	margin:0 !important;
	font-weight:400 !important;
	font-size:24px !important;
	font-family:"verveine", sans-serif;
	line-height:24px !important;
	text-transform:uppercase;
	letter-spacing:1px;
}
.c-blob__heading:only-child {
	font-size:32px !important;
	line-height:32px !important;
}
.c-blob--small {
	width:158px;
	height:148px;
	padding:25px 15px 15px;
	background:url(/ui-project/images/blob-small.svg) no-repeat center center;
	background-size:100% 100%;
}
.c-blob--large {
	width:201px;
	height:153px;
	padding:20px;
	background:url(/ui-project/images/blob-large.svg) no-repeat center center;
	background-size:contain;
}
.c-blob--original {
	width:144px;
	height:139px;
	padding:14px;
	background:url(/ui-project/images/blob.svg) no-repeat center center;
	background-size:contain;
}
.c-blob--large .c-blob__inner,
.c-blob--original .c-blob__inner {
	width:100%;
}
.c-blob--small.c-blob--bg-dark {
	background-image:url(/ui-project/images/blob-small--dark.svg);
	color:var(--color__ut_grey);
}
.c-blob--large.c-blob--bg-dark {
	background-image:url(/ui-project/images/blob-large--dark.svg);
	color:var(--color__ut_grey);
}
.c-blob--original.c-blob--bg-dark {
	background-image:url(/ui-project/images/blob--dark.svg);
	color:var(--color__ut_grey);
}

@media(min-width:769px) {
	div[data-page-type="blogpost"] .c-blob--small {
		width:199px;
		height:186px;
	}
	div[data-page-type="blogpost"] .c-blob--large {
		width:260px;
		height:198px;
		padding:20px;
	}
	div[data-page-type="blogpost"] .c-blob--original {
		width:183px;
		height:173px;
	}
	div[data-page-type="blogpost"] .c-blob__heading {
		list-style:32px !important;
		font-size:32px !important;
	}
	div[data-page-type="blogpost"] .c-blob__inner {
		gap:8px;
	}
	div[data-page-type="blogpost"] .c-blob__inner p {
		font-size:16px;
		line-height:18px;
	}
}

@media(max-width:768px) {
	.c-blob--top {
		top:15px;
	}
	.c-blob--bottom {
		bottom:15px;
	}
	.c-blob--left {
		left:15px;
	}
	.c-blob--right {
		right:15px;
	}
	.c-blob__inner {
		gap:calc(0.2px + 0.5vw);
	}
	.c-blob--original .c-blob__inner {
		width:100%;
	}
	.c-blob__heading {
		font-size:clamp(18px, 3vw, 24px) !important;
		line-height:clamp(18px, 3vw, 24px) !important;
	}
	.c-blob__heading:only-child {
		font-size:clamp(20px, 4vw, 32px) !important;
		line-height:clamp(20px, 4vw, 32px) !important;
	}
	.c-blob__inner p {
		font-size:clamp(12px, 2vw, 14px);
		line-height:clamp(12px, 2.5vw, 16px);
	}
	.c-blob--small {
		width:clamp(120px, 21vw, 158px);
		height:clamp(112px, calc(112 / 120 * 21vw), 148px);
	}
	.c-blob--large {
		width:clamp(126px, 28vw, 201px);
		height:clamp(90px, calc(90 / 126 * 28vw), 153px);
	}
	.c-blob--original {
		width:clamp(105px, 19vw, 144px);
		height:clamp(101px, calc(101 / 105 * 19vw), 139px);
	}
}