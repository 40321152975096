/* =============================================================================
 * Inspo hero
============================================================================= */
.c-inspo {
	position:relative;
	margin-bottom:calc(var(--space) * 2);
	background:var(--color__ut_darkgreen-20);
}
.c-inspo--green-20 {
	background:var(--color__ut_green-20);
}
.c-inspo__inner {
	display:flex;
	max-width:1220px;
	width:100%;
	margin-right:auto;
	margin-left:auto;
	padding:110px var(--space);
}
.c-inspo__heading {
	margin-bottom:calc(var(--space) / 2);
	font-weight:700;
	font-size:var(--font-size-heading);
	line-height:var(--font-line-height-heading);
}
.c-inspo__content {
	display:inline-flex;
	flex-direction:column;
	justify-content:center;
	min-height:200px;
	width:50%;
	padding-right:var(--space);
}
.c-inspo__content p {
	margin-bottom:var(--space);
}
.c-inspo__content a {
	max-width:fit-content;
}
.c-inspo__image-wrapper {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	width:50%;
	clip-path:ellipse(100% 160% at 100% 0);
}
.c-inspo .c-breadcrumbs {
	position:absolute;
	top:0;
}

@media (max-width:1400px) {
	.c-inspo__image-wrapper {
		clip-path:ellipse(100% 150% at 100% 0);
	}
}

@media (max-width:768px) {
	.c-inspo__inner {
		flex-direction:column;
		padding:0 0 76px;
	}
	.c-inspo__heading {
		font-size:var(--font-size-heading2);
		line-height:var(--font-line-height-heading2);
	}
	.c-inspo__content {
		width:100%;
		padding:var(--space);
	}
	.c-inspo__image-wrapper {
		position:relative;
		width:100%;
		clip-path:circle(74% at 70% 10px);
	}
	.c-inspo .c-breadcrumbs {
		display:none;
	}
}

@media (max-width:767px) {
	.c-inspo__content {
		display:block;
		min-height:auto;
	}
	.c-inspo__content .c-button {
		width:100%;
	}
}