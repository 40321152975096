/* =============================================================================
 * Height limiter
 *
 * Limits height and centers image in c-section block.
============================================================================= */
.o-height-limiter {
	margin:auto 0;
}
.o-height-limiter img {
	max-height:400px;
	width:100%;
	height:100%;
	object-fit:cover;
}

@media (min-width:768px) {
	.o-height-limiter {
		height:33vw;
	}
}

@media (min-width:1200px) {
	.o-height-limiter {
		height:400px;
	}
}