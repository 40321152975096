/* =============================================================================
 * Excerpt teaser
 *
 * Note: Some selectors are overly specific to override the s-text scope.
============================================================================= */
.c-excerpt-teaser {
	display:flex;
	position:relative;
	flex-direction:column;
	margin-bottom:var(--space);
	background:var(--color__ut_darkgreen);
	color:var(--color__base-white);
	font-size:var(--font-size-large);
}
.c-excerpt-teaser--bg-white {
	background:var(--color__base-white);
	color:var(--color__base-black);
}
.c-excerpt-teaser--bg-red {
	background:var(--color__ut_red);
}
.c-excerpt-teaser--bg-darkgreen {
	background:var(--color__ut_darkgreen);
}
.c-excerpt-teaser--bg-darkgrey {
	background:var(--color__ut_darkgrey);
}
.c-excerpt-teaser--bg-green {
	background:var(--color__ut_green);
}
.c-excerpt-teaser--bg-black {
	background:var(--color__base-black);
}
.c-excerpt-teaser--bg-yellow-20 {
	background:var(--color__ut_yellow-20);
	color:var(--color__base-black);
}
.c-excerpt-teaser--bg-darkgreen-20 {
	background:var(--color__ut_darkgreen-20);
	color:var(--color__base-black);
}
.c-excerpt-teaser--bg-green-20 {
	background:var(--color__ut_green-20);
	color:var(--color__base-black);
}
.c-excerpt-teaser__heading a::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background:transparent;
	content:"";
}
.c-excerpt-teaser__image {
	flex:0 0 auto;
}
.c-excerpt-teaser__image img {
	width:100%;
	height:auto;
}
.c-excerpt-teaser__content {
	display:flex;
	flex-direction:column;
	flex:1 0 auto;
	padding:var(--space);
}
.c-excerpt-teaser__content--remove-pading {
	padding-right:0;
	padding-bottom:0;
	padding-left:0;
}
.c-excerpt-teaser__content > * {
	margin-bottom:calc(var(--space) / 2);
}
.c-excerpt-teaser__date {
	font-weight:400;
	font-size:var(--font-size-default);
};
.c-excerpt-teaser--bg-white .c-excerpt-teaser__date,
.c-excerpt-teaser--bg-darkgreen-20 .c-excerpt-teaser__date,
.c-excerpt-teaser--bg-green-20 .c-excerpt-teaser__date,
.c-excerpt-teaser--bg-yellow-20 .c-excerpt-teaser__date {
	color:#333;
}
.c-excerpt-teaser__heading {
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.c-excerpt-teaser:not(.c-excerpt-teaser--bg-white):not(.c-excerpt-teaser--bg-darkgreen-20):not(.c-excerpt-teaser--bg-green-20):not(.c-excerpt-teaser--bg-yellow-20) a {
	color:#fff;
}
.c-excerpt-teaser:not(.c-excerpt-teaser--bg-white):not(.c-excerpt-teaser--bg-darkgreen-20):not(.c-excerpt-teaser--bg-green-20):not(.c-excerpt-teaser--bg-yellow-20) a:hover {
	color:var(--color__ut_red-30);
}
.c-excerpt-teaser:not(.c-excerpt-teaser--bg-white):not(.c-excerpt-teaser--bg-darkgreen-20):not(.c-excerpt-teaser--bg-green-20):not(.c-excerpt-teaser--bg-yellow-20) a:focus {
	border-color:var(--color__ut_yellow-50);
	color:var(--color__ut_yellow-50);
}
.c-excerpt-teaser__link {
	z-index:1;
	align-self:flex-start;
	margin-top:auto;
	text-underline-offset:0.2em;
}

@media print {
	.c-excerpt-teaser {
		background:transparent;
		color:#000;
	}
	.c-excerpt-teaser__image,
	.c-excerpt-teaser__link {
		display:none;
	}
	.c-excerpt-teaser__content {
		padding:0;
	}
	.c-excerpt-teaser a {
		color:inherit;
	}
}