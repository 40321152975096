.c-buffer-box {
	padding:var(--space);
}
.c-buffer-box__container {
	margin:auto 0;
}
.c-buffer-box__container .s-text {
	margin-bottom:10px;
}
.c-buffer-box__container .s-text p {
	width:90%;
}
.c-buffer-box__container .s-text p:first-of-type {
	margin-top:20px;
}
.c-buffer-box__container .c-buffer-box-expand__text p:first-of-type {
	margin-top:0;
}
.c-buffer-box__container .c-button {
	display:inline-block;
}

/* Set padding for a certain direction ONLY.
---------------------------------------------------------------------------- */
.c-buffer-box--only-top {
	padding:var(--space) 0 0;
}
.c-buffer-box--only-right {
	padding:0 var(--space) 0 0;
}
.c-buffer-box--only-bottom {
	padding:0 0 var(--space);
}
.c-buffer-box--only-left {
	padding:0 0 0 var(--space);
}

/* Set extra padding
---------------------------------------------------------------------------- */
.c-buffer-box--extra-padding {
	padding:calc(var(--space) * 2);
}
.c-buffer-box--extra-top {
	padding-top:calc(var(--space) * 2);
}
.c-buffer-box--extra-right {
	padding-right:calc(var(--space) * 2);
}
.c-buffer-box--extra-bottom {
	padding-bottom:calc(var(--space) * 2);
}
.c-buffer-box--extra-left {
	padding-left:calc(var(--space) * 2);
}

/* Set tighter padding
---------------------------------------------------------------------------- */
.c-buffer-box--tight-padding {
	padding:calc(var(--space) / 2);
}
.c-buffer-box--tight-top[class] {
	padding-top:calc(var(--space) / 2);
}
.c-buffer-box--tight-right[class] {
	padding-right:calc(var(--space) / 2);
}
.c-buffer-box--tight-bottom[class] {
	padding-bottom:calc(var(--space) / 2);
}
.c-buffer-box--tight-left[class] {
	padding-left:calc(var(--space) / 2);
}

/* Disable padding for certain directions
---------------------------------------------------------------------------- */
.c-buffer-box--no-top {
	padding-top:0;
}
.c-buffer-box--no-right {
	padding-right:0;
}
.c-buffer-box--no-bottom {
	padding-bottom:0;
}
.c-buffer-box--no-left {
	padding-left:0;
}
.c-buffer-box--no-top-bottom {
	padding-top:0;
	padding-bottom:0;
}
.c-buffer-box--no-left-right {
	padding-right:0;
	padding-left:0;
}
.c-buffer-box--no-padding {
	padding:0;
}

/* Set border for only a certain direction.
---------------------------------------------------------------------------- */
.c-buffer-box--border-top {
	border-top:1px solid var(--color__primary);
}
.c-buffer-box--border-bottom {
	border-bottom:1px solid var(--color__primary);
}
.c-buffer-box--border-left {
	border-left:1px solid var(--color__primary);
}
.c-buffer-box--border-right {
	border-right:1px solid var(--color__primary);
}

/* Set all borders.
---------------------------------------------------------------------------- */
.c-buffer-box--border-all {
	border:1px solid var(--color__primary);
}

/* Set border colors
---------------------------------------------------------------------------- */
.c-buffer-box--border-grey {
	border-color:var(--color__grey-2);
}

/* Make borders thicker.
---------------------------------------------------------------------------- */
.c-buffer-box--border-thicker {
	border-width:2px;
}

/* Add background
---------------------------------------------------------------------------- */
.c-buffer-box--bg-none {
	background:transparent;
}
.c-buffer-box--bg-red {
	background:var(--color__ut_red);
}
.c-buffer-box--bg-darkgreen {
	background:var(--color__ut_darkgreen);
}
.c-buffer-box--bg-darkgrey {
	background:var(--color__ut_darkgrey);
}
.c-buffer-box--bg-green {
	background:var(--color__ut_green);
}
.c-buffer-box--bg-black {
	background:var(--color__base-black);
}
.c-buffer-box--bg-yellow-20 {
	background:var(--color__ut_yellow-20);
}
.c-buffer-box--bg-darkgreen-20,
.c-buffer-box--bg-grey {
	background:var(--color__ut_darkgreen-20);
}
.c-buffer-box--bg-green-20 {
	background:var(--color__ut_green-20);
}

/* .c-buffer-box--bg-grey {
	background-color:var(--color__grey-6);
}
.c-buffer-box--bg-grey-light {
	background-color:var(--color__primary-alt_light);
}
.c-buffer-box--bg-grey-dark {
	background-color:var(--color__grey-7);
}
.c-buffer-box--bg-yellow {
	background-color:var(--color__yellow);
}
.c-buffer-box--bg-none {
	background-color:transparent;
}
.c-buffer-box--bg-purple-dark {
	background-color:var(--color__primary-dark);
}
.c-buffer-box--bg-purple-light {
	background-color:var(--color__primary-light);
} */

/* buffer box expandable */
.no-js .c-buffer-box-expand__button {
	display:none;
}
.c-buffer-box-expand__text {
	overflow:hidden;
	transition:all 300ms ease-in-out;;
}
.c-buffer-box-expand__button {
	display:inline-flex;
	align-items:center;
	padding:5px 5px 5px 0;
	color:var(--color__link-color);
	font-weight:bold;
	font-size:18px;
	text-decoration:underline;
	cursor:pointer;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-buffer-box-expand__button:hover {
	color:var(--color__hover-button);
}
.c-buffer-box-expand__button:hover .c-icon,
.c-buffer-box-expand__button:focus .c-icon {
	background-color:var(--color__hover-button);
}
.c-buffer-box-expand__button:focus {
	outline:none;
	color:var(--color__hover-button);
	text-decoration-thickness:0.5px;
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button,
div[class*="--bg-red"] .c-buffer-box-expand__button,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button,
div[class*="--bg-black"] .c-buffer-box-expand__button {
	color:var(--color__base-white);
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button .c-icon,
div[class*="--bg-red"] .c-buffer-box-expand__button .c-icon,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button .c-icon,
div[class*="--bg-black"] .c-buffer-box-expand__button .c-icon {
	background-color:var(--color__base-white);
	color:var(--color__base-black);
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button:hover,
div[class*="--bg-red"] .c-buffer-box-expand__button:hover,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button:hover
div[class*="--bg-black"] .c-buffer-box-expand__button:hover {
	color:var(--color__ut_red-30);
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button:hover .c-icon,
div[class*="--bg-red"] .c-buffer-box-expand__button:hover .c-icon,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button:hover .c-icon,
div[class*="--bg-black"] .c-buffer-box-expand__button:hover .c-icon {
	background-color:var(--color__ut_red-30);
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button:focus,
div[class*="--bg-red"] .c-buffer-box-expand__button:focus,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button:focus,
div[class*="--bg-black"] .c-buffer-box-expand__button:focus {
	color:var(--color__ut_yellow-50);
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button:focus span:first-child,
div[class*="--bg-red"] .c-buffer-box-expand__button:focus span:first-child,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button:focus span:first-child,
div[class*="--bg-black"] .c-buffer-box-expand__button:focus span:first-child {
	border-color:var(--color__base-white);
}
div[class*="green"]:not([class*="-20"]) .c-buffer-box-expand__button:focus .c-icon,
div[class*="--bg-red"] .c-buffer-box-expand__button:focus .c-icon,
div[class*="--bg-darkgrey"] .c-buffer-box-expand__button:focus .c-icon,
div[class*="--bg-black"] .c-buffer-box-expand__button:focus .c-icon,
div[class*="--bg-green"] .c-buffer-box-expand__button:focus .c-icon {
	background-color:var(--color__ut_yellow-50);
}
.c-buffer-box-expand__button .c-icon {
	margin-left:calc(var(--space) / 2);
	border-radius:50%;
	background-color:var(--color__link-color);
	color:var(--color__base-white);
	transition:transform 0.2s ease;
}
.c-buffer-box-expand.is-expanded .c-buffer-box-expand__button .c-icon {
	transform:rotate(180deg);
}
.c-section:nth-last-of-type(2) .c-buffer-box__container .s-text p {
	width:90%;
}

@media screen and (min-width:768px) {
	.c-buffer-box-expand {
		margin:0;
		padding:0;
	}
	.c-buffer-box__container .s-text {
		margin-bottom:0;
	}
	.c-buffer-box__container .s-text p {
		width:80%;
		margin-bottom:20px;
	}
}