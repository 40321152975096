/* =============================================================================
 * Cart
 *
 * 1. Focus may be moved to list items with JavaScript after a product has been
 *    removed. This probably does not need to be visible.
============================================================================= */
.c-cart {
	margin:calc(var(--space) * 2) 0;
}
.c-cart__table {
	margin-bottom:var(--space);
}
.c-cart__table thead th {
	padding:0 var(--space) calc(var(--space) / 2) 0;
	font-size:18px;
	line-height:1;
}


/* Rows (items)
----------------------------------------------------------------------------- */
.c-cart__item {
	border-top:1px solid var(--color__grey-2);
	transition:opacity 0.5s ease-in-out;
}
.c-cart__item--remove {
	opacity:0;
}
.c-cart__item:focus {
	outline:none; /* 1 */
}
.c-cart__item:last-child {
	border-bottom:1px solid var(--color__grey-2);
}
.c-cart__item td {
	padding:var(--space) var(--space) var(--space) 0;
	vertical-align:middle;
}
.c-cart__width-limiter {
	display:inline-block;
	max-width:140px;
	width:100%;
}

@media (min-width:1200px) {
	.c-cart__width-limiter {
		max-width:100%;
		width:auto;
	}
}

/*
 * Media
 */
.c-cart__item__media {
	display:flex;
	align-items:center;
	max-width:400px;
	width:100%;
}
.c-cart__item__media img {
	align-self:flex-start;
	flex:none;
	width:50px;
	margin-right:calc(var(--space) / 2);
}
.c-cart__item__media a {
	flex:0 1 auto;
}

/*
 * License
 */
.c-cart__item-license {
	text-align:center;
}

/*
 * Price
 */
.c-cart__item-price {
	text-align:right;
}

/*
 * Quantity
 */
.c-cart__item-quantity {
	text-align:center;
}

/*
 * Subtotal
 */
.c-cart__item-subtotal {
	text-align:right;
}

/*
 * Tools
 */
.c-cart__item-tools.c-cart__item-tools {
	padding-right:0;
}


/* Footer
----------------------------------------------------------------------------- */
.c-cart__total {
	padding:var(--space) 0 0;
	line-height:1;
}
.c-cart__total dl {
	display:grid;
	float:right;
	align-items:baseline;
	vertical-align:text-bottom;
	gap:15px 20px;
	grid-template-columns:auto auto;
	grid-gap:15px 20px;
	justify-items:end;
}
.c-cart__total-vat-included {
	font-size:22px;
}


/* Force table to not be like tables anymore
----------------------------------------------------------------------------- */
@media only screen and (max-width:767px) {
	.c-cart__table,
	.c-cart__table tbody,
	.c-cart__table td,
	.c-cart__table tr,
	.c-cart__table tfoot {
		display:block;
	}
	.c-cart__table td {
		padding:calc(var(--space) / 2) 0 0;
	}
	.c-cart__table .c-cart__item-subtotal {
		padding-top:var(--space);
	}
	.c-cart__table tfoot td {
		padding:0;
	}
	.c-cart__table tfoot tr {
		overflow:hidden;
		padding:0;
		border:none;
	}
	.c-cart__item {
		position:relative;
		margin-bottom:var(--space);
		padding:var(--space);
		border:1px solid var(--color__grey-2);
	}
	.c-cart__item-price,
	.c-cart__item-subtotal,
	.c-cart__item-license,
	.c-cart__item-quantity {
		text-align:left;
	}
	.c-cart__item-tools[class] {
		padding:0;
	}
	.c-cart__remove-item {
		position:absolute;
		top:5px;
		right:10px;
	}
	.c-cart__item__media {
		max-width:none;
	}

	/* Hide table headers */
	.c-cart__table thead {
		display:none;
	}

	/* Behave like a table header */
	.c-cart__table [data-th]::before {
		display:inline-block;
		margin-right:5px;
		font-size:16px;
		line-height:24px;
		content:attr(data-th);
	}
	.c-cart__table:not(.c-cart__table--read-only) .c-cart__item-license[data-th]::before,
	.c-cart__table:not(.c-cart__table--read-only) .c-cart__item-quantity[data-th]::before {
		display:block;
	}

	/* Visually hide c-cart__item title */
	.c-cart__table .c-cart__item-title::before {
		position:absolute;
		width:1px;
		height:1px;
		overflow:hidden;
		clip:rect(1px, 1px, 1px, 1px);
		margin:0;
	}
}