/* =============================================================================
 * List of key/value pairs
 *
============================================================================= */
.c-key-value-list {
	overflow:auto;
}
.c-key-value-list__key {
	float:left;
	clear:left;
	min-width:150px;
	width:calc(50% - 0.5em);
	margin-right:1em;
	margin-bottom:0.5em;
	font-weight:bold;
}
.c-key-value-list__key::after {
	display:inline;
	content:": ";
}
.c-key-value-list__value {
	float:right;
	min-width:150px;
	width:calc(50% - 0.5em);
}