/* Filter
================================================================================ */
.c-filter {
	border:1px solid var(--color__grey-1);
}
.c-filter-container .c-search-filter__choice-items {
	display:none;
}
.c-filter__header {
	display:none;
}
.c-filter__section--collapsed .c-filter__items {
	display:none;
}
.c-filter__item:not(:last-child) {
	margin-bottom:15px;
}
.js .c-filter__item--hidden {
	display:none;
}
.c-filter__more-button {
	color:var(--color__link-color);
}
.c-filter__more-button-text {
	margin-left:calc(var(--space) / 2);
	vertical-align:middle;
}
.c-filter__description {
	width:100%;
	background:var(--color__grey-2);
	font-weight:700;
	font-size:14px;
	line-height:1;
	text-transform:uppercase;
}
.c-filter__section--collapsed:not(:last-of-type) .c-filter__description {
	border-bottom:1px solid #fff;
}
.c-filter__description-content {
	display:table;
	width:100%;
	padding:calc(var(--space) / 2) calc(var(--space) - (var(--space) / 4)) calc(var(--space) / 2) var(--space);
	background:var(--color__grey-2);
}
.c-filter__section--collapsed .c-filter__description-content {
	background:var(--color__grey-2);
}
.c-filter__button {
	width:100%;
	text-transform:inherit;
}
.c-filter__collapse-icon {
	color:var(--color__primary-alt);
	transition:transform 0.2s;
}
.c-filter__text,
.c-filter__collapse-icon[class] {
	display:table-cell;
	vertical-align:middle;
}
.no-js .c-filter__collapse-icon {
	display:none;
}
.c-filter__button--collapsed .c-filter__collapse-icon {
	transform:rotate(180deg);
}
.c-filter
.c-filter__header,
.c-filter__items,
.c-filter__footer {
	padding:var(--space);
}
.c-filter__button {
	white-space:normal;
}

@media only screen and (max-width:767px) {
	.c-filter {
		margin-bottom:var(--space);
		border:none;
	}
	.c-filter__header {
		display:block;
	}
	.c-filter__heading {
		font-weight:700;
		text-align:center;
		text-transform:uppercase;
	}
	.c-filter__section {
		margin-bottom:var(--space);
	}
	.c-filter__items {
		padding:calc(var(--space) / 2);
		padding-right:0;
		font-size:16px;
	}
	.c-filter__description,
	.c-filter__description-content,
	.c-filter__section--collapsed .c-filter__description-content {
		background:var(--color__grey-1);
	}
}

@media only screen and (max-width:450px) {
	.c-filter__container .c-search-filter__choice-items {
		display:flex;
	}
	.c-filter__section {
		border:1px solid var(--color__grey-5);
	}
	.c-filter__section--collapsed {
		border:none;
	}
}