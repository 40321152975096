.c-hero {
	position:relative;
	overflow:hidden;
}
.c-hero__content-wrapper {
	position:relative;
	max-width:1200px;
	width:100%;
	margin:calc(var(--space) * 3) auto 40px;
	padding:80px var(--space) 0;
}
.c-hero__width-limiter {
	max-width:1220px;
	margin:0 auto;
	padding-right:var(--space);
	padding-left:var(--space);
}
.c-hero__content {
	position:relative;
	z-index:20;
	max-width:580px;
	width:100%;
	padding:calc(var(--space) * 1.5);
	background:var(--color__secondary-alt_95);
}
.c-hero--bg-none .c-hero__content {
	background:transparent;
}
.c-hero--bg-pink .c-hero__content {
	background:var(--color__secondary_95);
}
.c-hero--bg-black .c-hero__content {
	background:var(--color__base-black);
}
.c-hero--bg-grey .c-hero__content {
	background:var(--color__grey-6);
}
.c-hero--bg-grey-dark .c-hero__content {
	background:var(--color__grey-7);
}
.c-hero--bg-grey-light .c-hero__content {
	background:var(--color__primary-alt_light);
}

/* .c-hero--bg-purple-dark .c-hero__content {
	background:var(--color__primary-dark);
}
.c-hero--bg-purple-light .c-hero__content {
	background:var(--color__primary-light);
} */
.c-hero--bg-yellow .c-hero__content {
	background:var(--color__yellow);
}
.c-hero__heading {
	margin-bottom:calc(var(--space) / 2);
	font-weight:700;
	font-size:var(--font-size-heading);
	line-height:var(--font-line-height-heading);
	text-wrap:balance;
	overflow-wrap:break-word;
}
.c-hero__text {
	margin-bottom:var(--space);
}
.c-hero__heading,
.c-hero__text,
.c-hero__link {
	color:var(--color__base-white);
	text-underline-offset:0.2em;
}
.c-hero__link {
	display:inline-block;
	font-weight:600;
}
.c-hero__link span {
	font-weight:600;
}
.c-hero__link:hover {
	color:var(--color__ut_red-30);
}
.c-hero__link:focus {
	border-color:var(--color__base-white);
	color:var(--color__ut_yellow-50);
}
.c-hero--bg-yellow .c-hero__heading,
.c-hero--bg-none .c-hero__heading,
.c-hero--bg-grey-light .c-hero__heading,
.c-hero--bg-yellow .c-hero__text,
.c-hero--bg-grey-dark .c-hero__text,
.c-hero--bg-none .c-hero__text,
.c-hero--bg-grey-light .c-hero__text,
.c-hero--bg-yellow .c-hero__link,
.c-hero--bg-grey-dark .c-hero__link,
.c-hero--bg-none .c-hero__link,
.c-hero--bg-grey-light .c-hero__link,
.c-hero--bg-yellow .c-hero__link:hover,
.c-hero--bg-grey-dark .c-hero__link:hover,
.c-hero--bg-none .c-hero__link:hover,
.c-hero--bg-grey-light .c-hero__link:hover,
.c-hero--bg-yellow .c-hero__link:focus,
.c-hero--bg-grey-dark .c-hero__link:focus,
.c-hero--bg-none .c-hero__link:focus,
.c-hero--bg-grey-light .c-hero__link:focus {
	color:var(--color__base-black);
}
.c-hero__image {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
}

/* New hero */
.c-hero--new .c-hero__image {
	left:-5px;
}
.c-hero--new .c-hero__content {
	max-width:1000px;
	background:transparent;
}
.c-hero--new .c-hero__content-wrapper {
	padding:calc(var(--space) * 2) var(--space);
}
.c-hero--new .c-hero__heading {
	margin-bottom:var(--space);
	font-size:66px;
	line-height:1;
}
.c-hero--new .c-hero__text {
	font-size:23px;
	line-height:normal;
}
.c-hero--new .c-hero__link {
	display:inline-block;
	font-weight:700;
}

/* Newsletter hero */
.c-hero--newsletter .c-hero__image {
	top:-5px;
}
.c-hero--newsletter .c-hero__content-wrapper {
	padding:0;
}
.c-hero--newsletter .c-hero__content {
	max-width:550px;
	padding:0 30px 30px 10px;
	background:transparent;
}
.c-hero--newsletter .c-hero__heading {
	margin-bottom:var(--space);
	font-size:52px;
	line-height:1;
}
.c-hero--newsletter .c-hero__text {
	font-weight:700;
	font-size:23px;
	line-height:normal;
}

/* Campaign hero */
.c-hero--campaign .c-hero__content-wrapper {
	max-width:1500px;
	margin:var(--space) auto var(--space);
}
.c-hero--campaign .c-hero__content {
	max-width:800px;
	padding:0;
	background:transparent;
}

/* Dashboard hero */
.c-hero--dashboard .c-hero__content {
	max-width:700px;
	background:transparent;
}
.c-hero--dashboard .c-hero__content-wrapper {
	margin:120px 40px;
	padding-top:0;
}
.c-hero--dashboard .c-hero__logo {
	margin-bottom:20px;
}
.c-hero--dashboard .c-hero__heading {
	font-size:60px;
	line-height:1.2;
}
.c-hero--dashboard .c-weatherdate__date-wrapper {
	position:relative;
}

/* Two column hero */
.c-hero--two-cols {
	background-color:var(--color__ut_darkgreen-20);
}
.c-hero--two-cols-compact {
	margin-bottom:calc(var(--space) * -8);
}
.c-hero--two-cols .c-hero__width-limiter {
	display:flex;
	padding:calc(var(--space) * 3) var(--space);
}
.c-hero--two-cols-compact .c-hero__width-limiter {
	padding:calc(var(--space) * 2) var(--space) calc(var(--space) * 8);
}
.c-hero--two-cols .c-hero__content {
	max-width:100%;
	padding:0;
	background:transparent;
}
.c-hero--two-cols .c-hero__content-wrapper {
	display:flex;
	align-items:center;
	flex:3 1 0;
	margin:0 calc(var(--space) * 3) 0 0;
	padding:0;
}
.c-hero--two-cols .c-hero__heading {
	margin-bottom:var(--space);
	color:var(--color__primary-dark);
	font-size:66px;
	line-height:1;
}
.c-hero--two-cols-compact[class] .c-hero__heading {
	font-size:var(--font-size-heading);
	line-height:var(--font-line-height-heading);
}
.c-hero--two-cols .c-hero__text {
	color:var(--color__primary-dark);
	font-size:23px;
	line-height:normal;
}
.c-hero--two-cols .c-hero__link {
	display:inline-block;
	font-weight:700;
}
.c-hero--two-cols .c-hero__image {
	position:static;
	align-items:center;
	flex:2 1 0;
}
.c-hero--two-cols .c-hero__image img {
	width:95%;
}
.c-hero.c-hero--two-cols.c-hero--two-cols-compact {
	margin-top:calc(var(--space) * 3);
}

@media screen and (max-width:1024px) {
	.c-hero--two-cols .c-hero__content-wrapper,
	.c-hero--two-cols .c-hero__image {
		align-self:center;
		flex:1 1 0;
	}
	.c-hero--two-cols .c-hero__content-wrapper {
		margin:0 calc(var(--space) * 3) 0 0;
	}
}

@media screen and (max-width:1200px) {
	.c-hero.c-hero--two-cols.c-hero--two-cols-compact {
		margin-top:calc(var(--space) * 2);
	}

	/* .c-hero__raster {
		margin-bottom:calc(var(--space) * 2);
	} */
}

/* Hero with raster */
.c-hero--raster {
	margin-bottom:var(--space);
	background-color:var(--color__ut_darkgreen-20);
}
.c-hero--raster .c-hero__content-wrapper {
	display:flex;
	min-height:400px;
	margin:0 auto;
	padding:0;
}
.c-hero--raster .c-hero__content {
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:flex-start;
	max-width:42%;
	padding:20px 0 20px 20px;
	background-color:var(--color__ut_darkgreen-20);
}
.c-hero--bg-red,
.c-hero--bg-red .c-hero__content,
.c-hero--bg-red .c-hero__raster::before,
.c-hero--bg-red .c-hero__raster--right {
	background-color:var(--color__ut_red);
}
.c-hero--bg-darkgreen,
.c-hero--bg-darkgreen .c-hero__content,
.c-hero--bg-darkgreen .c-hero__raster::before,
.c-hero--bg-darkgreen .c-hero__raster--right {
	background-color:var(--color__ut_darkgreen);
}
.c-hero--bg-darkgreen-20,
.c-hero--bg-darkgreen-20 .c-hero__content,
.c-hero--bg-darkgreen-20 .c-hero__raster::before,
.c-hero--bg-darkgreen-20 .c-hero__raster--right {
	background-color:var(--color__ut_darkgreen-20);
}
.c-hero--bg-green-20,
.c-hero--bg-green-20 .c-hero__content,
.c-hero--bg-green-20 .c-hero__raster::before,
.c-hero--bg-green-20 .c-hero__raster--right {
	background-color:var(--color__ut_green-20);
}
.c-hero--bg-yellow-20,
.c-hero--bg-yellow-20 .c-hero__content,
.c-hero--bg-yellow-20 .c-hero__raster::before,
.c-hero--bg-yellow-20 .c-hero__raster--right {
	background-color:var(--color__ut_yellow-20);
}
.c-hero--raster .c-hero__heading,
.c-hero--raster .c-hero__text,
.c-hero--raster .c-hero__link {
	color:var(--color__base-black);
}
.c-hero--bg-darkgreen .c-hero__heading,
.c-hero--bg-darkgreen .c-hero__text,
.c-hero--bg-darkgreen .c-hero__link {
	color:var(--color__base-white);
}
.c-hero--bg-red .c-hero__heading,
.c-hero--bg-red .c-hero__text,
.c-hero--bg-red .c-hero__link {
	color:var(--color__base-white);
}
.c-hero--raster .c-hero__link {
	color:var(--color__link-color);
	font-weight:600;
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-hero--raster .c-hero__link:hover {
	color:var(--color__link-hover);
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-hero--raster .c-hero__link:focus {
	color:var(--color__link-active);
	text-decoration-thickness:0.5px;
}
.c-hero--bg-red .c-hero__link,
.c-hero--bg-darkgreen .c-hero__link {
	color:var(--color__base-white);
}
.c-hero--bg-red .c-hero__link:hover,
.c-hero--bg-darkgreen .c-hero__link:hover {
	color:var(--color__ut_red-30);
}
.c-hero--bg-red .c-hero__link:focus,
.c-hero--bg-darkgreen .c-hero__link:focus {
	color:var(--color__ut_yellow-50);
}
.c-hero__raster {
	position:relative;
	flex:1;
}
.c-hero__raster::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:-2px;
	background-color:var(--color__ut_darkgreen-20);
	content:"";
	mask-image:url(/ui-project/images/hero-raster.svg);
	mask-repeat:no-repeat;
}
.c-hero__raster span {
	position:absolute;
	width:40px;
	height:40px;
	background:rgba(255, 255, 255, 0.4);
	opacity:0;
	transition:opacity 0.2s;
}
.c-hero__raster span.fade-in {
	opacity:1;
}
.c-hero--raster .c-hero__image {
	left:40%;
	max-width:1600px;
}
.c-hero__raster--right {
	display:none;
	position:absolute;
	top:0;
	width:100%;
	height:100%;
	background-color:var(--color__ut_darkgreen-20);
	transform:scaleX(-1);
	mask-image:url(/ui-project/images/hero-raster.svg);
	mask-repeat:no-repeat;
}

@media screen and (min-width:2600px) {
	.c-hero__raster--right {
		display:block;
	}
	.c-hero--raster .c-hero__image {
		left:45%;
	}
}

@media screen and (max-width:767px) {
	.c-hero--raster .c-hero__content {
		max-width:100%;
		padding:20px;
	}
	.c-hero--raster .c-hero__raster {
		display:none;
	}
	.c-hero--raster .c-hero__image {
		display:none;
	}
	.c-hero__content-wrapper {
		margin:calc(var(--space) * 3) auto;
		padding-top:calc(var(--space) * 5);
	}
	.c-hero__content {
		padding:var(--space);
	}
	.c-hero__heading {
		font-size:var(--font-size-heading-mobile);
		line-height:var(--font-line-height-heading-mobile);
	}
	.c-hero--new .c-hero__content-wrapper {
		margin:calc(var(--space) * 2) auto 150px;
	}
	.c-hero--new .c-hero__content {
		padding:15px 0 0 15px;
	}
	.c-hero--new .c-hero__heading {
		margin-bottom:var(--space);
		font-size:53px;
		line-height:1.1;
	}
	.c-hero--campaign .c-hero__content-wrapper {
		margin:var(--space) auto;
		padding:var(--space) 0;
	}
	.c-hero--newsletter .c-hero__content-wrapper {
		padding:0 var(--space) calc(var(--space) * 4);
	}
	.c-hero--newsletter .c-hero__heading {
		margin-bottom:var(--space);
		font-size:32px;
		line-height:1.1;
	}
	.c-hero--new .c-hero__text {
		font-size:17px;
		line-height:2;
	}
	.c-hero--newsletter .c-hero__text {
		font-size:17px;
		line-height:1.5;
	}
	.c-hero--two-cols .c-hero__width-limiter {
		flex-direction:column;
		flex-wrap:wrap;
		padding:calc(var(--space) * 2) var(--space) calc(var(--space) * 7);
	}
	.c-hero--two-cols .c-hero__content-wrapper {
		margin:0 calc(var(--space) * 3) 0 0;
	}
	.c-hero--two-cols .c-hero__image img {
		max-width:400px;
	}
	.c-hero--two-cols a {
		width:100%;
	}
	.c-hero--two-cols .c-hero__content-wrapper {
		margin:0 0 calc(var(--space) * 2);
	}
	.c-hero--two-cols .c-hero__heading {
		font-size:52px;
	}
	.c-hero--two-cols .c-hero__text {
		font-size:16px;
	}
}

@media print {
	.c-hero__content-wrapper {
		margin:40px auto;
		padding:0;
	}
	.c-hero__content {
		background:#fff;
		color:#000;
	}
	.c-hero__heading,
	.c-hero__text,
	.c-hero__link {
		color:inherit;
	}
}