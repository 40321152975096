.t-width-full {
	width:100% !important;
}
.t-width-half {
	width:50% !important;
}

@media (max-width:767px) {
	.t-width-full\@xs {
		width:100% !important;
	}
	.t-width-half\@xs {
		width:50% !important;
	}
}