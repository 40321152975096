/* =============================================================================
 * Form
 *
 * 1. Provide space between parts when stacked, while still preserving the same
 *    margin between the inline form and whatever is above it.
 * 2. By default, we want buttons to align with fields if there's labels above
 *    them.
============================================================================= */
.c-form:not(.c-form--inline) .c-form__part {
	margin-bottom:20px;
}
.c-form__part--center {
	align-self:center;
}


/* Inline form
----------------------------------------------------------------------------- */
.c-form--inline {
	display:inline-flex;
	flex-wrap:wrap;
	align-items:flex-end; /* 2 */
	margin-top:-15px; /* 1 */
}
.c-form--inline .c-form__part {
	margin-top:15px; /* 1 */
}
.c-form--inline .c-form__part:not(:last-child) {
	margin-right:15px;
}
.c-form--inline .c-form__part--small {
	width:100px;
}
.c-form--inline .c-form__part--medium {
	width:200px;
}
.c-form--inline .c-form__part--large {
	width:300px;
}


/* Fieldset
----------------------------------------------------------------------------- */
.c-form__fieldset {
	margin-bottom:20px;
	padding:10px 20px 0;
	border:1px solid var(--color__grey-2);
}
.c-form__fieldset > :not(:first-child):not(:last-child):not(.o-grid) {
	margin-bottom:20px;
}
.c-form__alert {
	display:none;
	padding-bottom:var(--space);
	color:var(--color__primary-alt_dark);
}
.c-form--showalert .c-form__alert {
	display:block;
}
.c-form__fieldset.c-form-item--invalid {
	padding-bottom:10px;
	border:2px solid var(--color__error);
}
.c-custom-container__hovermessage {
	display:none;
	position:absolute;
}
.c-form__fieldset.max:hover .c-custom-container__hovermessage {
	display:block;
}


/* Footer
 *
 * 1. Provide space between areas when stacked, while still preserving the same
 *    margin between the footer and whatever is above it.
----------------------------------------------------------------------------- */
.c-form__footer {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:flex-end;
	margin-top:-15px; /* 1 */
}
.c-form__footer--align-center {
	justify-content:center;
}
.c-form__footer-area {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
}
.c-form__footer-area:not(:last-child) {
	margin-right:20px;
}
.c-form__footer-area-item {
	margin-top:15px; /* 1 */
}
.c-form__footer-area-item:not(:last-child) {
	margin-right:20px;
}