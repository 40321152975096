/* =============================================================================
 * An image with a caption
 ============================================================================ */
.figure {
	display:block;
	max-width:100%;
	width:-webkit-min-content;
	width:-moz-min-content;
	width:min-content;
	padding:0;
}
.figure__image {
	max-width:none;
}
.figure .figcaption {
	display:block;
	font-style:italic;
}

@media only screen and (min-width:480px), print {
	.figure--left {
		float:left;
		padding-right:calc(var(--space) * 1.5);
	}
	.figure--right {
		float:right;
		padding-left:calc(var(--space) * 1.5);
	}
}