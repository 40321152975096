/* =============================================================================
 * Teaser
 *
 * 1. Overrides a[rel~="external"]::after in elements.links.css
============================================================================= */
.c-teaser {
	position:relative;
	height:320px;
}
.c-teaser--tall {
	height:480px;
}
.c-teaser__image {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
}
.c-teaser__overlay {
	display:flex;
	position:relative;
	flex-direction:column;
	justify-content:flex-end;
	height:100%;
	overflow:hidden;
	color:var(--color__base-white);
}
.c-teaser__content {
	z-index:20;
	padding:15px;
	border-bottom:none;
	color:var(--color__base-white);
	font-weight:400;
	text-decoration:none;
}
.c-teaser__content:hover,
.c-teaser__content:focus {
	color:var(--color__base-white);
}
.c-teaser__content:hover .c-teaser__heading,
.c-teaser__content:focus .c-teaser__heading {
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-teaser__content .c-teaser__text {
	margin-top:var(--space);
	text-decoration:none;
}
.c-teaser__content .c-teaser__text:first-of-type {
	margin-top:0;
}
.c-teaser__content {
	background-color:var(--color__primary);
}
.c-teaser__content > * {
	max-width:550px;
}
.c-teaser__heading {
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.c-teaser__new-icon {
	position:absolute;
	top:-12px;
	left:10px;
	z-index:10;
}

/* Low variant on book page
----------------------------------------------------------------------------- */
.c-teaser--low {
	max-width:436px;
	height:auto;
}
.c-teaser--low .c-teaser__image {
	position:relative;
	height:189px;
}
.c-teaser--low .c-teaser__overlay {
	height:auto;
}
.c-teaser--low .c-teaser__content {
	background-color:var(--color__ut_green-20);
	color:var(--color__ut_red) !important;
}
.c-teaser--low .c-teaser__content .c-teaser__text::after {
	display:inline-block;
	width:22px;
	margin-left:3px;
	background-image:url(/ui-project/components/icon/icons/arrow-right-alt-red.svg);
	background-position:left;
	background-repeat:no-repeat;
	content:"\00a0";
	transition-duration:0.1s;
}
.c-teaser--low .c-teaser__content:hover > .c-teaser__text::after {
	background-position:right;
}
.c-teaser__imagetext {
	position:absolute;
	top:50%;
	left:50%;
	z-index:10;
	max-height:60%;
	width:80%;
	overflow:hidden;
	transform:translate(-50%, -50%);
}
.c-teaser__imagetext p {
	color:#fff;
	font-weight:400;
	font-style:normal;
	font-size:32px;
	font-family:"verveine", sans-serif;
	line-height:1.1;
	text-align:center;
}
.c-teaser__imagetext--black p {
	color:#000;
	text-shadow:none;
}
.c-teaser--low .c-teaser__text {
	color:var(--color__link-color);
	font-weight:600;
	text-decoration:underline;
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-teaser--low .c-teaser__content:hover .c-teaser__text {
	color:var(--color__hover-button);
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-teaser--low .c-teaser__content:focus .c-teaser__text {
	color:var(--color__hover-button);
	text-decoration-thickness:0.5px;
}
.c-teaser--low .c-teaser__content:focus-visible {
	outline:none;
}
.c-teaser--low .c-teaser__content:hover .c-teaser__text::after,
.c-teaser--low .c-teaser__content:focus .c-teaser__text::after {
	background-image:url(/ui-project/components/icon/icons/arrow-right-alt.svg);
}

/* Background variants
----------------------------------------------------------------------------- */
.c-teaser__overlay--bg-none .c-teaser__intro,
.c-teaser__overlay--bg-none .c-teaser__content:not(.c-teaser--low .c-teaser__content) {
	background-color:transparent;
	color:var(--color__base-black);
}
.c-teaser__overlay--bg-white .c-teaser__intro,
.c-teaser__overlay--bg-white .c-teaser__content {
	background-color:var(--color__base-white);
	color:var(--color__base-black);
}
.c-teaser__overlay--bg-red .c-teaser__intro,
.c-teaser__overlay--bg-red .c-teaser__content:not(.c-teaser--low .c-teaser__content) {
	background-color:var(--color__ut_red);
}
.c-teaser__overlay--bg-darkgreen .c-teaser__intro,
.c-teaser__overlay--bg-darkgreen .c-teaser__content:not(.c-teaser--low .c-teaser__content) {
	background-color:var(--color__ut_darkgreen);
}
.c-teaser__overlay--bg-darkgrey .c-teaser__intro,
.c-teaser__overlay--bg-darkgrey .c-teaser__content:not(.c-teaser--low .c-teaser__content) {
	background-color:var(--color__ut_darkgrey);
}
.c-teaser__overlay--bg-green .c-teaser__intro,
.c-teaser__overlay--bg-green .c-teaser__content:not(.c-teaser--low .c-teaser__content) {
	background-color:var(--color__ut_green);
}
.c-teaser__overlay--bg-black .c-teaser__intro,
.c-teaser__overlay--bg-black .c-teaser__content:not(.c-teaser--low .c-teaser__content) {
	background-color:var(--color__base-black);
}
.c-teaser__overlay--bg-green-20 .c-teaser__intro,
.c-teaser__overlay--bg-green-20 .c-teaser__content {
	background-color:var(--color__ut_green-20);
	color:var(--color__base-black);
}
.c-teaser__overlay--bg-yellow-20 .c-teaser__intro,
.c-teaser__overlay--bg-yellow-20 .c-teaser__content {
	background-color:var(--color__ut_yellow-20);
	color:var(--color__base-black);
}
.c-teaser__overlay--bg-darkgreen-20 .c-teaser__intro,
.c-teaser__overlay--bg-darkgreen-20 .c-teaser__content {
	background-color:var(--color__ut_darkgreen-20);
	color:var(--color__base-black);
}


/* Print
----------------------------------------------------------------------------- */
@media print {
	.c-teaser {
		height:auto;
		border:1px solid;
	}
	.c-teaser__new-icon {
		z-index:100;
	}
	.c-teaser .c-teaser__content {
		background-color:var(--color__base-white);
		color:var(--color__base-black);
	}
	.c-teaser__image,
	.c-teaser__link {
		display:none;
	}
}