/* =============================================================================
 * Tab links
============================================================================= */

/* .c-tab-links__list {
	margin:calc(var(--space) * 2) 0;
} */
.o-grid .c-tab-links__list {
	margin-bottom:var(--space);
	gap:16px;
}
.c-tab-links__item {
	display:inline-block;
	padding:calc(var(--space) / 4) var(--space);
}
.c-tab-links__item:last-child {
	margin-right:0;
}
.c-tab-links__link {
	font-weight:600;
	font-size:16px;
	font-size:var(--font-size-large);
	line-height:24px;
	text-decoration:none;
}
.c-tab-links__link--selected {
	color:var(--color__base-black);
}

/* block version */
.c-tab-links--block {
	width:100%;
	text-align:left;
}
.c-tab-links--block .c-tab-links__list {
	display:flex;
	flex-wrap:wrap;
	width:100%;
	gap:16px;
}
.c-tab-links--block .c-tab-links__item {
	position:relative;
	padding:calc(var(--space) - 4px) calc(var(--space) + 12px) calc(var(--space) - 6px);
	background:var(--color__primary-alt_light);
	transition:0.2s;
}
.c-tab-links--block .c-tab-links__item--selected {
	background:var(--color__primary-dark);
}
.c-tab-links--block .c-tab-links__item.c-tab-links__item--selected .c-tab-links__link {
	border-bottom:2px solid var(--color__base-white);
	color:var(--color__base-white);
}
.c-tab-links--block .c-tab-links__item .c-tab-links__link {
	display:block;
	border-bottom:2px solid transparent;
	color:var(--color__base-black);
	transition:0.2s;
}
.c-tab-links--block .c-tab-links__item .c-tab-links__link:focus {
	outline:0;
	border-bottom:2px solid var(--color__base-black);
	text-decoration:none;
}
.c-tab-links--block .c-tab-links__link::after {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:"";
}
.c-tab-links--block .c-tab-links__item .c-tab-links__link:focus::after {
	border:2px solid var(--color__base-black);
}

@media only screen and (max-width:480px) {
	.c-tab-links {
		margin:0;
	}
	.c-tab-links--block {
		text-align:center;
	}
	.c-tab-links__item {
		padding:calc(var(--space) / 6) calc(var(--space) / 2);
	}
	.c-tab-links__link {
		font-size:16px;
	}
	.c-tab-links--block .c-tab-links__list {
		margin-left:0;
		gap:unset;
	}
	.c-tab-links--block .c-tab-links__item {
		flex:0 0 100%;
		margin:12px 0 0;
		padding:8px 16px 8px;
	}
	.c-tab-links--block .c-tab-links__link {
		padding:calc(var(--space) - 12px) calc(var(--space) - 4px);
	}
	.c-tab-links--block .c-tab-links__item.c-tab-links__item--selected .c-tab-links__link {
		border:0;
	}
	.c-tab-links--block .c-tab-links__item.c-tab-links__item--selected .c-tab-links__link span {
		border-bottom:2px solid var(--color__base-white);
	}
}

@media only screen and (min-width:481px) {
	.c-tab-links--block .c-tab-links__item.c-tab-links__item--selected:hover .c-tab-links__link,
	.c-tab-links--block .c-tab-links__item.c-tab-links__item--selected .c-tab-links__link:focus {
		border-bottom:2px solid var(--color__base-white);
	}
	.c-tab-links--block .c-tab-links__item:hover .c-tab-links__link,
	.c-tab-links--block .c-tab-links__item .c-tab-links__link:active {
		border-bottom:2px solid var(--color__base-black);
		text-decoration:none;
	}
}

@media print {
	.c-tab-links__link--selected {
		text-decoration:underline;
	}
}