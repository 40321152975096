.c-weatherdate__date-wrapper {
	display:flex;
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
}
.c-weatherdate__date,
.c-weatherdate__time,
.c-weatherdate__weather {
	flex:1;
	padding:24px 36px;
	background:var(--color__base-black);
	color:#fff;
	font-weight:700;
	font-size:32px;
	text-align:center;
}
.c-weatherdate__time {
	background:var(--color__primary-dark);
}
.c-weatherdate__weather {
	background:var(--color__secondary-alt_light);
	color:var(--color__primary-dark);
}