/* =============================================================================
 * Truncate
============================================================================= */
.c-truncate {
	position:relative;
	margin-bottom:calc(var(--space) * 2);
	padding-bottom:calc(var(--space) * 2);
}
.c-truncate.is-truncated .c-truncate__content {
	position:relative;
	max-height:10em; /* Fallback */
	max-height:calc(40px * var(--truncate-height));
	overflow-y:hidden;
}
.c-truncate.is-truncated .c-truncate__content::after {
	position:absolute;
	right:0;
	bottom:0;
	left:0;
	height:calc(var(--space) * 4);
	background:linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	content:"";
}
.c-truncate.not-truncated {
	margin-bottom:0;
	padding-bottom:0;
}
.c-truncate__show,
.c-truncate__show:active:not([disabled]) {
	position:absolute;
	right:0;
	bottom:0;
	flex-direction:row;
}
.c-truncate__show:focus,
.c-truncate__show:active {
	outline:0;
	border:0;
}
.c-truncate.is-truncated .c-truncate__show {
	bottom:0;
}
.c-truncate__button-icon {
	margin-bottom:0;
}
.c-truncate__button-icon svg {
	margin:0;
}
.c-truncate__show[aria-expanded="true"] .c-truncate__button-icon {
	margin-bottom:2px;
	transform:rotate(180deg);
}
.c-truncate.is-truncated[class] p {
	margin-bottom:0;
}