/* =============================================================================
 * Add multiple items to cart
 *
 * 1. Align with the custom number input
 * 2. Focus may be moved to list items with JavaScript after a product has been
 *    removed. This probably does not need to be visible.
============================================================================= */
.c-add-multiple-to-cart__list {
	margin-bottom:var(--space);
}
.c-add-multiple-to-cart__item {
	display:flex;
	padding:var(--space) 0;
	border-bottom:1px solid var(--color__grey-1);
	transition:opacity 0.5s ease-in-out;
}
.c-add-multiple-to-cart__item--remove {
	opacity:0;
}
.c-add-multiple-to-cart__item:focus {
	outline:none; /* 2 */
}
.c-add-multiple-to-cart__item-image {
	flex:none;
}
.c-add-multiple-to-cart__item-content {
	flex:1;
	margin-left:var(--space);
}
.c-add-multiple-to-cart__item-details {
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	margin-top:calc(var(--space) / 2);
}
.c-add-multiple-to-cart__item-details .c-form-item__label,
.c-add-multiple-to-cart__item-details .c-input-number__label {
	margin-bottom:0;
}
.c-add-multiple-to-cart__item-details .c-custom-select {
	height:43px; /* 1 */
}
.c-add-multiple-to-cart__remove {
	align-self:flex-start;
	flex:none;
	margin-top:calc(-0.5 * var(--space));
	margin-left:var(--space);
}

@media screen and (max-width:600px) {
	.c-add-multiple-to-cart__item-image img {
		width:50px;
	}
	.c-add-multiple-to-cart__item-heading {
		margin-bottom:5px;
	}
	.c-add-multiple-to-cart__item-details {
		flex-wrap:wrap;
	}
	.c-add-multiple-to-cart__item-details .c-price--per-item {
		width:100%;
	}
	.c-add-multiple-to-cart__item-details .c-price--per-item ~ * {
		margin-top:5px;
	}
	.c-add-multiple-to-cart__remove {
		margin-top:0;
	}
}