/* =============================================================================
 * Animations
============================================================================= */

/* Slide in
----------------------------------------------------------------------------- */
.b-animation-slide-in {
	animation:slideIn 0.2s linear forwards;
}

@keyframes slideIn {
	0% {
		opacity:0;
		transform:translateY(-0.5rem);
	}
	100% {
		opacity:1;
		transform:translateY(0);
	}
}