.c-video-player {
	display:inline-block;
	position:relative;
}
.c-video-player--iframe {
	display:block;
}
.c-video-player__video {
	display:none;
	width:100%;
	height:100%;
	outline:none;
}
.no-js .c-video-player__video,
.c-video-player__container--show .c-video-player__video {
	display:block;
}
.c-video-player__img--hide {
	visibility:hidden;
	z-index:-1;
}
.no-js .c-video-player__img {
	display:none;
}
.c-video-player__container {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:-1;
	background:#000;
	opacity:0;
	transition:opacity 0.4s;
}
.no-js .c-video-player__container,
.c-video-player__container--show {
	z-index:10;
	opacity:1;
}
.c-video-player--iframe .c-video-player__container {
	position:relative;
	z-index:10;
	width:100%;
	height:0;
	padding-bottom:56.25%;
	opacity:1;
}
.video-block__vimeo-inner {
	position:relative;
	height:0;
	padding-bottom:56.25%;
}
.c-video-player--iframe iframe {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.no-js .c-video-player__container {
	position:relative;
}
.c-video-player__btn {
	position:absolute;
	top:50%;
	left:50%;
	width:48px;
	height:48px;
	margin:0 auto;
	outline:none;
	border:none;
	background:transparent;
	opacity:1;
	cursor:pointer;
	transition:all 0.6s;
	transform:scale(1) translate(-50%, -50%);
	transform-origin:0 0;
}
.c-video-player__btn::after {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1;
	border:3px solid #fff;
	background:rgba(0, 0, 0, 0.25);
	content:"";
	transition:all 0.3s;
}
.c-video-player__btn:hover,
.c-video-player__btn:focus {
	transform:scale(1.1) translate(-50%, -50%);
}
.c-video-player__btn:hover::after,
.c-video-player__btn:focus::after {
	background:rgba(0, 0, 0, 0.35);
}
.c-video-player__btn--fade {
	opacity:0;
	transform:scale(0) translate(-50%, -50%);
}
.no-js .c-video-player__btn {
	display:none;
}
.c-video-player__play-icon {
	position:relative;
	z-index:10;
	width:19px;
	height:15px;
}
.c-video-player__play-icon svg {
	width:100%;
	height:100%;
	fill:#fff;
}

@media print {
	.c-video-player {
		margin:0 auto;
	}
	.c-video-player__img {
		width:auto;
	}
}