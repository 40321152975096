.c-header-mobile {
	z-index:10;
	width:100%;
	height:112px;
	background-color:var(--color__base-white);
	font-size:var(--font-size-default);
}
.c-header-mobile__inner {
	display:-webkit-box;
	display:flex;
	display:-webkit-flex;
	position:fixed;
	top:0;
	z-index:200;
	-ms-flex-pack:justify;
	-webkit-justify-content:space-between;
	justify-content:space-between;
	-ms-flex-align:center;
	-webkit-align-items:center;
	align-items:center;
	width:100vw;
	padding:calc(var(--space) / 2);
	padding-right:var(--space);
	-webkit-box-shadow:0 0 11px -1px rgba(0, 0, 0, 0.6);
	box-shadow:0 0 11px -1px rgba(0, 0, 0, 0.6);
	background-color:var(--color__primary-dark);
	-webkit-box-pack:justify;
	-webkit-box-align:center;
}

/* animated menu icon */
.c-header-mobile__icon {
	position:relative;
	width:24px;
	height:16px;
	cursor:pointer;
	transition:0.2s ease;
	transform:rotate(0deg);
}
.c-header-mobile__icon span {
	display:block;
	position:absolute;
	left:0;
	width:100%;
	height:2px;
	border-radius:9px;
	background:var(--color__base-white);
	opacity:1;
	transition:0.25s ease-in-out;
	transform:rotate(0deg);
}
.c-header-mobile__icon span:nth-child(1) {
	top:0;
}
.c-header-mobile__icon span:nth-child(2),
.c-header-mobile__icon span:nth-child(3) {
	top:6px;
}
.c-header-mobile__icon span:nth-child(4) {
	top:12px;
}
.c-header-mobile__icon.js-active span:nth-child(1) {
	top:16px;
	left:50%;
	width:0;
}
.c-header-mobile__icon.js-active span:nth-child(2) {
	transform:rotate(45deg);
}
.c-header-mobile__icon.js-active span:nth-child(3) {
	transform:rotate(-45deg);
}
.c-header-mobile__icon.js-active span:nth-child(4) {
	top:18px;
	left:50%;
	width:0;
}
.c-header-mobile__buttons {
	display:flex;
	display:-webkit-flex;
	align-items:center;
}
.c-header-mobile__search {
	margin-right:var(--space);
}
.c-header-mobile__button,
.c-header-mobile__link,
.c-header-mobile__link:hover {
	display:flex;
	position:relative;
	flex-direction:column;
	align-items:center;
	padding:0;
	background-color:transparent;
	color:var(--color__base-white);
	font-weight:600;
	font-size:12px;
	text-align:center;
	text-decoration:none;
	cursor:pointer;
}
.c-header-mobile__button {
	margin-left:var(--space);
}
.c-header-mobile__button-text {
	display:block;
	padding-top:5px;
	color:var(--color__base-white);
	font-size:14px;
	line-height:1;
	text-align:center;
}
.c-header-mobile__icons {
	display:flex;
}
.c-header-mobile__cart {
	margin-left:calc(var(--space) / 2);
}

@media screen and (max-width:350px) {
	.c-header-mobile__inner {
		padding:calc(var(--space) / 2);
	}
}

@media print, screen and (min-width:1025px) {
	.c-header-mobile {
		display:none;
	}
}