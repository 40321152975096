/* =============================================================================
 * Icon
============================================================================= */

/* Import Grunticon generated files
--------------------------------------------------------------------------- */
.c-icon {
	display:inline-block;
	line-height:1;
	vertical-align:middle;
	text-align:center;
	text-indent:0;
	fill:currentColor;
}
.c-icon path {
	transition-property:fill;
}

/* Sizes
--------------------------------------------------------------------------- */
.c-icon--13x13 > svg,
.c-icon--13x13 {
	width:13px;
	height:13px;
}
.c-icon--16x16 > svg,
.c-icon--16x16 {
	width:16px;
	height:16px;
}
.c-icon--24x24 > svg,
.c-icon--24x24 {
	width:24px;
	height:24px;
}
.c-icon--32x32 > svg,
.c-icon--32x32 {
	width:32px;
	height:32px;
}
.c-icon--40x40 > svg,
.c-icon--40x40 {
	width:40px;
	height:40px;
}
.c-icon--48x48 > svg,
.c-icon--48x48 {
	width:48px;
	height:48px;
}

/* Colors
--------------------------------------------------------------------------- */
.c-icon--black {
	color:var(--color__base-black);
}

/* Circle icons
--------------------------------------------------------------------------- */
.c-icon--circle {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	border:2px solid currentColor;
	border-radius:50%;
}
.c-icon--circle > svg {
	width:50%;
	height:50%;
}
.c-icon--circle-red-bg {
	border-color:var(--color__ut_red);
	background-color:var(--color__ut_red);
	color:var(--color__base-white);
}
.c-icon--circle-red-bg > svg {
	width:75%;
	height:75%;
	fill:var(--color__base-white);
}

/* Add space
--------------------------------------------------------------------------- */
.c-icon--ml {
	margin-left:6px;
}
.c-icon--mr {
	margin-right:6px;
}
.c-icon--ml-m {
	margin-left:12px;
}
.c-icon--mr-m {
	margin-right:12px;
}
.c-icon--ml-l {
	margin-left:18px;
}
.c-icon--mr-l {
	margin-right:18px;
}

/* Flip
--------------------------------------------------------------------------- */
.c-icon--flip-180 {
	transform:rotate(180deg);
}