/* =============================================================================
 * Search form
============================================================================= */
.c-search-form__form {
	display:flex;
	position:relative;
}
.c-search-form__field {
	display:flex;
	flex-grow:1;
}
.c-search-form__input {
	padding:calc(var(--space) / 2) var(--space);
	border:1px solid var(--color__primary-alt);
	background:#fff;
}
.c-search-form__input:focus {
	border-color:var(--color__primary-alt);
}
.c-search-form__button {
	border-radius:0 var(--border__radius) var(--border__radius) 0;
}

/* Search form header */
.c-search-form__header .c-search-form__input {
	margin-right:calc(var(--space) / 2);
	padding-left:calc(var(--space) * 2 + 10px);
	background:var(--color__base-white);
	color:var(--color__base-black);
}
.c-header:not(.c-header--private) .c-search-form__header .c-search-form__input {
	border:none;
}
.c-search-form__header .c-search-form__input::placeholder {
	color:var(--color__base-black);
	opacity:1;
}
.c-search-form__header .c-header-search__icon {
	position:absolute;
	left:0;
	transform:translate(10px, 10px);
	fill:var(--color__base-black);
}
.c-search-form__header .c-search-form__input:hover::placeholder,
.c-search-form__header .c-search-form__input:focus::placeholder {
	opacity:0;
}

@media screen and (max-width:1024px) {
	.c-header__search-form {
		position:fixed;
		top:67px;
		z-index:200;
		width:100%;
	}
	.c-search-form__header .c-search-form__input {
		margin:0;
		border-bottom:1px solid rgba(46, 92, 100, 0.2);
		background:var(--color__ut_grey-30);
		color:var(--color__base-black);
	}
	.c-search-form__header .c-search-form__input:hover,
	.c-search-form__header .c-search-form__input:focus {
		background:var(--color__ut_grey-30);
	}
	.c-search-form__header .c-search-form__input::placeholder {
		color:var(--color__base-black);
	}
	.c-search-form__header .c-header-search__icon {
		fill:var(--color__ut_darkgreen);
	}
}