/* =============================================================================
 * Tooltip
 *
 * A keyboard accessible, ARIA-enabled tooltip
============================================================================= */
.c-series-tooltip {
	position:relative;
}
.c-series-tooltip__content {
	position:absolute;
	top:calc(100% + 10px);
	min-width:150px;
	max-width:250px !important;
	width:80%;
	padding:calc(var(--space) / 2);
	border:1px solid rgb(0, 0, 0);
	box-shadow:0 0 4px rgba(0, 0, 0, 0.2);
	background:rgb(255, 255, 255);
	color:rgb(0, 0, 0);
	font-weight:400;
	font-size:14px;
	line-height:1.4;
	text-align:left;
	opacity:1;
	transition:all 0.16s ease;
	pointer-events:auto;
}
.c-series-tooltip__content.js {
	left:50%;
	z-index:15;
	transform:translate(-50%, 0);
}
.c-series-tooltip__content::before,
.c-series-tooltip__content::after {
	position:absolute;
	top:-7px;
	left:50%;
	border:5px solid transparent;
	border-width:0 7px 7px 7px;
	border-bottom-color:rgb(0, 0, 0);
	content:"";
	transform:translate(-50%, 0);
}
.c-series-tooltip__content::after {
	top:-6px;
	left:50%;
	border-width:0 6px 6px 6px;
	border-bottom-color:rgb(255, 255, 255);
	transform:translate(-50%, 0);
}
.c-series-tooltip__content[aria-hidden="true"] {
	opacity:0;
	pointer-events:none;
}

/* .c-teaser__overlay .c-series-tooltip__content::before,
.c-teaser__overlay .c-series-tooltip__content::after {
	display:none;
}
.c-teaser__overlay .c-series-tooltip__content {
	top:unset;
	bottom:calc(100% + 10px);
} */
.c-product-grid__item .c-series-tooltip__content {
	left:10%;
	transform:translate(0, 0);
}

/* The element that triggers the tooltip
----------------------------------------------------------------------------- */
.c-series-tooltip__trigger {
	padding:4px;
	cursor:pointer;
}
.c-series-tooltip__trigger--active {
	background:#000;
	color:#fff;
}

/* Disabled series page
----------------------------------------------------------------------------- */
div[data-disabled-series-page] .c-tab-links {
	width:100%;
}
div[data-disabled-series-page] .c-tab-links__item {
	display:none;
}
div[data-disabled-series-page] .c-tab-links__item:first-child {
	display:block;
}
div[data-disabled-series-page] .c-header__inner {
	padding:var(--space);
}
div[data-disabled-series-page] .c-series-hero {
	margin-bottom:0;
}
.c-disabled-series-page__message {
	width:100%;
	background-color:var(--color__error-light);
}
.c-disabled-series-page__message div {
	display:flex;
	max-width:1220px;
	margin-right:auto;
	margin-bottom:calc(var(--space) * 2);
	margin-left:auto;
	padding:calc(var(--space) + 4px);
	padding-right:var(--space);
	padding-left:var(--space);
}
.c-series-tooltip__close {
	position:fixed;
	top:var(--space);
	right:var(--space);
	z-index:10;
	width:33px;
	height:33px;
	border-radius:50%;
	background-color:rgba(255, 255, 255, 0.7);
	background-image:url(/ui-project/components/icon/icons/close.svg);
	background-position:center;
	background-repeat:no-repeat;
	cursor:pointer;
}
.c-series-tooltip__close:hover {
	background-color:var(--color__ut_grey);
}
div[data-disabled-series-page] .c-carousel__container {
	overflow:visible;
}
div[data-disabled-series-page] .c-carousel__list {
	margin-bottom:-100px;
	padding-bottom:100px;
}
div[data-disabled-series-page] .c-teaser__overlay {
	overflow:visible;
}

@media screen and (max-width:1024px) {
	div[data-disabled-series-page] .c-header {
		display:block;
	}
}