/* =============================================================================
 * List inline
============================================================================= */

/*
 * Default
 */
.o-list-inline {
	display:flex;
	flex-wrap:wrap;
}

/*
 * @md
 */
@media screen and (min-width:768px) {
	.o-list-inline\@md {
		display:flex;
	}
}

/*
 * @lg
 */
@media screen and (min-width:1024px) {
	.o-list-inline\@lg {
		display:flex;
	}
}

/*
 * @xl
 */
@media screen and (min-width:1400px) {
	.o-list-inline\@xl {
		display:flex;
	}
}