.c-series-shortcut {
	display:none;
	align-items:flex-start;
	width:100%;
	padding-bottom:calc(var(--space) * 2);
}
.c-series-shortcut .c-button {
	display:flex;
	align-items:center;
	gap:calc(var(--space) / 2);
}
.c-series-shortcut .c-icon {
	transform:rotate(90deg);
}