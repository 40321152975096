.c-get-more {
	padding:var(--space) 0 calc(var(--space) * 2);
	text-align:center;
}
.c-get-more__button {
	display:inline-block;
	width:70%;
	background:var(--color__base-black);
}
.c-get-more__count {
	display:block;
	margin-bottom:calc(var(--space) / 2);
}
.c-get-more__animate-in {
	position:relative;
	-webkit-animation:get-more-animate 0.3s ease-in-out;
	animation:get-more-animate 0.3s ease-in-out;
}
.c-get-more__animate-in:focus {
	outline:none;
}
.c-get-more__button--loading::before {
	display:inline-block;
	width:24px;
	height:24px;
	margin-right:8px;
	background:url(../../images/loading.svg);
	background-size:100%;
	content:"";
	vertical-align:-6px;
}

@-webkit-keyframes get-more-animate {
	from {
		opacity:0;
		-webkit-transform:translateY(-20px);
		transform:translateY(-20px);
	}
	to {
		opacity:1;
		-webkit-transform:translateY(0);
		transform:translateY(0);
	}
}

@keyframes get-more-animate {
	from {
		opacity:0;
		-webkit-transform:translateY(-20px);
		transform:translateY(-20px);
	}
	to {
		opacity:1;
		-webkit-transform:translateY(0);
		transform:translateY(0);
	}
}

@media screen and (min-width:768px) {
	.c-get-more__button {
		width:30%;
		padding:15px 20px;
		border-bottom:none;
	}
}

/* Print
----------------------------------------------------------------------------- */
@media print {
	.c-get-more {
		display:none;
	}
}