/* =============================================================================
 * Flex
============================================================================= */
.t-flex {
	display:inline-flex !important;
}

/* =============================================================================
 * Flex wrap
============================================================================= */
.t-flex-wrap {
	flex-wrap:wrap !important;
}
.t-flex-no-wrap {
	flex-wrap:nowrap !important;
}

/* =============================================================================
 * Flex alignment
============================================================================= */
.t-flex-align-left {
	align-self:flex-start !important;
}
.t-flex-align-right {
	align-self:flex-end !important;
}
.t-flex-align-center {
	align-self:center !important;
}
.t-flex-align-items-center {
	align-items:center !important;
}