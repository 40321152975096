/* =============================================================================
 * Logo
 *
 * 1. For text fallback if image doesn't load.
============================================================================= */
.c-logo svg {
	max-width:141px;
	width:100%;
}
.c-logo img {
	font-weight:400; /* [1] */
}
.c-logo__link,
.c-logo__link:visited {
	display:inline-block;
	max-height:47px;
	color:inherit; /* [1] */
	text-decoration:none;
}
.c-logo__link:focus-visible,
.c-footer__logo-link:focus-visible {
	outline:2px dashed #fff;
	outline-offset:4px;
}
.c-header--private .c-logo__link:focus-visible {
	outline:2px dashed #000;
}