/* =============================================================================
 * Action box
 * A teaser-like box used for calls to action
============================================================================= */
.c-action-box {
	margin-bottom:var(--space);
	padding:calc(var(--space) + 12px); /* 32px */
}
.c-action-box > *:last-child {
	margin-bottom:0;
}
.c-action-box .c-button:first-child {
	margin-right:calc(var(--space) - 4px);
	margin-bottom:calc(var(--space) - 12px);
}
.c-main-navigation__action-box__wrapper .c-action-box {
	background:#fff;
}

@media only screen and (max-width:1023px) {
	.c-action-box {
		padding:calc(var(--space) + 4px); /* 24 */
	}
}

@media only screen and (max-width:767px) {
	.c-action-box {
		padding:calc(var(--space) - 4px); /* 16px */
	}
}


/* Centered content above 767px
----------------------------------------------------------------------------- */
@media only screen and (min-width:768px) {
	.c-action-box--centered {
		text-align:center;
	}
	.c-action-box--max-width {
		max-width:700px;
		margin:auto;
	}
	.c-action-box--max-width > *:last-child {
		margin-bottom:0;
	}
}


/* Highlighted
----------------------------------------------------------------------------- */
.c-action-box--highlighted {
	background-color:var(--color__primary);
	color:#fff;
}


/* Print
----------------------------------------------------------------------------- */
@media print {
	.c-action-box {
		margin-bottom:calc(var(--space) * 2);
		padding:0;
		background:transparent;
		color:#000;
	}
}