/* =============================================================================
 * Section header
 * Not to be confused with c-section__header
============================================================================= */
.c-section-header {
	padding:calc(var(--space) / 2) var(--space);
	border-bottom:2px solid var(--color__primary);
	background-color:var(--color__primary-alt_light);
}
.c-section-header--subheading {
	padding-right:0;
	padding-left:0;
	border-bottom:1px solid var(--color__grey-2);
	background:transparent;
}
.c-section-header + .c-section-header--subheading {
	padding-top:calc(var(--space) * 2);
}
.c-section-header__heading {
	font-weight:700;
}

@media screen and (max-width:640px) {
	.c-section-header:not(.c-section-header--subheading) {
		text-align:center;
	}
	.c-section-header > * {
		display:inline-block;
		margin-right:calc(var(--space) / 2);
	}
	.c-section-header > *:last-child {
		margin-right:0;
	}
	.c-section-header > * + * {
		margin-top:calc(var(--space) / 2);
	}
}

@media screen and (min-width:641px), print {
	.c-section-header {
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;
		align-items:center;
	}
	.c-section-header > a {
		margin-top:0;
	}
}