/* =============================================================================
 * Section
============================================================================= */
.c-section:first-child {
	margin-top:var(--space);
}
.c-section--teasers {
	padding-bottom:0;
}
.c-section--teasers .o-width-limiter {
	padding:0;
}
.c-section--dashboard {
	padding-bottom:20px;
}
.c-section--carousel {
	max-width:1180px;
	width:100%;
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_darkgreen-20);
}

/*
 * Modifier
 */
.c-section--compact {
	padding:calc(var(--space) * 2) 0;
}
.c-section--bg-wide {
	position:relative;
	right:50%;
	left:50%;
	width:100vw;
	margin-right:-50vw;
	margin-left:-50vw;
}

/*
 * Backgrounds
 */
.c-section--bg-white {
	background:var(--color__base-white);
	color:var(--color__base-black);
}
.c-section--bg-white .o-width-limiter {
	padding:0;
}
.c-section--bg-red {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_red);
	color:var(--color__base-white);
}
.c-section--bg-red .c-button {
	background:var(--color__base-white);
	color:var(--color__ut_darkgreen);
}
.c-section--bg-red .c-button:hover {
	background:var(--color__base-white);
	color:var(--color__base-black);
}
.c-section--bg-darkgreen {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_darkgreen);
	color:var(--color__base-white);
}
.c-section--bg-darkgreen .c-button {
	background:var(--color__base-white);
	color:var(--color__ut_darkgreen);
}
.c-section--bg-darkgreen .c-button:hover {
	background:var(--color__base-white);
	color:var(--color__base-black);
}
.c-section--bg-darkgrey {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_darkgrey);
	color:var(--color__base-white);
}
.c-section--bg-green {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_green);
	color:var(--color__base-white);
}
.c-section--bg-black {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__base-black);
	color:var(--color__base-white);
}
.c-section--bg-yellow-20 {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_yellow-20);
	color:var(--color__base-black);
}
.c-section--bg-darkgreen-20,
.c-section--bg-grey {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_darkgreen-20);
	color:var(--color__base-black);
}
.c-section--bg-green-20 {
	padding:calc(var(--space) * 2) 0;
	background:var(--color__ut_green-20);
	color:var(--color__base-black);
}

@media only screen and (max-width:767px) {
	/* .c-section {
		padding:calc(var(--space) * 2) 0;
	}
	.c-section--teasers {
		padding-bottom:var(--space);
	}
	.c-section--space-only-bottom {
		padding-top:calc(var(--space));
	} */
}

@media only screen and (max-width:1200px) {
	.c-section--carousel {
		width:calc(100% + 40px);
		margin:0 -20px;
	}

	/* div[class*="c-section c-section--bg-"] {
		margin-right:-20px;
		margin-left:-20px;
	}
	.c-section {
		margin-top:var(--space);
	} */
}

@media print {
	.c-section {
		padding:var(--space) 0;
		background:transparent !important;
	}
}

/*
 * Padding
 */
.c-section--space-only-top {
	padding-bottom:0;
}
.c-section--space-only-bottom {
	padding-top:0;
}

/* Section header
----------------------------------------------------------------------------- */
.c-section__header {
	margin-bottom:calc(var(--space) * 4);
	text-align:center;
}

@media print {
	.c-section__header {
		margin-bottom:calc(var(--space) * 2);
	}
}