/* =============================================================================
 * Hidden
 *
 * Hide element
 * Remember, any change must be made in all variants of the trump.
============================================================================= */
.t-hidden,
.js .t-hidden-js-only {
	display:none;
	visibility:hidden;
}

/*
 * @sm
 */
@media only screen and (max-width:767px) {
	.t-hidden\@sm-only {
		display:none;
		visibility:hidden;
	}
}

/*
 * @md
 */
@media screen and (min-width:768px) {
	.t-hidden\@md {
		display:none;
		visibility:hidden;
	}
}

/*
 * @lg
 */
@media screen and (min-width:1024px) {
	.t-hidden\@lg {
		display:none;
		visibility:hidden;
	}
}

/*
 * @xl
 */
@media screen and (min-width:1400px) {
	.t-hidden\@xl {
		display:none;
		visibility:hidden;
	}
}