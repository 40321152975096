/* =============================================================================
 * Customer tab links
============================================================================= */
.c-customer-links {
	display:flex;
}
.c-customer-links__item {
	display:inline-block;
	position:relative;
}
.c-customer-links__item:hover .c-customer-links__link {
	border-bottom:3px solid var(--color__base-black);
}
.c-customer-links__item:first-of-type {
	margin:0 16px 0 20px;
}
.c-customer-links__item[data-customer-type="1"] .c-customer-links__link:not(.c-header--private .c-customer-links__item[data-customer-type="1"] .c-customer-links__link),
.c-customer-links__item[data-customer-type="1"] .c-customer-links__link:focus {
	border-bottom:3px solid var(--color__base-black);
}
.c-header--private .c-customer-links__item[data-customer-type="1"] .c-customer-links__link:focus {
	border-bottom:3px solid var(--color__base-black);
}
.c-customer-links__link {
	display:inline-block;
	padding:19px 0 5px;
	color:currentColor;
	font-weight:600;
	font-size:18px;
	line-height:24px;
	text-decoration:none;
	cursor:pointer;
	transition:font-size 0.2s ease, color 0.2s ease;
}
.c-customer-links__item[data-customer-type="2"] .c-customer-links__link,
.c-header--private .c-customer-links__item[data-customer-type="1"] .c-customer-links__link {
	font-weight:400;
	letter-spacing:0.3px;
}
.c-header--private .c-customer-links__item[data-customer-type="2"] .c-customer-links__link {
	border-bottom:3px solid var(--color__base-black);
	font-weight:600;
	letter-spacing:0;
}
.c-customer-links__item:first-of-type .c-customer-links__link::after {
	display:inline-block;
	width:0;
	content:"|";
	text-indent:3px;
	pointer-events:none;
}
.c-customer-links__link:focus {
	outline:0;
	border-bottom:3px solid var(--color__base-white);
	color:currentColor;
	text-decoration:none;
}
.c-customer-links__link:hover {
	text-decoration:none;
}

@media screen and (min-width:1025px) {
	.c-header--private .c-customer-links__item:hover .c-customer-links__link {
		border-bottom:3px solid var(--color__base-black);
	}
	.c-customer-links__item:hover .c-customer-links__link {
		border-bottom:3px solid var(--color__base-white);
	}
	.c-customer-links__link:hover {
		border-bottom:3px solid var(--color__base-white);
		color:currentColor;
		text-decoration:none;
	}
	.c-customer-links__item[data-customer-type="1"] .c-customer-links__link:not(.c-header--private .c-customer-links__item[data-customer-type="1"] .c-customer-links__link),
	.c-customer-links__item[data-customer-type="1"] .c-customer-links__link:focus {
		border-bottom:3px solid var(--color__base-white);
	}
}