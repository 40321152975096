/* =============================================================================
 * Custom radio
 *
 * 1. Fix flex shrink problem
============================================================================= */
.c-custom-radio {
	display:inline-block;
	position:relative;
	min-width:30px; /* 1 */
	width:30px;
	height:30px;
	vertical-align:middle;
}
.c-custom-radio__input {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	opacity:0;
}
.c-custom-radio__overlay {
	position:absolute;
	top:10px;
	right:10px;
	bottom:10px;
	left:10px;
	border-radius:50%;
	box-shadow:0 0 0 7px #fff, 0 0 0 8px var(--color__ut_darkgrey-95);
	background:#fff;
	pointer-events:none;
}


/* Checked
----------------------------------------------------------------------------- */
.c-custom-radio__input:checked + .c-custom-radio__overlay {
	background:var(--color__primary);
}

/* Hover
----------------------------------------------------------------------------- */
.c-custom-radio__input:not([disabled]):hover + .c-custom-radio__overlay {
	box-shadow:0 0 0 7px #fff, 0 0 0 8px var(--color__primary-alt);
}

/* Focus
----------------------------------------------------------------------------- */
.c-custom-radio__input:not([disabled]):focus + .c-custom-radio__overlay {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:10px;
	box-shadow:0 0 0 7px #fff, 0 0 0 8px var(--color__primary-alt);
	background:var(--color__primary-dark);
	color:var(--color__base-white);
}

/* Disabled
----------------------------------------------------------------------------- */
.c-custom-radio__input[disabled] + .c-custom-radio__overlay {
	opacity:0.4;
}