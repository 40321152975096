/* =============================================================================
 * Button as text
 *
 * 1. If this modifier is set, we don't touch the border. That allows us to,
 *    in the component, style the border whichever way we want. If we didn't
 *    do it this way, we'd have to "trump a trump" in components.
 * 2. This should correspond to the site's default line height.
 * 3. Recreate how your links look here.
============================================================================= */
.o-button-as-text {
	background-color:transparent;
	color:inherit;
	font-size:inherit;
	line-height:inherit; /* [2] */
	text-align:inherit;
	cursor:pointer;
}
.o-button-as-text:not(.t-button-as-text--stylable-border) {
	/* [1] */
	border:none;
}


/* Link
----------------------------------------------------------------------------- */
.o-button-as-text--link[class] {
	/* [3] */
	color:var(--color__link-color);
}
.o-button-as-text--link[class]:hover,
.o-button-as-text--link[class]:focus {
	outline:none;
	color:var(--color__primary-dark);
	text-decoration:underline;
}