/* =============================================================================
 * series-hero hero
============================================================================= */
.c-series-hero {
	overflow:hidden;
	margin-bottom:calc(var(--space) * 2);
	background:var(--color__ut_darkgreen);
	color:var(--color__base-white);
}
.c-series-hero--green-20 {
	background:var(--color__ut_green-20);
}
.c-series-hero__inner {
	display:flex;
	justify-content:space-evenly;
	align-items:center;
	max-width:1180px;
	width:100%;
	margin-right:auto;
	margin-left:auto;
	padding:30px 30px 30px 0;
}
.c-series-hero__serie {
	font-size:30px;
	font-family:"verveine", sans-serif;
}
.c-series-hero__heading {
	font-weight:700;
	font-size:var(--font-size-heading);
	line-height:var(--font-line-height-heading);
}
.c-series-hero__subheading {
	margin-bottom:calc(var(--space) / 2);
	font-size:22px;
}
.c-series-hero__textcontent {
	display:inline-flex;
	flex-direction:column;
	justify-content:center;
	flex:1;
	min-width:450px;
	min-height:200px;
	padding:0 var(--space);
}
.c-series-hero__textcontent p {
	margin-bottom:var(--space);
}
.c-series-hero__image-wrapper {
	height:250px;
}
.c-series-hero__image-wrapper img {
	object-position:right;
}
.c-series-hero__right {
	position:relative;
	flex:1;
}
.c-series-hero__books-wrapper {
	display:flex;
	position:absolute;
	bottom:50%;
	left:30px;
	justify-content:center;
	flex-wrap:wrap-reverse;
	align-items:center;
	width:500px;
	opacity:1;
	transform:translateY(50%) rotate(45deg);
}
.c-series-hero__books-wrapper picture {
	position:relative;
	width:14%;
	margin:9.6%;
	border-radius:0 7px 7px 0;
	box-shadow:0 0 10px 5px rgba(0, 0, 0, 0.3);
	transition:all 0.6s ease-out;
	transform:rotate(-90deg);
}
.c-series-hero__books-wrapper picture img {
	border-radius:0 7px 7px 0;
}

/* .c-series-hero__books-wrapper.loaded */
.c-series-hero__books-wrapper picture {
	width:28%;
	margin:10px;
	transform:rotate(0);
}
.c-series-hero__books-wrapper.books-1 picture,
.c-series-hero__books-wrapper.books-2 picture {
	width:40%;
	border-radius:0 10px 10px 0;
}
.c-series-hero__books-wrapper.books-3 picture {
	width:32%;
	border-radius:0 10px 10px 0;
}
.c-series-hero__books-wrapper.books-3 picture {
	margin:-10% 5%;
}
.c-series-hero__books-wrapper.books-4 picture:nth-last-child(2) {
	margin-left:15%;
}
.c-series-hero__books-wrapper.books-4 picture:nth-last-child(3) {
	margin-right:15%;
}
.c-series-hero__books-wrapper picture::after {
	position:absolute;
	right:-2px;
	bottom:50%;
	z-index:-1;
	width:10px;
	height:98%;
	border-radius:5px;
	background:var(--color__base-white);
	content:"";
	transform:translate(0, 50%);
}

/* Swap properties with .c-series-hero__books-wrapper and add .loaded to animate  */
.c-series-hero__books-wrapper.loaded {
	bottom:-600px;
	left:300px;
	width:2000px;
	opacity:0;
	transition:all 0.6s ease-out;
	transform:rotate(-15deg);
}

/* Add .loaded (.c-series-hero__books-wrapper.books-1.loaded) to animate */
.c-series-hero__books-wrapper.books-1,
.c-series-hero__books-wrapper.books-2,
.c-series-hero__books-wrapper.books-3 {
	left:-20px;
	flex-wrap:wrap;
	transform:translateY(50%) rotate(15deg);
}
.c-series-hero__books-wrapper.books-1,
.c-series-hero__books-wrapper.books-3 {
	left:-75px;
}
.c-series-hero__books-wrapper.books-3 {
	transform:translateY(50%) rotate(25deg);
}

/* Animation end */
.c-series-hero .c-breadcrumbs {
	margin:0 0 calc(var(--space) / 2) 0;
}
.c-series-hero .c-breadcrumbs a {
	color:var(--color__base-white);
}

/* Background colors
---------------------------------------------------------------------------- */
.c-series-hero--bg-red {
	background:var(--color__ut_red);
}
.c-series-hero--bg-darkgreen {
	background:var(--color__ut_darkgreen);
}
.c-series-hero--bg-darkgrey {
	background:var(--color__ut_darkgrey);
}
.c-series-hero--bg-green {
	background:var(--color__ut_green);
}
.c-series-hero--bg-black {
	background:var(--color__base-black);
}
.c-series-hero--bg-yellow-20 {
	background:var(--color__ut_yellow-20);
	color:var(--color__base-black);
}
.c-series-hero--bg-darkgreen-20 {
	background:var(--color__ut_darkgreen-20);
	color:var(--color__base-black);
}
.c-series-hero--bg-green-20 {
	background:var(--color__ut_green-20);
	color:var(--color__base-black);
}

@media (max-width:1024px) {
	.c-series-hero__image-wrapper:not(.c-series-hero__right .c-series-hero__image-wrapper) {
		display:none;
	}

	/* Add .loaded to animate */
	.c-series-hero__books-wrapper {
		left:50px;
		width:450px;
	}
	.c-series-hero__books-wrapper.books-1 {
		left:-50px;
	}
}

@media (max-width:768px) {
	.c-series-hero__inner {
		flex-direction:column;
		padding:0;
	}
	.c-series-hero__inner .books {
		padding-top:200px;
	}
	.c-series-hero__right {
		width:100%;
	}
	.c-series-hero__heading {
		font-size:var(--font-size-heading2);
		line-height:var(--font-line-height-heading2);
	}
	.c-series-hero__textcontent {
		order:2;
		min-width:unset;
		width:100%;
		padding:var(--space);
	}
	.c-series-hero__image-wrapper {
		position:relative;
		width:100%;
	}

	/* Add .loaded to animate */
	.c-series-hero__books-wrapper {
		bottom:-40px;
		left:50% !important;
		transform:translate(-44%, 0) rotate(15deg) !important;
	}
	.c-series-hero__books-wrapper.books-1 {
		transform:translateX(-50%) rotate(15deg);
	}
	.c-series-hero__books-wrapper.books-1 picture,
	.c-series-hero__books-wrapper.books-2 picture {
		width:33%;
	}
	.c-series-hero__books-wrapper.books-3 {
		bottom:10px;
	}
	.c-series-hero .c-breadcrumbs {
		display:none;
	}
}

@media (max-width:767px) {
	.c-series-hero__textcontent {
		display:block;
		min-height:auto;
	}
	.c-series-hero__textcontent .c-button {
		width:100%;
	}
}

@media (min-width:1240px) {
	.c-series-hero__inner {
		padding:30px 0;
	}
	.c-series-hero__textcontent {
		padding-right:calc(var(--space) * 1.5);
		padding-left:0;
	}
	.c-series-hero__books-wrapper,
	.c-series-hero__books-wrapper.books-2 {
		left:100px;
	}
	.c-series-hero__books-wrapper.books-3,
	.c-series-hero__books-wrapper.books-1 {
		left:50px;
	}
}