.c-secondary-navigation {
	display:flex;
	justify-content:center;
}
a.c-secondary-navigation__link-button {
	display:inline-block;
	margin:0 10px;
	border-bottom-color:#cfcfcf;
	background-color:var(--color__primary-alt_light);
	color:var(--color__primary-alt);
	transition:padding 0.2s ease, font-size 0.2s ease;
}
a.c-secondary-navigation__link-button:hover,
a.c-secondary-navigation__link-button:focus {
	border-bottom-color:var(--color__primary-alt);
	background-color:var(--color__primary-alt);
	color:var(--color__base-white);
	text-decoration:none;
}

@media screen and (max-width:1023px) {
	.c-secondary-navigation {
		text-align:center;
	}
	a.c-secondary-navigation__link-button {
		margin:0 5px;
		padding-right:10px;
		padding-left:10px;
		font-size:14px;
	}
}