/* =============================================================================
 * System message
 * Some selectors use duplicate class selectors to increase specificity because
 * of s-text.
============================================================================= */
.c-system-message {
	display:flex;
	margin-bottom:var(--space);
	padding:calc(var(--space) + 4px);
	border-radius:2px;
	background-color:var(--color__secondary_light-2);
}
.c-system-message__heading,
.s-text .c-system-message__heading {
	display:flex;
	margin-bottom:calc(var(--space) / 2);
	font-weight:700;
	font-size:20px;
	line-height:24px;
}
.c-system-message--alternative .c-system-message__heading {
	margin-bottom:0;
}
.c-system-message--alternative .s-text {
	margin-top:calc(var(--space) / 2);
}
.c-system-message__icon {
	position:relative;
	flex:none;
	margin-right:12px;
	fill:var(--color__primary-dark);
}
.c-system-message__form {
	display:flex;
	position:relative;
	justify-content:flex-end;
	margin:15px 0 0;
}
.c-system-message--no-margin {
	margin:0;
}
.c-system-message__close {
	margin-left:15px;
}
.c-system-message__close-x__button {
	display:none;
	width:calc(100% + 36px);
	margin-top:15px;
	margin-left:-36px;
}
.c-system-message__close-x {
	position:absolute;
	top:-8px;
	right:-8px;
	width:40px;
	height:40px;
	padding:0;
	cursor:pointer;
}
.c-system-message__close-x::before,
.c-system-message__close-x::after {
	display:block;
	position:absolute;
	top:0;
	left:calc(50% - 2px);
	width:4px;
	height:100%;
	background:var(--color__primary-dark);
	content:"";
	transform:rotate(45deg) translateX(0);
}
.c-system-message__close-x::after {
	transform:rotate(-45deg) translateX(0);
}
.c-system-message__close-x:hover::before,
.c-system-message__close-x:hover::after {
	background:var(--color__primary-dark);
}
.sr-only {
	position:absolute;
	width:1px;
	height:1px;
	overflow:hidden;
	clip:rect(0, 0, 0, 0);
	margin:-1px;
	padding:0;
	border:0;
}
.c-system-message__mobile-illustration {
	display:none;
	margin:24px auto 0;
}

/* Different states */
.c-system-message--confirmation {
	background-color:var(--color__confirm);
}
.c-system-message--confirmation .c-system-message__icon {
	fill:var(--color__confirm-dark);
}
.c-system-message--error {
	background-color:var(--color__error-light);
}
.c-system-message--error .c-system-message__icon {
	fill:var(--color__error);
}
.c-system-message--infobanner {
	background-color:var(--color__error-light);
	color:var(--color__base-black);
}
.c-system-message--infobanner .c-system-message__icon {
	fill:var(--color__error);
}
.c-system-message--infobanner .s-text p,
.c-system-message--infobanner .c-system-message__form {
	margin:0;
}
.c-system-message--confirmation .c-system-message__icon,
.c-system-message--error .c-system-message__icon {
	top:-2px;
}
.c-system-message .c-system-message__mobile-illustration {
	padding-right:36px;
}
.c-system-message--error a {
	color:var(--color__base-black);
	text-decoration:underline;
}
.c-system-message--alternative {
	background-color:var(--color__primary-alt_light);
}
.c-system-message--alternative .c-system-message__icon {
	fill:var(--color__primary);
}
.c-system-message .s-text ul {
	margin-bottom:0;
}

@media only screen and (max-width:450px) {
	.c-system-message__close-x {
		display:none;
	}
	.c-system-message__close-x__button {
		display:block;
	}
	.c-system-message__mobile-illustration {
		display:block;
	}
}

@media only screen and (min-width:768px) {
	.c-system-message--infobanner .c-system-message__heading {
		font-weight:600;
		font-size:30px;
		line-height:34px;
	}
	.c-system-message--infobanner .s-text p,
	.c-system-message--infobanner .s-text a {
		font-size:23px;
		line-height:34px;
	}
}

@media only screen and (max-width:767px) {
	.c-system-message__close-x {
		width:32px;
		height:32px;
	}
	.c-system-message__heading {
		font-size:18px;
		line-height:22px;
	}
	.c-system-message--confirmation .c-system-message__icon,
	.c-system-message--error .c-system-message__icon {
		top:-4px;
	}
}