.c-search-related {
	padding:calc(var(--space) * 4) 0 calc(var(--space) * 2);
}
h1.c-search-related__heading {
	margin-bottom:16px;
	color:#000;
}
.c-search-related__suggest {
	margin-top:calc(var(--space));
	font-size:calc(var(--space));
}
.c-search-related__suggest a {
	display:inline-block;
	color:#000;
	text-decoration:underline;
}
.c-search-related__input {
	font-weight:500;
}

@media(max-width:1200px) {
	.c-search-related {
		padding:calc(var(--space) * 3) 0 calc(var(--space) * 2);
	}
}

@media(max-width:768px) {
	.c-search-related {
		padding:calc(var(--space) * 2) 0 calc(var(--space) * 1);
	}
	.c-search-related__suggest {
		font-size:calc(var(--space) - 4px);
	}
	h1.c-search-related__heading {
		margin-bottom:8px;
	}
}