/* =============================================================================
 * Banner
============================================================================= */
.c-banner {
	display:flex;
	position:relative;
	align-items:center;
	margin:var(--space) 0;
}
.c-banner__image {
	width:55px;
	height:55px;
	margin-right:calc(var(--space) / 2);
}
.c-banner__icon > svg {
	width:100%;
	height:100%;
}
.c-banner__heading {
	color:var(--color__primary);
	font-weight:600;
	font-size:16px;
}
.c-banner__heading a {
	color:inherit;
}
.c-banner__heading a:hover,
.c-banner__heading a:focus {
	color:var(--color__primary-dark);
	text-decoration:underline;
}
.c-banner__heading a::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
}

@media (max-width:767px) {
	.c-banner__image {
		width:37px;
		height:37px;
	}
}