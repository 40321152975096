.c-product-image {
	position:relative;
	margin-bottom:calc(var(--space) * 2);
}
.c-product-image__link {
	position:absolute;
	right:0;
	bottom:0;
	margin:calc(var(--space) / 2);
	opacity:0;
	transition:opacity 0.2s ease;
}
.c-product-image__link:hover,
.c-product-image__link:focus,
.c-product-image img:hover + .c-product-image__link,
.c-product-image img:focus + .c-product-image__link {
	opacity:1;
}
.c-product-image__icon {
	display:flex;
	justify-content:center;
	align-items:center;
	width:40px;
	height:40px;
	border-radius:50%;
	background-color:var(--color__base-white);
}