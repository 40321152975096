.c-main-navigation {
	position:relative;
	background-color:var(--color__ut_darkgreen);
}
.c-main-navigation__filler {
	display:none;
	position:absolute;
	top:calc(100% + 1px);
	right:0;
	bottom:0;
	left:0;
	z-index:999;
	width:100%;
	height:100%;
	box-shadow:0 4px 5px rgba(0, 0, 0, 0.2);
	background-color:var(--color__ut_grey-30);
	content:"";
}
.c-main-navigation__inner {
	max-width:1400px;
}
.c-main-navigation__inner--padding {
	padding-top:calc(var(--space) * 3);
	padding-bottom:calc(var(--space) * 3);
}
.c-main-navigation__list {
	display:flex;
}
.no-js .c-main-navigation__list {
	flex-wrap:wrap;
}
.c-main-navigation__item {
	display:flex;
	margin-right:var(--space);
	margin-bottom:calc(var(--space) / 2);
	text-align:center;
}
.c-main-navigation__item--relative {
	position:relative;
}
.c-main-navigation__link,
.c-main-navigation__trigger-link,
.c-main-navigation__button,
.c-action-menu__link {
	display:inline-block;
	position:relative;
	width:100%;
	height:100%;
	padding:19px 0 5px;
	border-bottom:3px solid transparent;
	color:currentColor;
	font-weight:600;
	font-size:var(--font-size-large);
	line-height:24px;
	text-decoration:none;
	cursor:pointer;
	transition:font-size 0.2s ease;
}
.c-main-navigation__button {
	flex:0 0 24px;
}
.c-main-navigation__link:hover,
.c-main-navigation__link:focus,
.c-main-navigation__link--selected,
.c-main-navigation__trigger:hover .c-main-navigation__trigger-link,
.c-main-navigation__trigger-link:focus,
.c-main-navigation__trigger:hover .c-main-navigation__button,
.c-main-navigation__button:focus,
.c-main-navigation__trigger--expanded .c-main-navigation__button,
.c-main-navigation__trigger--expanded .c-main-navigation__trigger-link,
.c-main-navigation__link:hover,
.c-action-menu__link:hover {
	outline:none;
	border-bottom:3px solid #fff;
	color:#fff;
	text-decoration:none;
	fill:#fff;
}
.c-main-navigation__button:focus-visible {
	font-weight:700;
}
.c-main-navigation__link:hover::after,
.c-main-navigation__link:focus::after {
	color:var(--color__ut_darkgreen-20);
}
.c-main-navigation__link svg:hover,
.c-main-navigation__link:hover svg,
.c-main-navigation__link:hover .c-action-menu__cart-count,
.c-action-menu__cart-count:hover,
.c-main-navigation__link--selected svg {
	fill:#fff;
}
.c-main-navigation__action-box__wrapper {
	display:none;
	position:absolute;
	top:55px;
	left:0;
	z-index:1001;
	width:100%;
	padding-top:12px;
}
.c-main-navigation__action-box__wrapper--open {
	display:block;
}
.c-main-navigation__action-box__wrapper .c-action-box {
	min-width:250px;
	width:100%;
	padding:16px 24px;
	box-shadow:0 2px 6px rgba(0, 0, 0, 0.3);
	color:var(--color__base-black);
	text-align:left;
}
.c-main-navigation__action-box__wrapper .c-action-box p {
	font-weight:400;
	line-height:1.5;
}
.c-main-navigation__action-box__wrapper .c-action-box a {
	line-height:24px;
}
.c-main-navigation__action-box__wrapper .c-action-box p span {
	display:block;
	font-weight:600;
}
.c-main-navigation__action-box__wrapper .c-action-box p a {
	width:100%;
}
.c-main-navigation[data-loggedin="true"] .c-main-navigation__link:hover + .c-main-navigation__action-box__wrapper,
.c-main-navigation[data-loggedin="true"] .c-main-navigation__item:hover > .c-main-navigation__action-box__wrapper {
	display:block;
}

@media screen and (min-width:1025px) {
	.c-main-navigation__link:hover .c-action-menu__cart-count,
	.c-action-menu__cart-count:hover,
	.c-main-navigation__link--selected .c-action-menu__cart-count {
		box-shadow:0 0 0 2px var(--color__ut_yellow);
		background-color:var(--color__ut_yellow);
		color:var(--color__ut_darkgreen);
	}
	.c-main-navigation__action-box__wrapper {
		top:52px;
		left:-27px;
		width:292px;
	}
	.c-main-navigation__action-box__wrapper .c-action-box {
		width:max-content;
	}
	.c-main-navigation__action-box__wrapper .c-action-box p a {
		width:auto;
	}
}
.c-main-navigation__trigger:hover .c-main-navigation__button,
.c-main-navigation__trigger:hover .c-main-navigation__trigger-link,
.c-main-navigation__trigger--expanded .c-main-navigation__button,
.c-main-navigation__trigger--expanded .c-main-navigation__trigger-link {
	border-bottom:3px solid #fff;
	color:#fff;
}
.c-main-navigation__button-icon {
	transition:transform 0.2s ease;
}
.c-main-navigation__trigger--expanded .c-main-navigation__button-icon {
	transform:rotate(180deg);
}
.c-main-navigation__support {
	display:flex;
	justify-content:flex-end;
	flex:1 1 auto;
	color:#fff;
}
.c-main-navigation__support .c-main-navigation__item {
	margin-right:calc(var(--space) * 3);
}
.c-main-navigation__support .c-main-navigation__item:nth-last-child(2) {
	margin-right:10px;
}
.c-main-navigation__support .c-main-navigation__item:last-child {
	margin-right:0;
	margin-left:5px;
}
.c-main-navigation .c-action-menu__icons {
	position:absolute;
	top:12px;
	left:-35%;
	color:var(--color__base-black);
	transform:translate(-20%, 35%);
}
.c-main-navigation .c-action-menu__cart-count {
	top:auto;
	right:auto;
	bottom:10%;
	left:-55%;
}
.c-main-navigation__container {
	display:none;
	position:absolute;
	top:calc(100% + 11px);
	left:0;
	z-index:1000;
	padding:calc(var(--space) * 1.5) 0 calc(var(--space) * 2.5);
	background-color:var(--color__ut_grey-30);
	text-align:left;
}
.c-main-navigation__close {
	position:relative;
	min-width:24px;
	width:24px;
	height:24px;
	cursor:pointer;
}
.c-main-navigation__close span {
	display:block;
	position:absolute;
	right:0;
	width:100%;
	height:2px;
	border-radius:9px;
	background:var(--color__base-black);
}
.c-main-navigation__close span:nth-child(1) {
	transform:rotate(45deg);
}
.c-main-navigation__close span:nth-child(2) {
	transform:rotate(-45deg);
}
.c-main-navigation__close:hover span {
	background:var(--color__link-hover);
}
.c-main-navigation__close:focus {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
}
.c-main-navigation__container--show {
	display:flex;
}
.no-js .c-main-navigation__container {
	display:block;
	position:static;
}
.c-main-navigation__wrapper {
	display:flex;
	width:100%;
}
.c-main-navigation__trigger--expanded .c-main-navigation__trigger-link,
.c-main-navigation__trigger-link:hover {
	color:var(--color__ut_darkgreen-20);
}
.c-main-navigation__sub-item-heading {
	margin-bottom:calc(var(--space) / 2);
	color:var(--color__ut_darkgreen);
	font-weight:bold;
	font-size:18px;
	white-space:nowrap;
}
.c-main-navigation__sub-items {
	margin-right:calc(var(--space) * 2);
}
.c-main-navigation__sub-items:last-child {
	margin-right:0;
}
.c-main-navigation__sub-items ul {
	margin:0;
	padding:0;
}
.c-main-navigation__sub-item:not(:last-child) {
	margin-bottom:calc(var(--space) / 2);
}
.c-main-navigation__sub-item > a {
	color:var(--color__base-black);
	font-size:18px;
	text-decoration:none;
	white-space:nowrap;
}
.c-main-navigation__sub-item > a:hover {
	text-decoration:underline;
	text-decoration-thickness:2px;
	text-underline-offset:0.2em;
}
.c-main-navigation__sub-item--selected > a {
	font-weight:700;
	text-decoration:underline;
	text-decoration-thickness:2px;
}

@media screen and (min-width:1150px) {
	.c-main-navigation__item {
		margin-right:calc(var(--space) * 2);
	}
	.c-main-navigation__sub-items {
		margin-right:calc(var(--space) * 3);
	}
}