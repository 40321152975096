/* =============================================================================
 * Toggle text
============================================================================= */
.js .c-toggle-text {
	display:none;
}
.js .c-toggle-text--show {
	display:block;
}

@media print {
	.js .c-toggle-text {
		display:block;
	}
	[id^="toggle-text-btn"] {
		display:none;
	}
}