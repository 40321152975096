/* =============================================================================
 * Cookie banner
============================================================================= */
.c-cookie-banner {
	position:fixed;
	right:0;
	bottom:0;
	left:0;
	z-index:99;
	padding:var(--space) 0;
	background:var(--color__primary);
	color:#fff;
}
.c-cookie-banner__inner {
	display:flex;
	position:relative;
	justify-content:space-between;
	align-items:center;
	padding-right:calc(var(--space) * 2);
}
.c-cookie-banner__content {
	flex:1 1 auto;
	margin-right:var(--space);
}
.c-cookie-banner__content a {
	text-decoration:underline;
}
.c-cookie-banner__content a,
.c-cookie-banner__content p {
	color:#fff;
}
.c-cookie-banner__content p,
.c-cookie-banner__form {
	margin:0;
}
.c-cookie-banner__form {
	flex:0 0 auto;
}
.c-cookie-banner__close {
	position:absolute;
	top:-12px;
	right:0;
}

@media screen and (max-width:767px) {
	.c-cookie-banner__inner {
		display:block;
	}
	.c-cookie-banner__content p {
		margin-bottom:var(--space);
	}
}