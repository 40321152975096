/* =============================================================================
 * Big quote
 *
 * [class] Raise specificity and override ".s-text blockquote"
 ============================================================================ */
.c-big-quote {
	position:relative;
	margin:var(--space) 0;
	padding-left:50px;
	color:var(--color__base-black);
	text-align:left;
}
.c-big-quote--compact {
	margin:0 !important;
}
.c-big-quote::before {
	position:absolute;
	top:-4px;
	left:0;
	font-weight:bold;
	font-size:78px;
	line-height:1;
	content:"”";
	pointer-events:none;
}
.c-big-quote[class] p {
	margin-bottom:calc(var(--space) / 2);
	font-size:26px;
	line-height:32px;
	text-indent:0;
}
.c-big-quote p::after,
.c-big-quote p::before {
	content:none;
}
.c-big-quote footer {
	color:var(--color__base-black);
}
.c-big-quote cite {
	font-size:18px;
	line-height:26px;
}
div[class*="green"]:not(div[class*="-20"]) .c-big-quote,
div[class*="--bg-red"] .c-big-quote,
div[class*="--bg-darkgrey"] .c-big-quote,
div[class*="--bg-black"] .c-big-quote,
div[class*="green"]:not(div[class*="-20"]) .c-big-quote footer,
div[class*="--bg-red"] .c-big-quote footer,
div[class*="--bg-darkgrey"] .c-big-quote footer,
div[class*="--bg-black"] .c-big-quote footer {
	color:var(--color__base-white);
}

@media only screen and (min-width:768px) {
	.c-big-quote {
		margin:calc(var(--space) * 2) 0;
		padding-left:100px;
	}
	.c-big-quote::before {
		top:-10px;
		font-size:156px;
	}
	.c-big-quote[class] p {
		font-size:40px;
		line-height:47px;
	}
}