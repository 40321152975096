.c-footer {
	position:relative;
	z-index:1;
	margin-top:calc(var(--space) * 5);
	padding:0 0 calc(var(--space) * 2);
	background-color:var(--color__ut_darkgreen);
	color:var(--color__base-white);
}
.c-footer::before {
	position:absolute;
	right:0;
	bottom:50%;
	left:-5px;
	z-index:-1;
	height:50%;
	background-color:var(--color__ut_darkgreen);
	content:"";
	transition:0.2s transform;
	-ms-transform:rotate(3deg) scale(1) skew(3deg);
	-webkit-transform:rotate(3deg) scale(1) skew(3deg);
	transform:rotate(3deg) scale(1) skew(3deg);
	-ms-transform-origin:100% 100%;
	-webkit-transform-origin:100% 100%;
	transform-origin:100% 100%;
}
.c-footer__logo {
	margin-bottom:calc(var(--space) * 2);
}
.c-footer__logo-img {
	width:240px;
}
.c-footer__sections {
	margin-left:30px;
}
.c-footer__section {
	float:left;
	width:calc(18% - 10px);
	margin:0 0 calc(var(--space) * 2) 10px;
}
.c-footer aside {
	text-decoration:none;
}
.c-footer a:hover,
.c-footer__divided-list-item a:hover {
	color:var(--color__ut_red-30);
}
.c-footer a:focus,
.c-footer__divided-list-item a:focus {
	color:var(--color__ut_yellow-50);
}
.c-footer__section:first-of-type,
.c-footer__section:nth-of-type(2) {
	width:calc(23% - 10px);
}
.c-footer__section a {
	color:var(--color__base-white);
}
.c-footer__heading {
	margin-bottom:6px;
	font-weight:400;
	font-size:var(--font-size-heading3);
	line-height:var(--font-line-height-heading3);
}
.c-footer__contact-details {
	display:block;
}
.c-footer__contact-phone {
	color:var(--color__base-white);
	text-decoration:none;
}

/* Footer lists */
.c-footer__divided-list {
	display:flex;
	justify-content:flex-end;
	flex-wrap:wrap;
	font-size:13px;
}
.c-footer__divided-list-item {
	display:flex;
	position:relative;
	flex-wrap:wrap;
	align-items:center;
	padding-right:var(--space);
}
.c-footer__divided-list-item a {
	color:var(--color__base-white);
}
.c-footer__divided-list-item::after {
	position:absolute;
	top:50%;
	right:calc(var(--space) / 2);
	width:1px;
	height:16px;
	background:var(--color__base-white);
	content:"";
	transform:translateY(-50%);
}
.c-footer__divided-list-item:last-child {
	padding-right:0;
}
.c-footer__divided-list-item:last-child::after {
	content:none;
}

@media screen and (max-width:1023px) {
	.c-footer__section,
	.c-footer__section:first-of-type,
	.c-footer__section:nth-of-type(2) {
		width:calc(33.33333% - 10px);
	}
	.c-footer__divided-list {
		justify-content:center;
	}
}

@media screen and (max-width:767px) {
	.c-footer {
		padding-top:var(--space);
	}
	.c-footer__logo-img {
		width:180px;
	}
	.c-footer__sections {
		margin-left:-10px;
	}
	.c-footer__section,
	.c-footer__section:first-of-type,
	.c-footer__section:nth-of-type(2) {
		width:calc(50% - 10px);
	}
	.c-footer__section:last-child {
		width:100%;
	}
}

@media screen and (max-width:420px) {
	.c-footer__section,
	.c-footer__section:first-of-type,
	.c-footer__section:nth-of-type(2) {
		width:100%;
		margin-bottom:var(--space);
	}
	.c-footer__divided-list {
		flex-direction:column;
		justify-content:flex-start;
	}
	.c-footer__divided-list-item {
		padding:0;
	}
	.c-footer__divided-list-item:last-child {
		margin-top:var(--space);
	}
	.c-footer__divided-list-item::after {
		content:none;
	}
}

@media screen and (min-width:1000px) {
	.c-footer::before {
		-ms-transform:rotate(2.5deg) scale(1) skew(2.5deg);
		-webkit-transform:rotate(2.5deg) scale(1) skew(2.5deg);
		transform:rotate(2.5deg) scale(1) skew(2.5deg);
	}
}

@media screen and (min-width:1500px) {
	.c-footer::before {
		-ms-transform:rotate(2deg) scale(1) skew(2deg);
		-webkit-transform:rotate(2deg) scale(1) skew(2deg);
		transform:rotate(2deg) scale(1) skew(2deg);
	}
}

@media screen and (min-width:2048px) {
	.c-footer::before {
		transition:0s transform;
		-ms-transform:rotate(1.5deg) scale(1) skew(1.5deg);
		-webkit-transform:rotate(1.5deg) scale(1) skew(1.5deg);
		transform:rotate(1.5deg) scale(1) skew(1.5deg);
	}
}

@media screen and (min-width:3000px) {
	.c-footer::before {
		bottom:65%;
		-ms-transform:rotate(0deg) scale(1) skew(0deg);
		-webkit-transform:rotate(0deg) scale(1) skew(0deg);
		transform:rotate(0deg) scale(1) skew(0deg);
	}
}

/* Print
 *
 * 1. Assuming that the last column will contain social media links
----------------------------------------------------------------------------- */
@media print {
	.c-footer__section {
		width:calc(25% - 10px);
	}
	.c-footer__section:last-child {
		display:none; /* 1 */
	}
}