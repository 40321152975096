/* =============================================================================
 * Spinner
============================================================================= */
.c-spinner {
	display:block;
	width:24px;
	height:24px;
	border:0.25rem solid rgba(0, 0, 0, 0.2);
	border-top-color:var(--color__primary);
	border-radius:50%;
	animation:spin 0.5s infinite linear;
}

/* Spinner over an area
 *
 * When used to cover an area that are being loaded
----------------------------------------------------------------------------- */
[data-spinner-area] {
	position:relative;
}
[data-spinner-area]::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:500;
	content:"";
}
[data-spinner-area] .c-spinner {
	position:absolute;
	top:calc(50% - 12px);
	left:calc(50% - 12px);
	z-index:500;
	color:#fff;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
	}
	100% {
		-webkit-transform:rotate(360deg);
		transform:rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
	}
	100% {
		-webkit-transform:rotate(360deg);
		transform:rotate(360deg);
	}
}