/* =============================================================================
 * Tags
============================================================================= */
.c-tags.c-tags {
	margin:0 0 calc(var(--space) / 2);
	list-style:none;
}
.c-tags.c-tags li {
	display:inline-block;
	margin:0;
}
.c-tags.c-tags li + li::before {
	display:inline-block;
	width:1px;
	height:16px;
	margin:0 0.5em 0 calc(0.5em - 1px);
	background-color:var(--color__base-black);
	content:"";
	vertical-align:-2px;
}
.c-tags.c-tags--light li + li::before {
	background-color:var(--color__base-white);
}