.c-search-sort {
	padding:var(--space) 0;
	border-top:1px solid #a2a2a2;
	border-bottom:1px solid #a2a2a2;
}
.c-search-sort__expandable {
	display:flex;
	position:relative;
}
.c-search-sort__heading {
	margin:auto 0;
	margin-right:10px;
	margin-bottom:calc(var(--space) / 2);
	font-weight:700;
	font-size:20px;
	text-transform:uppercase;
}
.c-search-sort__item {
	margin-bottom:calc(var(--space) / 2);
}
.c-search-sort__link {
	color:var(--color__base-black);
}
.c-search-sort__link:hover {
	text-decoration:underline;
}
.c-search-sort__button {
	display:none;
}

@media screen and (max-width:1024px) {
	.c-search-sort__heading {
		font-size:16px;;
	}
}

@media screen and (max-width:767px) {
	.c-search-sort {
		margin:-20px auto;
		padding:0 var(--space) var(--space);
		border:none;
	}
	.c-search-sort::after {
		position:absolute;
		left:0;
		width:100%;
		margin-top:10px;
		border-bottom:1px solid #a2a2a2;
		content:"";
	}
	.c-search-sort__heading {
		margin-bottom:0;
	}
	.c-search-sort__button {
		display:block;
		position:relative;
		width:24px;
		height:24px;
	}
	.c-search-sort__button .c-search-filter__icon {
		right:auto;
	}
	.c-search-sort__items {
		display:none;
	}
	.c-search-sort__expandable.js-active + .c-search-sort__items {
		display:block;
		margin-top:calc(var(--space) / 2);
	}
}