/* open-sans-regular - latin */

@font-face {
	font-weight:400;
	font-style:normal;
	font-family:"Open Sans";
	src:url(_fonts/open-sans-v27-latin-regular.woff) format("woff");
}

/* open-sans-500 - latin */

@font-face {
	font-weight:600;
	font-style:normal;
	font-family:"Open Sans";
	src:url(_fonts/open-sans-v27-latin-500.woff) format("woff");
}

/* open-sans-700 - latin */

@font-face {
	font-weight:700;
	font-style:normal;
	font-family:"Open Sans";
	src:url(_fonts/open-sans-v27-latin-700.woff) format("woff");
}