.c-header__top-bar {
	background-color:var(--color__ut_darkgreen);
}
.c-header__inner {
	display:flex;
	display:-webkit-flex;
	justify-content:space-between;
	align-items:center;
	max-width:1400px;
	margin-bottom:-0.5px;
	padding-top:var(--space);
}
.c-header__main-navigation {
	background-color:var(--color__ut_darkgreen);
	color:#fff;
	transition:transform 0.2s ease, box-shadow 0.2s ease;
}
.c-header--private .c-header__main-navigation,
.c-header--private .c-header__top-bar,
.c-header--private .c-main-navigation {
	background-color:var(--color__ut_green-20);
	color:var(--color__base-black);
}
.c-header--private .c-header-mobile__button,
.c-header--private .c-header-mobile__link,
.c-header--private .c-header-mobile__link:hover {
	color:var(--color__base-black);
}
.c-header--private .c-action-menu__link,
.c-header--private .c-main-navigation__link,
.c-header--private .c-customer-links__link,
.c-header--private .c-main-navigation__item:nth-last-child(2) a::after,
.c-header--private .c-action-menu__link:hover,
.c-header--private .c-action-menu__link:focus,
.c-header--private .c-action-menu__link:focus-visible {
	color:var(--color__base-black);
	text-decoration:none;
}
.c-header--private .c-main-navigation__trigger--expanded .c-main-navigation__trigger-link,
.c-header--private .c-main-navigation__trigger:hover .c-main-navigation__button,
.c-header--private .c-main-navigation__trigger:hover .c-main-navigation__trigger-link,
.c-header--private .c-main-navigation__trigger-link:focus:focus,
.c-header--private .c-main-navigation__button:focus,
.c-header--private .c-customer-links__link:focus,
.c-header--private .c-main-navigation__trigger--expanded .c-main-navigation__button,
.c-header--private .c-main-navigation__trigger--expanded .c-main-navigation__trigger-link,
.c-header--private .c-customer-links__link:hover,
.c-header--private .c-main-navigation__link:hover,
.c-header--private .c-main-navigation__link:focus,
.c-header--private .c-header--private .c-customer-links__link:focus,
.c-header--private .c-action-menu__link:hover,
.c-header--private .c-action-menu__link:focus {
	border-bottom:3px solid var(--color__base-black);
	color:var(--color__base-black);
	fill:var(--color__base-black);
}
.c-header--private .c-header__logo svg,
.c-header--private .c-header-mobile__logo svg path {
	fill:var(--color__ut_darkgreen);
}
.c-header--private .c-main-navigation__support svg,
.c-header--private .c-main-navigation__link:hover svg {
	fill:var(--color__base-black);
}
.c-header--private .c-header-mobile__icon {
	fill:var(--color__base-black);
}
.c-header--private .c-header-mobile__icon span {
	background:var(--color__base-black);
}
.c-header--private .c-action-menu__link .c-action-menu__cart-count,
.c-header--private .c-action-menu__link:hover .c-action-menu__cart-count,
.c-header--private .c-header-mobile__cart .c-action-menu__cart-count {
	background-color:var(--color__base-black);
	color:var(--color__ut_green-20);
}
.c-header--private .c-header-mobile__inner {
	background-color:var(--color__ut_green-20);
	color:var(--color__base-black);
}
.c-header__main-navigation--fixed {
	position:fixed;
	top:0;
	z-index:10000;
	width:100%;
}
.c-header__secondary-navigation {
	-webkit-flex:1 1 auto;
	flex:1 1 auto;
}
.c-header__action-menu {
	display:flex;
	display:-webkit-flex;
	justify-content:flex-end;
	flex:1 1 auto;
}
.c-header__logo {
	flex:1 1 auto;
	max-height:47px;
}
.c-header__search-form {
	flex:8 auto;
}

@media print {
	.c-header {
		height:auto;
	}
	.c-header__top-bar {
		position:static;
		justify-content:center;
	}
	.c-header__main-navigation,
	.c-header__secondary-navigation,
	.c-header__action-menu {
		display:none;
	}
}

@media screen and (max-width:1024px) {
	.c-header {
		display:none;
	}
}