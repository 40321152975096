/* =============================================================================
 * Search category
============================================================================= */
.c-search-category {
	margin-bottom:52px;
	text-align:center;
}
.c-search-category__list {
	display:grid;
	width:100%;
	text-align:left;
	grid-template-columns:repeat(auto-fill, 280px);
	grid-gap:20px;
}
.c-search-category__heading {
	display:flex;
	justify-content:space-between;
	align-items:center;
	min-height:75px;
	margin-bottom:30px;
	text-align:left;
}
.c-search-category__heading h2 {
	overflow:hidden;
	font-weight:600;
	font-size:var(--font-size-heading2);
}
.c-search-category__item .c-page-list__item-heading a {
	color:var(--color__base-black);
	font-weight:700;
	font-size:16px;
	text-decoration:none;
}
.c-search-category__item .c-page-list__item-heading a:hover {
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-search-category__item--product .c-page-list__item-heading {
	position:relative;
	width:100%;
	font-size:16px;
	hyphens:auto;
}

/* .c-search-category__item--product .c-page-list__item-heading::after {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	box-shadow:inset -7px 0 9px -7px #fff;
	content:"";
} */
.c-search-category .c-page-list__item-heading:not(.c-page-list__item-content .c-page-list__item-heading):not(.c-search-category__item--product .c-page-list__item-heading) {
	display:none;
}
.c-search-category__item {
	position:relative;
}
.c-search-category__item .c-search-category__item-subjects li {
	display:inline-block;
	position:relative;
	z-index:1;
	margin-top:5px;
	margin-right:5px;
	padding:5px 10px;
	background:var(--color__ut_darkgreen-20);
	color:var(--color__ut_darkgreen);
}
.c-search-category__item .c-search-category__item-subjects li:last-child {
	margin-right:0;
}
.c-search-category__item .c-search-category__item-subjects li {
	color:var(--color__ut_darkgreen);
}
.c-search-category__item .c-search-category__item-subjects li::before {
	display:none;
}
.c-search-category--init .c-search-category__item:not(.c-search-category__item--product):nth-child(n + 5),
.c-search-category--init .c-search-category__item--product:nth-child(n + 7) {
	display:none;
}
.c-search-category__item:not(.c-search-category__item--product) {
	margin-bottom:20px;
}
.c-search-category__item-image {
	position:relative;
	flex-shrink:0;
	width:100%;
	margin-bottom:7px;
}
.c-search-category__item-image img {
	max-height:160px;
}
.c-search-category__showmore:not(a.c-search-category__showmore) {
	display:inline-block;
	min-width:264px;
	margin:32px 0 0;
}
.c-search-category--bestbets .c-form-item,
.c-search-category--bestbets .c-search-category__showmore {
	display:none;
}
.c-search-category__item .c-breadcrumbs {
	margin-top:10px;
}
.c-search-category__noresult p {
	max-width:800px;
	color:var(--Svart, #333);
	font-weight:400;
	font-style:normal;
	font-size:22px;
	font-family:"Open Sans";
	line-height:34px;
	text-align:left;
}

/* Series category */
.c-search-category__item--series .c-search-category__item-image {
	height:160px;
	overflow:hidden;
	background:var(--color__ut_darkgreen);
}
.c-search-category__item--series .c-search-category__item-image span {
	display:block;
	position:absolute;
	bottom:20px;
	left:20px;
	color:#fff;
	font-size:30px;
	font-family:"verveine", sans-serif;
	line-height:1;
}
.c-search-category__books-wrapper {
	display:flex;
	position:absolute;
	bottom:75%;
	left:40px;
	justify-content:center;
	flex-wrap:wrap-reverse;
	align-items:center;
	width:300px;
	opacity:1;
	transform:translateY(50%) rotate(45deg);
}
.c-search-category__books-wrapper picture {
	position:relative;
	width:14%;
	margin:9.6%;
	border-radius:0 7px 7px 0;
	box-shadow:0 0 10px 5px rgba(0, 0, 0, 0.3);
	transition:all 0.6s ease-out;
	transform:rotate(-90deg);
}
.c-search-category__books-wrapper picture img {
	border-radius:0 7px 7px 0;
}
.c-search-category__books-wrapper picture {
	width:28%;
	margin:5px;
	transform:rotate(0);
}
.c-search-category__books-wrapper.books-1 picture,
.c-search-category__books-wrapper.books-2 picture {
	width:40%;
	border-radius:0 10px 10px 0;
}
.c-search-category__books-wrapper.books-3 picture {
	width:32%;
	border-radius:0 10px 10px 0;
}
.c-search-category__books-wrapper.books-3 picture {
	margin:-10% 5%;
}
.c-search-category__books-wrapper.books-4 picture:nth-last-child(2) {
	margin-left:15%;
}
.c-search-category__books-wrapper.books-4 picture:nth-last-child(3) {
	margin-right:15%;
}
.c-search-category__books-wrapper.books-1,
.c-search-category__books-wrapper.books-2,
.c-search-category__books-wrapper.books-3 {
	left:10px;
	flex-wrap:wrap;
	transform:translateY(50%) rotate(35deg);
}
.c-search-category__books-wrapper.books-1,
.c-search-category__books-wrapper.books-2,
.c-search-category__books-wrapper.books-3 {
	bottom:50%;
	left:30px;
}
.c-search-category__books-wrapper.books-3 {
	transform:translateY(50%) rotate(25deg);
}
.c-search-category__books-wrapper picture::after {
	position:absolute;
	right:-2px;
	bottom:50%;
	z-index:-1;
	width:10px;
	height:98%;
	border-radius:5px;
	background:var(--color__base-white);
	content:"";
	transform:translate(0, 50%);
}
.c-search-category__highlight {
	font-weight:700;
}

/* Product & bestbet category */
div[data-category="products"] .c-search-category__list,
div[data-category="bestbets"] .c-search-category__list {
	grid-template-columns:repeat(auto-fill, 152px);
}
.c-search-category__item--product {
	max-width:204px;
	width:152px;
}
.c-search-category__item--product img {
	max-height:270px;
}
.c-search-category__item--product .c-product__bubble-wrapper {
	top:-10px;
	left:-10px;
}
.c-search-category__item--product .c-search-category__item-image img {
	border-radius:3px;
	box-shadow:0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.c-search-category__item--product .c-product__link {
	padding:0;
}
.c-search-category__item--product .c-product__inner {
	max-width:unset;
}
.c-search-category__item--product > div:first-child {
	transition:background 0.1s linear;
}

@media (min-width:801px) {
	.c-search-category__item--product .c-page-list__item-content {
		display:flex;
		position:absolute;
		top:-10px;
		left:-20px;
		z-index:10;
		flex-direction:column;
		justify-content:center;
		width:calc(100% + 40px);
		height:calc(100% + 10px);
		padding:20px;
		border-radius:3px;
		box-shadow:2px 6px 12px 0 rgba(0, 0, 0, 0.03);
		background:var(--color__ut_grey);
		font-size:16px;
		opacity:0;
		cursor:pointer;
		transition:opacity 0.2s ease-out;
	}
	.c-search-category__item--product:not(.c-instant-search-results .c-search-category__item--product):hover .c-page-list__item-content,
	.c-search-category__item--product:not(.c-instant-search-results .c-search-category__item--product):focus-within .c-page-list__item-content {
		opacity:1;
	}
	.c-search-category__item--product > div:first-child > div:first-child {
		display:flex;
		flex-direction:column;
		align-items:flex-start;
	}
	.c-search-category__item--product .c-page-list__item-isbn,
	.c-search-category__item--product .c-page-list__item-isbn + dd {
		font-size:14px;
	}
}

@media (min-width:540px) {
	div[data-category="products"] .c-search-category__list,
	div[data-category="bestbets"] .c-search-category__list {
		justify-content:space-between;
	}
}

@media (max-width:800px) {
	.c-search-category__item--product .c-search-category__item-image + .c-page-list__item-heading {
		display:none;
	}
}