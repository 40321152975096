.c-search-filter {
	width:100%;
	padding-bottom:calc(var(--space));
}
.c-search-filter__heading {
	padding-bottom:var(--space);
	font-weight:400;
	font-size:25px;
	font-family:"Open Sans";
	line-height:35px; /* 140% */
}
.c-search-filter__label p {
	padding-bottom:8px;
	font-weight:600;
	font-size:18px;
	font-family:"Open Sans";
	line-height:24px; /* 133.333% */
}
.c-search-filter__block {
	display:flex;
	align-items:center;
	width:100%;
	border:1px solid #a2a2a2;
	background:#fff;
	text-align:left;
	cursor:pointer;
}
.c-search-filter__wrapper {
	position:relative;
}
.c-search-filter__wrapper .c-search-filter__block.js-active,
.c-search-filter__wrapper .c-search-filter__block.js-active .c-search-filter__block-text {
	position:relative;
	border-bottom:none;
	font-weight:700;
}
.c-search-filter__block-text {
	flex:1;
	padding:10px 0 10px 15px;
}
.c-search-filter__button {
	width:47px;
	padding:10px;
}
.c-search-filter__button,
.c-search-filter__choice-button {
	position:relative;
	outline:none;
	border:none;
	background:transparent;
}
.c-search-filter__block.js-active .c-icon {
	transform:rotate(180deg) translate(50%, 50%);
}
.c-search-filter__block:focus-visible {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
	border-color:#000;
}
.c-search-filter__choice-button:focus,
.c-search-filter__button:focus {
	outline:1px dotted #000;
	border:none;
	background:transparent;
}
.c-search-filter__icon {
	position:absolute;
	top:50%;
	right:0;
	color:var(--color__base-black);
	transform:translate(-50%, -50%);
}
.c-search-filter__checkbox-container {
	display:none;
	position:absolute;
	left:0;
	width:100%;
}
.c-search-filter__block.js-active + .c-search-filter__checkbox-container {
	display:block;
	z-index:20;
	max-height:max(14rem, 25vh);
	overflow-y:auto;
	border:1px solid #a2a2a2;
	border-top:none;
}
.c-search-filter__checkbox-wrapper {
	display:flex;
	margin:-2px 0 0;
	padding:var(--space);
	padding-bottom:0;
	background:#fff;
}
.c-search-filter__checkbox-wrapper > * {
	width:100%;
}
.c-search-filter__checkbox {
	margin-bottom:var(--space);
}
.c-search-filter__choice-container {
	display:flex;
	padding:calc(var(--space) / 2) 0 calc(var(--space) * 2);
}
.c-search-filter__choice-items {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	gap:10px;
}
.c-search-filter__choice-item {
	display:flex;
	align-items:center;
	margin:auto 0;
	padding:6px 16px;
	background:var(--color__ut_grey);
	color:var(--color__primary-dark);
	font-weight:400;
	font-size:14px;
	gap:10px;
}
.c-search-filter__choice-items .c-search-filter__choice-button {
	display:flex;
	padding:0;
}
.c-search-filter__choice-icon {
	position:relative;
	color:var(--color__primary-dark);
}

@media screen and (max-width:1023px) {
	.c-search-filter__wrapper .c-search-filter__block.js-active .c-search-filter__block-text {
		margin-bottom:0;
	}
	.c-search-filter__choice-container {
		display:block;
		margin-top:20px;
	}
	.c-search-filter.js-active {
		height:auto;
	}
	.o-grid__column:nth-child(odd) .c-search-filter__checkbox-container {
		float:left;
	}
	.c-search-filter__checkbox-container {
		top:45px;
	}
}

@media screen and (max-width:768px) {
	.c-search-filter__checkbox {
		display:inline-block;
		overflow:hidden;
		margin-bottom:20px;
		text-overflow:ellipsis;
		white-space:nowrap;
	}
	.c-search-filter__checkbox .c-form-item__label {
		display:inline;
	}
	.c-search-filter__block-text {
		padding-left:10px;
	}
	.o-grid__column:nth-child(odd) .c-search-filter__checkbox-container {
		left:0;
	}
	.c-search-filter__choice-item {
		overflow:hidden;
	}
	.c-search-filter__heading {
		padding-bottom:calc(var(--space) / 2);
		font-size:20px;
		line-height:28px; /* 140% */
	}
}

@media screen and (max-width:450px) {
	.c-search-filter__choice-text {
		overflow:hidden;
		margin-right:0;
		text-overflow:ellipsis;
	}
}