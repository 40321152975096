/* =============================================================================
 * Responsive table
 *
 * postcss-bem-linter: define responsive-table
============================================================================= */
.c-responsive-table {
	border-collapse:collapse;
	border-radius:4px;
}

/* Change display properties
----------------------------------------------------------------------------- */
.c-responsive-table--transformed,
.c-responsive-table--transformed caption,
.c-responsive-table--transformed tbody,
.c-responsive-table--transformed tfoot,
.c-responsive-table--transformed tr,
.c-responsive-table--transformed th,
.c-responsive-table--transformed td {
	display:block;
}
.c-responsive-table--transformed thead {
	display:none;
}

/* Caption
----------------------------------------------------------------------------- */
.c-responsive-table--transformed caption {
	padding:0.5em;
	background-color:rgba(0, 0, 0, 0.05);
}

/* Margin, padding and borders
----------------------------------------------------------------------------- */
/* stylelint-disable */
.c-responsive-table--transformed tr > * {
	padding:15px;
}
.c-responsive-table--transformed tr > *:not(:last-child) {
	padding-bottom:0;
	border-bottom:none;
}
.c-responsive-table--transformed th > *:last-child,
.c-responsive-table--transformed td > *:last-child {
	margin-bottom:0;
}
/* stylelint-enable */

/* Headers
----------------------------------------------------------------------------- */
.c-responsive-table--transformed th {
	font-weight:normal;
}
.c-responsive-table__fake-header {
	display:block;
	font-weight:bold;
}

/* media query */
@media screen and (min-width:768px) {
	.c-responsive-table--transformed tr:nth-child(even) {
		background-color:#f7f7f7;
	}
}